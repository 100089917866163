import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { FilterItem } from 'src/components/table-filter/@types';
import {
  AccountBalancesModel,
  AssetAllocationDetailView,
} from '@newedge/reports';
import { UserProfileFinancialAccountNickname } from '@newedge/common';
import { FinancialAccountInfo } from 'src/@types/global';
import { useContext } from 'react';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import { AssetAllocationDetailQueryReaderQuery } from './__generated__/AssetAllocationDetailQueryReaderQuery.graphql';

export const AssetAllocationDetailQueryReaderNode = graphql`
  query AssetAllocationDetailQueryReaderQuery(
    $assetAllocationInput: GetAssetAllocationInput!
    $assetAllocationFilterInput: GetAssetAllocationModelFilterInput
    $balancesInput: GetAccountBalancesInput!
  ) {
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      getAssetAllocations(
        input: $assetAllocationInput
        where: $assetAllocationFilterInput
      ) {
        allocationId
        accountId
        accountDefaultName
        accountNumber
        managementStyle
        accountBalance
        assetClass
        assetClassBalance
        subAssetClass
        subAssetClassBalance
        asOfDate
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
      id
      getAccountBalances(input: $balancesInput) {
        accountId
        totalBalance
      }
    }
  }
`;

interface AssetAllocationDetailQueryReaderProps {
  queryRef: PreloadedQuery<AssetAllocationDetailQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string | null) => void;
}

export const AssetAllocationDetailQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  setAsOfDate,
}: AssetAllocationDetailQueryReaderProps) => {
  const data = usePreloadedQuery(
    AssetAllocationDetailQueryReaderNode,
    queryRef
  );

  const dataArray = data.clientsummary_viewer.getAssetAllocations;

  const userProfileAccounts = data.userprofile_viewer
    ?.accounts as UserProfileFinancialAccountNickname[];

  const accountBalances = data.clientsummary_viewer
    ?.getAccountBalances as AccountBalancesModel[];

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    data.userprofile_viewer?.accounts as UserProfileFinancialAccountNickname[],
    data.clientsummary_viewer?.financialAccounts as FinancialAccountInfo[]
  );

  const { selectedAccounts } = useContext(GlobalFilterContext);

  return (
    <AssetAllocationDetailView
      data={dataArray}
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      setAsOfDate={setAsOfDate}
      accountBalances={accountBalances}
      accounts={accounts}
      selectedAccounts={selectedAccounts}
      userProfileAccounts={userProfileAccounts}
    />
  );
};
