import { SvgIcon, SvgIconProps } from '@mui/material';

const AssetAllocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 535 548">
      <path d="M257.2,184.2v-149c-58.9,2.1-115,25.4-158.1,65.6l105.3,105.3C219.4,193.6,237.8,186,257.2,184.2L257.2,184.2z" />
      <path
        d="M257.2,524.8v-149c-22.4-2.1-43.3-11.9-59.2-27.8c-15.9-15.9-25.7-36.9-27.8-59.2h-149c2.3,61.9,27.9,120.6,71.7,164.4
	C136.7,496.9,195.4,522.5,257.2,524.8L257.2,524.8z"
      />
      <path d="M192.1,218.4L86.8,113.2c-40.2,43.1-63.5,99.2-65.6,158.1h149C172,251.8,179.6,233.4,192.1,218.4L192.1,218.4z" />
      <path
        d="M274.8,210v10c7.5,1.9,14.1,6.3,18.9,12.4c4.8,6.1,7.3,13.6,7.4,21.4c0,4.8-3.9,8.8-8.8,8.8s-8.8-3.9-8.8-8.8
	c0-4.6-1.8-9.1-5.1-12.4s-7.7-5.1-12.4-5.1s-9.1,1.8-12.4,5.1s-5.1,7.7-5.1,12.4s1.8,9.1,5.1,12.4s7.7,5.1,12.4,5.1
	c11.4,0,22.2,5.6,28.7,15c6.5,9.4,8.1,21.4,4.1,32.1c-4,10.7-13,18.8-24,21.7v10c0,2.3-0.9,4.5-2.6,6.2s-3.9,2.6-6.2,2.6
	c28.1,0,54.1-15,68.2-39.4s14.1-54.4,0-78.8s-40.1-39.4-68.2-39.4c2.3,0,4.5,0.9,6.2,2.6S274.8,207.7,274.8,210L274.8,210z"
      />
      <path
        d="M274.8,375.8v149c61.9-2.3,120.6-27.9,164.4-71.7c43.8-43.8,69.4-102.5,71.7-164.4h-149c-2.1,22.4-11.9,43.3-27.8,59.2
	C318.1,363.9,297.1,373.7,274.8,375.8L274.8,375.8z"
      />
      <path
        d="M361.8,271.2h149c-2.3-61.9-27.9-120.6-71.7-164.4c-43.8-43.8-102.5-69.4-164.4-71.7v149c22.4,2.1,43.3,11.9,59.2,27.8
	C349.9,227.9,359.7,248.9,361.8,271.2L361.8,271.2z"
      />
      <path
        d="M187.2,280c0,20.9,8.3,40.9,23.1,55.7s34.8,23.1,55.7,23.1c-4.8,0-8.8-3.9-8.8-8.8v-10
	c-7.5-1.9-14.1-6.3-18.9-12.4c-4.8-6.1-7.3-13.6-7.4-21.4c0-4.8,3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8c0,4.6,1.8,9.1,5.1,12.4
	s7.7,5.1,12.4,5.1s9.1-1.8,12.4-5.1s5.1-7.7,5.1-12.4s-1.8-9.1-5.1-12.4s-7.7-5.1-12.4-5.1c-11.4,0-22.2-5.6-28.7-15
	c-6.5-9.4-8.1-21.4-4.1-32.1c4-10.7,13-18.8,24-21.7v-10c0-4.8,3.9-8.8,8.8-8.8c-20.9,0-40.9,8.3-55.7,23.1S187.3,259.1,187.2,280
	L187.2,280z"
      />
    </SvgIcon>
  );
};

export { AssetAllocationIcon };
