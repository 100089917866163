import { Box, Typography } from '@mui/material';
import { AccordionItem, ManageDisplayNameRow } from '@newedge/common';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { useCallback } from 'react';
import { ManageProfileAccordion_ManageDisplayNameRowMutation } from './__generated__/ManageProfileAccordion_ManageDisplayNameRowMutation.graphql';

const ManageDisplayNameRowMutationNode = graphql`
  mutation ManageProfileAccordion_ManageDisplayNameRowMutation(
    $input: UpdateUserInput!
  ) {
    UpdateUser(input: $input) {
      errors {
        code
        message
      }
      partitionKey
      request {
        correlationId
        status
      }
      userState {
        displayName
      }
      viewer {
        displayName
      }
    }
  }
`;

interface ManageProfileAccordionProps {
  displayName?: string;
}

export const ManageProfileAccordion = ({
  displayName,
}: ManageProfileAccordionProps) => {
  const [commitUpdate] =
    useMutation<ManageProfileAccordion_ManageDisplayNameRowMutation>(
      ManageDisplayNameRowMutationNode
    );

  const onUpdateClick = useCallback(
    (newDisplayName: string) => {
      commitUpdate({
        variables: {
          input: {
            displayName: newDisplayName,
          },
        },
      });
    },
    [commitUpdate]
  );

  return (
    <AccordionItem
      accordionProps={{
        'data-testid': 'ManageProfileAccordion',
      }}
      summaryComponent={<Typography variant="h4">Manage Profile</Typography>}
      detailsComponent={
        <Box
          sx={(theme) => ({
            '&>*:nth-child(odd)': {
              backgroundColor: theme.extensions.grey[16],
            },
          })}
        >
          <ManageDisplayNameRow
            displayName={displayName ? displayName : ''}
            onUpdateClick={onUpdateClick}
          />
        </Box>
      }
      accordionDetailsProps={{
        sx: {
          padding: 0,
        },
      }}
    />
  );
};

export default ManageProfileAccordion;
