import {
  ColumnDataType,
  DataAlignment,
  Order,
} from 'src/components/sortable-table/@types';
import {
  getDefaultComparator,
  stableSort,
} from 'src/components/sortable-table/SortHelpers';
import { AccountBalancesModel } from '@newedge/reports';
import { CompactTable, CompactTableColumn } from '@newedge/common';

interface AccountBalanceAccountOnlyTableProps {
  data?: AccountBalancesModel[];
}

export const AccountBalanceAccountOnlyTable = ({
  data,
}: AccountBalanceAccountOnlyTableProps) => {
  const columns: CompactTableColumn<AccountBalancesModel>[] = [
    {
      id: 'accountNickname',
      label: 'Name',
      dataType: ColumnDataType.String,
      maxLength: 72,
    },
    {
      id: 'totalBalance',
      label: 'Total Balance',
      dataType: ColumnDataType.Currency,
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'cashBalance',
      label: 'Cash & Cash Equivalent',
      dataType: ColumnDataType.Currency,
      dataAlignment: DataAlignment.Right,
      width: '35%',
    },
  ];

  if (data) {
    return (
      <CompactTable
        columns={columns}
        data={stableSort(
          data,
          getDefaultComparator(Order.Desc, 'totalBalance')
        )}
      />
    );
  }
  return <></>;
};

export default AccountBalanceAccountOnlyTable;
