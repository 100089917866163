/**
 * @generated SignedSource<<20221e495afdc6d63e833c5edf742c21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageAccountGroupView_clientsummary_viewer$data = {
  readonly financialAccounts: ReadonlyArray<{
    readonly accountName: string;
    readonly accountNumber: string;
    readonly accountType: string;
    readonly accountTypeAbbreviation: string;
    readonly clientIdentifier: any;
    readonly custodian: string;
    readonly id: string;
    readonly managementStyle: string;
    readonly registrationName: string;
  }>;
  readonly " $fragmentType": "ManageAccountGroupView_clientsummary_viewer";
};
export type ManageAccountGroupView_clientsummary_viewer$key = {
  readonly " $data"?: ManageAccountGroupView_clientsummary_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageAccountGroupView_clientsummary_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageAccountGroupView_clientsummary_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAccount",
      "kind": "LinkedField",
      "name": "financialAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientIdentifier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managementStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountTypeAbbreviation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "custodian",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientSummaryViewer",
  "abstractKey": null
};

(node as any).hash = "92118a26f704c6be7fe94052cf4def98";

export default node;
