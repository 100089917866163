/**
 * @generated SignedSource<<fefc87ade9d8b09e0ba9b4da428cf3a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ToutsWidget_data$data = {
  readonly getDailyChangeForAccounts: {
    readonly dailyChange: any | null;
  };
  readonly getPortfolioValuationBalances: {
    readonly accountBalance: any | null;
    readonly cashBalance: any | null;
  };
  readonly " $fragmentType": "ToutsWidget_data";
};
export type ToutsWidget_data$key = {
  readonly " $data"?: ToutsWidget_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ToutsWidget_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dailyChangeInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "portfolioInput"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ToutsWidget_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "portfolioInput"
        }
      ],
      "concreteType": "FinancialAccountValuationModel",
      "kind": "LinkedField",
      "name": "getPortfolioValuationBalances",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cashBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "dailyChangeInput"
        }
      ],
      "concreteType": "GetDailyChangeForAccountsModel",
      "kind": "LinkedField",
      "name": "getDailyChangeForAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dailyChange",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientSummaryViewer",
  "abstractKey": null
};

(node as any).hash = "4822b6b926e8d1e94fab25a183cef997";

export default node;
