/**
 * @generated SignedSource<<4026c6f303692905708b0e7157f30065>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetCashFlowDetailProjectionInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type CashFlowDetailModelFilterInput = {
  accountId?: ComparableInt32OperationFilterInput | null;
  and?: ReadonlyArray<CashFlowDetailModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  cashFlowDetailId?: IdOperationFilterInput | null;
  incomeEstimateAmount?: ComparableNullableOfDecimalOperationFilterInput | null;
  incomeEstimateDate?: ComparableDateOnlyOperationFilterInput | null;
  incomeTypeName?: StringOperationFilterInput | null;
  isFederalTaxable?: BooleanOperationFilterInput | null;
  isStateTaxable?: BooleanOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  or?: ReadonlyArray<CashFlowDetailModelFilterInput> | null;
  taxStatusName?: StringOperationFilterInput | null;
};
export type IdOperationFilterInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
};
export type ComparableInt32OperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  nstartsWith?: string | null;
  or?: ReadonlyArray<StringOperationFilterInput> | null;
  startsWith?: string | null;
};
export type BooleanOperationFilterInput = {
  eq?: boolean | null;
  neq?: boolean | null;
};
export type ComparableDateOnlyOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type GetPortfolioValuationBalancesInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type GetDailyChangeForAccountsInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type GetAssetAllocationInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type GetTopSubAssetClassesInput = {
  assetClass: string;
  financialAccountIds: ReadonlyArray<number>;
  maxNumberOfRecords: number;
};
export type DashboardViewQuery$variables = {
  assetAllocationWidgetInput: GetAssetAllocationInput;
  cashFlowDashboardFilterInput: CashFlowDetailModelFilterInput;
  cashFlowDashboardInput: GetCashFlowDetailProjectionInput;
  getDailyChangeForAccountsInput: GetDailyChangeForAccountsInput;
  getPortfolioValuationBalancesInput: GetPortfolioValuationBalancesInput;
  subAssetClassWidgetInput: GetTopSubAssetClassesInput;
};
export type DashboardViewQuery$data = {
  readonly clientsummary_viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AssetClassAllocationWidget_data" | "CashFlowBreakdownWidget_data" | "SubAssetClassWidget_data" | "ToutsWidget_data">;
  };
  readonly userprofile_viewer: {
    readonly displayName: string;
    readonly id: string;
  } | null;
};
export type DashboardViewQuery = {
  response: DashboardViewQuery$data;
  variables: DashboardViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetAllocationWidgetInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cashFlowDashboardFilterInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cashFlowDashboardInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "getDailyChangeForAccountsInput"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "getPortfolioValuationBalancesInput"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subAssetClassWidgetInput"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfileViewer",
  "kind": "LinkedField",
  "name": "userprofile_viewer",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "cashFlowDashboardInput"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "cashFlowDashboardFilterInput"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "assetAllocationWidgetInput"
  }
],
v10 = {
  "kind": "Variable",
  "name": "input",
  "variableName": "subAssetClassWidgetInput"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardViewQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "kind": "FragmentSpread",
            "name": "CashFlowBreakdownWidget_data"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "dailyChangeInput",
                "variableName": "getDailyChangeForAccountsInput"
              },
              {
                "kind": "Variable",
                "name": "portfolioInput",
                "variableName": "getPortfolioValuationBalancesInput"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ToutsWidget_data"
          },
          {
            "args": (v9/*: any*/),
            "kind": "FragmentSpread",
            "name": "AssetClassAllocationWidget_data"
          },
          {
            "args": [
              (v10/*: any*/),
              {
                "kind": "Variable",
                "name": "input2",
                "variableName": "assetAllocationWidgetInput"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SubAssetClassWidget_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardViewQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          {
            "alias": "widgetData",
            "args": (v8/*: any*/),
            "concreteType": "CashFlowDetailModel",
            "kind": "LinkedField",
            "name": "getCashFlowDetailProjection",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cashFlowDetailId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxStatusName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFederalTaxable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStateTaxable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incomeTypeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incomeEstimateDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incomeEstimateAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "asOfDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "getPortfolioValuationBalancesInput"
              }
            ],
            "concreteType": "FinancialAccountValuationModel",
            "kind": "LinkedField",
            "name": "getPortfolioValuationBalances",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cashBalance",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "getDailyChangeForAccountsInput"
              }
            ],
            "concreteType": "GetDailyChangeForAccountsModel",
            "kind": "LinkedField",
            "name": "getDailyChangeForAccounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dailyChange",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "AssetAllocationByAssetClassModel",
            "kind": "LinkedField",
            "name": "getAssetAllocationByAssetClass",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetClassName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "marketValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentOfAccounts",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v10/*: any*/)
            ],
            "concreteType": "GetTopSubAssetClassesModel",
            "kind": "LinkedField",
            "name": "getTopSubAssetClasses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetClassTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TopSubAssetClassModel",
                "kind": "LinkedField",
                "name": "subClasses",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subAssetClassMarketValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subAssetClassName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95c641bcfd1b306aa95b8cb46ce5e847",
    "id": null,
    "metadata": {},
    "name": "DashboardViewQuery",
    "operationKind": "query",
    "text": "query DashboardViewQuery(\n  $cashFlowDashboardInput: GetCashFlowDetailProjectionInput!\n  $cashFlowDashboardFilterInput: CashFlowDetailModelFilterInput!\n  $getPortfolioValuationBalancesInput: GetPortfolioValuationBalancesInput!\n  $getDailyChangeForAccountsInput: GetDailyChangeForAccountsInput!\n  $assetAllocationWidgetInput: GetAssetAllocationInput!\n  $subAssetClassWidgetInput: GetTopSubAssetClassesInput!\n) {\n  userprofile_viewer {\n    id\n    displayName\n  }\n  clientsummary_viewer {\n    ...CashFlowBreakdownWidget_data_m7Zbw\n    ...ToutsWidget_data_XxmTd\n    ...AssetClassAllocationWidget_data_13OOGN\n    ...SubAssetClassWidget_data_1NCkoe\n    id\n  }\n}\n\nfragment AssetClassAllocationWidget_data_13OOGN on ClientSummaryViewer {\n  getAssetAllocationByAssetClass(input: $assetAllocationWidgetInput) {\n    assetClassName\n    marketValue\n    percentOfAccounts\n  }\n  id\n}\n\nfragment CashFlowBreakdownWidget_data_m7Zbw on ClientSummaryViewer {\n  widgetData: getCashFlowDetailProjection(input: $cashFlowDashboardInput, where: $cashFlowDashboardFilterInput) {\n    cashFlowDetailId\n    accountId\n    taxStatusName\n    isFederalTaxable\n    isStateTaxable\n    incomeTypeName\n    incomeEstimateDate\n    incomeEstimateAmount\n    asOfDate\n  }\n  id\n}\n\nfragment SubAssetClassWidget_data_1NCkoe on ClientSummaryViewer {\n  getAssetAllocationByAssetClass(input: $assetAllocationWidgetInput) {\n    assetClassName\n    marketValue\n  }\n  getTopSubAssetClasses(input: $subAssetClassWidgetInput) {\n    assetClassTotal\n    subClasses {\n      subAssetClassMarketValue\n      subAssetClassName\n    }\n  }\n  id\n}\n\nfragment ToutsWidget_data_XxmTd on ClientSummaryViewer {\n  getPortfolioValuationBalances(input: $getPortfolioValuationBalancesInput) {\n    accountBalance\n    cashBalance\n  }\n  getDailyChangeForAccounts(input: $getDailyChangeForAccountsInput) {\n    dailyChange\n  }\n}\n"
  }
};
})();

(node as any).hash = "f24dd3d338ba8078ddf54c35ddb92860";

export default node;
