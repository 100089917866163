import {
  ClientPortalAccountGroup,
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
} from '@newedge/common';
import AccountGroupItem from './AccountGroupItem';
interface AccountGroupListProps {
  accountGroups: ClientPortalAccountGroup[];
  selectedAccountGroup: UserProfileAccountGroup | null;
  onClickAccountGroup: (accountGroup: ClientPortalAccountGroup) => void;
  onAccountGroupAddAccounts: (accountGroup: ClientPortalAccountGroup) => void;
  onDeleteAccountGroup: (accountGroup: ClientPortalAccountGroup) => void;
  onDeleteAccountFromAccountGroup: (
    account: ClientPortalFinancialAccount,
    accountGroup: ClientPortalAccountGroup
  ) => void;
  onUpdateAccountGroupName: (
    accountGroup: ClientPortalAccountGroup,
    newName: string
  ) => Promise<boolean>;
  onEditAccountGroupNameInputValueChange: (
    value: string,
    currentAccountGroupName: string
  ) => void;
  onEditModeClickAway: () => void;
}

export const AccountGroupList = ({
  accountGroups,
  selectedAccountGroup,
  onClickAccountGroup,
  onAccountGroupAddAccounts,
  onDeleteAccountGroup,
  onDeleteAccountFromAccountGroup,
  onUpdateAccountGroupName,
  onEditAccountGroupNameInputValueChange,
  onEditModeClickAway,
}: AccountGroupListProps) => {
  return (
    <>
      {accountGroups.map((accountGroup) => {
        return (
          <AccountGroupItem
            key={accountGroup.id}
            accountGroup={accountGroup}
            selectedAccountGroup={selectedAccountGroup}
            onClickAccountGroup={onClickAccountGroup}
            onAccountGroupAddAccounts={onAccountGroupAddAccounts}
            onDeleteAccountGroup={onDeleteAccountGroup}
            onDeleteAccountFromAccountGroup={onDeleteAccountFromAccountGroup}
            onUpdateAccountGroupName={onUpdateAccountGroupName}
            onEditAccountGroupNameInputValueChange={
              onEditAccountGroupNameInputValueChange
            }
            onEditModeClickAway={onEditModeClickAway}
          />
        );
      })}
    </>
  );
};

export default AccountGroupList;
