// TODO: profile view should probably be named better. RiskProfile, FinancialProfile, UserProfile, etc.
import { Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { useContext } from 'react';
import { useFragment } from 'react-relay';
import { ManageAccountGroupView } from './manage-account-groups/ManageAccountGroupView';
import ManageAliases from './manage-nicknames/ManageNicknames';
import {
  UnsavedChangesContext,
  UnsavedChangesProvider,
} from './UnsavedChangesContext';
import { ProfileView_clientsummary_viewer$key } from './__generated__/ProfileView_clientsummary_viewer.graphql';
import { ProfileView_userprofile_viewer$key } from './__generated__/ProfileView_userprofile_viewer.graphql';
import usePrompt from 'src/hooks/usePrompt';
import { ManageProfileAccordion } from './manage-profile/ManageProfileAccordion';

export const UserProfileQuery = graphql`
  fragment ProfileView_userprofile_viewer on UserProfileViewer {
    id
    displayName
    accounts {
      accountNickname
    }
    ...ManageAccountGroupView_userprofile_viewer
    ...ManageNicknames_userprofile_data
  }
`;

export const ClientSummaryProfileQuery = graphql`
  fragment ProfileView_clientsummary_viewer on ClientSummaryViewer {
    ...ManageAccountGroupView_clientsummary_viewer
    ...ManageNicknames_clientsummary_data
  }
`;

type ProfileViewProps = {
  userProfileFragmentQueryRef: ProfileView_userprofile_viewer$key | null;
  clientSummaryFragmentQueryRef: ProfileView_clientsummary_viewer$key | null;
};

const ProfileView = ({
  userProfileFragmentQueryRef,
  clientSummaryFragmentQueryRef,
}: ProfileViewProps) => {
  const userProfileData = useFragment(
    UserProfileQuery,
    userProfileFragmentQueryRef
  );

  const clientSummaryData = useFragment(
    ClientSummaryProfileQuery,
    clientSummaryFragmentQueryRef
  );
  const unsavedChangesContext = useContext(UnsavedChangesContext);

  // TODO: usePrompt simply displays a window.alert() message. Verify if we need a custom modal.
  // If a custom modal is needed simply use the hook useBlocking instead of usePrompt, and then display the custom modal.
  usePrompt(
    'You have not saved your entries. Are you sure you would like to leave?',
    unsavedChangesContext.checkUnsavedChanges()
  );

  return (
    <>
      <Typography variant="special">Profile</Typography>
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <ManageAccountGroupView
            userProfileFragmentQueryRef={userProfileData}
            clientSummaryFragmentQueryRef={clientSummaryData}
          />
        </Grid>
        <Grid item xs={12}>
          <ManageAliases
            clientSummaryFragmentQueryRef={clientSummaryData}
            userProfileFragmentQueryRef={userProfileData}
          />
        </Grid>
        <Grid item xs={12}>
          <ManageProfileAccordion displayName={userProfileData?.displayName} />
        </Grid>
        {/*
        // TNG-1240: Turn Off Notifications
        <Grid item xs={12}>
          <ManageGeneralNotifications />
        </Grid>
        <Grid item xs={12}>
          <ManageInvestmentsNotifications allInvestments={ALL_INVESTMENTS} />
        </Grid> */}
      </Grid>
    </>
  );
};

const WrappedProfileView = (props: ProfileViewProps) => {
  return (
    <UnsavedChangesProvider>
      <ProfileView {...props} />
    </UnsavedChangesProvider>
  );
};

export default WrappedProfileView;
