import {
  Box,
  Button,
  styled,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-relay';
import { newEdgeNamedColors } from 'src/assets/theme';
import getAccountDisplayName from 'src/libs/utils/getAccountDisplayName';
import { UnsavedChangesContext } from '../UnsavedChangesContext';
import { ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation } from './__generated__/ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation.graphql';
import { ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation } from './__generated__/ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation.graphql';
import { Close } from '@mui/icons-material';
import { ClientPortalFinancialAccount, TooltipWrapper } from '@newedge/common';

export const UpdateFinancialaccountNickname = graphql`
  mutation ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation(
    $input: UpdateAccountNicknameInput!
  ) {
    UpdateAccountNickname(input: $input) {
      viewer {
        accounts {
          accountNickname
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

export const ResetFinancialAccountNickname = graphql`
  mutation ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation(
    $input: DeleteAccountNicknameInput!
  ) {
    DeleteAccountNickname(input: $input) {
      viewer {
        accounts {
          accountNickname
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body1,
}));

interface ManageNicknameTableRowProps {
  financialAccount: ClientPortalFinancialAccount;
  accountGroups: Array<{
    name: string;
  }>;
}

const ManageNicknameTableRow = ({
  financialAccount,
  accountGroups,
}: ManageNicknameTableRowProps) => {
  const [textfieldValue, setTextfieldValue] = useState<string>(
    getAccountDisplayName(financialAccount)
  );
  const initialNicknameValue = useRef('');
  const inputRef = useRef<any>(null);
  const [editRow, setEditRow] = useState<boolean>(false);
  const unsavedChangesContext = useContext(UnsavedChangesContext);

  const unsavedChangesContextKey = 'accountNickname-' + financialAccount.id;
  const financialAccountId = financialAccount.financialAccountId;

  const maxDisplayAccountNameLength = 72;
  const ellipsesCharacterLength = 3;

  useEffect(() => {
    setTextfieldValue(
      financialAccount.nicknameEntry?.accountNickname
        ? financialAccount.nicknameEntry?.accountNickname
        : financialAccount.accountName
    );
  }, [
    financialAccount.nicknameEntry?.accountNickname,
    financialAccount.accountName,
  ]);

  useEffect(() => {
    initialNicknameValue.current = financialAccount.nicknameEntry
      ?.accountNickname
      ? financialAccount.nicknameEntry?.accountNickname
      : financialAccount.accountName;
  }, [
    financialAccount.nicknameEntry?.accountNickname,
    financialAccount.accountName,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextfieldValue(event.target.value);
  };

  // Create the commit mutation function.
  const [commitResetAccountNicknameMutation] =
    useMutation<ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation>(
      ResetFinancialAccountNickname
    );

  // A callback function wrapping the commit mutation function.
  const resetAccountNickname = useCallback(
    async (accountId: number) => {
      commitResetAccountNicknameMutation({
        variables: {
          input: {
            financialAccountId: accountId,
          },
        },
      });
      setEditRow(false);
    },
    [commitResetAccountNicknameMutation]
  );

  const handleResetAccountNicknameClick = () => {
    resetAccountNickname(financialAccountId);
  };

  // Create the commit mutation function.
  const [commitUpdateNicknameMutation] =
    useMutation<ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation>(
      UpdateFinancialaccountNickname
    );

  // A callback function wrapping the commit mutation function.
  const updateAccountNickname = useCallback(
    async (nickname: string, accountId: number) => {
      commitUpdateNicknameMutation({
        variables: {
          input: {
            accountNickname: nickname,
            financialAccountId: accountId,
          },
        },
      });
      setEditRow(false);
    },
    [commitUpdateNicknameMutation]
  );

  const handleUpdateNicknameClick = () => {
    if (textfieldValue === '') {
      // if empty, update with financialAccount.accountName value (the default name)
      resetAccountNickname(financialAccountId);
      setTextfieldValue(financialAccount.accountName);
    } else {
      updateAccountNickname(textfieldValue, financialAccountId);
    }
    unsavedChangesContext.handleSetUnsavedChanges(
      unsavedChangesContextKey,
      false
    );
  };

  const renderButtons = () => {
    const buttons: React.ReactElement[] = [];
    if (editRow) {
      buttons.push(
        <Button
          key={financialAccount.id + '-update'}
          id={financialAccountId.toString()}
          onClick={handleUpdateNicknameClick}
        >
          Update
        </Button>
      );
      if (initialNicknameValue.current !== financialAccount.accountName) {
        buttons.push(
          <Button
            key={financialAccount.id + '-reset'}
            id={financialAccountId.toString()}
            onClick={handleResetAccountNicknameClick}
          >
            Reset
          </Button>
        );
      }
    }
    return <>{buttons}</>;
  };

  const renderToolTipForLongValue = (
    nickname: string | undefined,
    accountName: string
  ) => {
    return !nickname ||
      nickname === accountName ||
      editRow ||
      nickname.length <= maxDisplayAccountNameLength ? (
      ''
    ) : (
      <Typography variant="caption">
        {financialAccount.nicknameEntry?.accountNickname}
      </Typography>
    );
  };

  const truncateLongValue = (value: string) => {
    return editRow
      ? value
      : value.length > maxDisplayAccountNameLength
      ? `${value.substring(
          0,
          maxDisplayAccountNameLength - ellipsesCharacterLength
        )}...`
      : value;
  };

  const renderClearButton = (
    isEditMode: boolean,
    currentValue: string,
    targetControlRef: MutableRefObject<any>,
    accountId: number
  ): ReactNode | null => {
    return isEditMode && currentValue ? (
      <InputAdornment position="end">
        <IconButton
          key={accountId + '-clear'}
          id={accountId.toString()}
          sx={({ spacing }) => ({ padding: spacing(0.3) })}
          onClick={() => {
            setTextfieldValue('');
            targetControlRef.current?.focus();
          }}
          edge="end"
          aria-label="clear"
        >
          <Close
            sx={{ color: newEdgeNamedColors.lightIndigo }}
            fontSize="large"
          />
        </IconButton>
      </InputAdornment>
    ) : null;
  };

  return (
    <TableRow>
      <StyledTableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            sx={(theme) => ({ ...theme.typography.caption })}
            title={renderToolTipForLongValue(
              financialAccount.nicknameEntry?.accountNickname,
              financialAccount.accountName
            )}
            disableInteractive
            disableFocusListener
            disableTouchListener
          >
            <TextField
              value={truncateLongValue(textfieldValue)}
              onChange={handleChange}
              inputRef={inputRef}
              color="secondary"
              size="small"
              sx={(theme) => ({
                minWidth: '170px',
                fieldset: {
                  border: 'none',
                },

                '&:hover': {
                  backgroundColor: theme.extensions.filter[12],
                  border: `1px solid white`,
                },
                '&:focus-within': {
                  border: `1px solid ${newEdgeNamedColors.cadet}`,
                  backgroundColor: theme.extensions.filter[12],
                },
              })}
              inputProps={{
                onFocus: () => {
                  setEditRow(true);
                },
                onBlur: (event) => {
                  // hide button(s) if value hasn't been updated
                  // don't hide buttons if they're the related target (for click or tab over)
                  if (
                    textfieldValue === initialNicknameValue.current &&
                    (!event.relatedTarget ||
                      (event.relatedTarget as Element).id !==
                        financialAccountId.toString())
                  ) {
                    setEditRow(false);
                  }
                },
                maxLength: 72,
              }}
              InputProps={{
                endAdornment: renderClearButton(
                  editRow,
                  textfieldValue,
                  inputRef,
                  financialAccountId
                ),
              }}
            ></TextField>
          </Tooltip>
          {renderButtons()}
        </Box>
      </StyledTableCell>
      <StyledTableCell>{financialAccount.accountNumber}</StyledTableCell>
      <StyledTableCell>
        <TooltipWrapper
          text={financialAccount.registrationName ?? ''}
          maxLength={72}
        />
      </StyledTableCell>
      <StyledTableCell>{financialAccount.managementStyle}</StyledTableCell>
      <StyledTableCell>{financialAccount.accountType}</StyledTableCell>
      <StyledTableCell>{financialAccount.custodian}</StyledTableCell>
      <StyledTableCell>
        {accountGroups &&
          accountGroups.map((accountGroup) => {
            return <div key={accountGroup.name}>{accountGroup.name}</div>;
          })}
      </StyledTableCell>
    </TableRow>
  );
};

export { ManageNicknameTableRow };
