import { Dispatch, forwardRef, LegacyRef, useState } from 'react';
import { useMutation } from 'react-relay';
import { ActionType } from '../ManageAccountGroupReducer';
import AccountDataGrid from './AccountDataGrid';
import graphql from 'babel-plugin-relay/macro';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { EditAccountGroup_AddAccountsToAccountGroupMutation } from './__generated__/EditAccountGroup_AddAccountsToAccountGroupMutation.graphql';
import SearchIcon from '@mui/icons-material/Search';
import {
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
  UserProfileFinancialAccount,
} from '@newedge/common';

const AddAccountsToAccountGroupMutation = graphql`
  mutation AddAccountsToAccountGroupMutation(
    $input: AccountGroupAccountChangesInput!
  ) {
    AddAccountsToAccountGroup(input: $input) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

interface AddAccountsToAccountGroupProps {
  allAccounts: ClientPortalFinancialAccount[];
  alreadySelectedAccounts: ClientPortalFinancialAccount[];
  dispatch: Dispatch<any>;
  addedAccounts: ClientPortalFinancialAccount[];
  selectedAccountGroup: UserProfileAccountGroup | null;
}

const AddAccountsToAccountGroup = forwardRef(
  (
    {
      allAccounts,
      alreadySelectedAccounts,
      dispatch,
      addedAccounts,
      selectedAccountGroup,
    }: AddAccountsToAccountGroupProps,
    ref
  ) => {
    const [filterText, setFilterText] = useState<string>('');

    const handleBackToAccountGroups = () => {
      dispatch({
        type: ActionType.TOGGLE_SHOW_ACCOUNT_GROUPS,
        payload: {
          selectedAccountGroup: null,
          textFieldValue: '',
          addedAccounts: [],
        },
      });
    };

    const [commitAddAccountsToAccountGroup] =
      useMutation<EditAccountGroup_AddAccountsToAccountGroupMutation>(
        AddAccountsToAccountGroupMutation
      );

    const handleClickUpdateAccountGroupAccounts = async () => {
      const accountsToAdd = addedAccounts.map((x) => {
        return {
          id: uuid(),
          financialAccountId: x.financialAccountId,
        } as UserProfileFinancialAccount;
      });
      commitAddAccountsToAccountGroup({
        variables: {
          input: {
            id: selectedAccountGroup!.id,
            accounts: accountsToAdd,
          },
        },
      });
      const result = [...alreadySelectedAccounts, ...addedAccounts];
      dispatch({
        type: ActionType.ACCOUNTS_CONFIRMED,
        payload: {
          alreadySelectedAccounts: result,
        },
      });
      dispatch({
        type: ActionType.TOGGLE_SHOW_ACCOUNT_GROUPS,
        payload: {},
        addedAccounts: [],
      });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(event.target.value);
    };

    return (
      <Grid
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        container
      >
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: '2rem' }}>
            Check the boxes below to add account(s) to the selected account
            group. Select the “Update Account Group” to save any changes.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider
            sx={({ palette }) => ({
              borderColor: palette.greyPercent[50],
            })}
          />
        </Grid>

        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs="auto">
            <Typography
              variant="h4"
              sx={({ spacing }) => ({
                paddingLeft: spacing(4),
                marginTop: spacing(2),
                marginBottom: spacing(2),
              })}
            >
              {selectedAccountGroup?.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              placeholder="Account Search"
              sx={({ spacing }) => ({
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '& input': {
                    paddingTop: spacing(1),
                    paddingBottom: spacing(1),
                  },
                },
              })}
              type="search"
              variant="outlined"
              size="medium"
              onChange={handleSearch}
              value={filterText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              color="iron"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div ref={ref as LegacyRef<HTMLDivElement>}>
            <AccountDataGrid
              allAccounts={allAccounts}
              alreadySelectedAccounts={alreadySelectedAccounts}
              dispatch={dispatch}
              filterText={filterText}
              addedAccounts={addedAccounts}
            />
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: theme.spacing(2),
              })}
            >
              <Button
                color="secondary"
                onClick={() => handleBackToAccountGroups()}
              >
                Back to Account Groups
              </Button>
              <Button onClick={() => handleClickUpdateAccountGroupAccounts()}>
                Update Account Group
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
    );
  }
);

export default AddAccountsToAccountGroup;
