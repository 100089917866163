import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { FilterItem } from 'src/components/table-filter/@types';
import _ from 'lodash';
import { HoldingsDetailView, HoldingsDetailModel } from '@newedge/reports';
import {
  UserProfileFinancialAccountNickname,
  findNicknameForAccountId,
} from '@newedge/common';
import { FinancialAccountInfo } from 'src/@types/global';
import { useContext, useMemo } from 'react';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import { HoldingsDetailQueryReaderQuery } from './__generated__/HoldingsDetailQueryReaderQuery.graphql';

export const HoldingsDetailQueryReaderNode = graphql`
  query HoldingsDetailQueryReaderQuery(
    $holdingsDetailInput: GetHoldingsDetailInput!
    $holdingsDetailFilterInput: HoldingsDetailModelFilterInput
  ) {
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      getHoldingsDetail(
        input: $holdingsDetailInput
        where: $holdingsDetailFilterInput
      ) {
        holdingsId
        accountId
        assetClass
        costBasis
        asOfDate
        subAssetClass
        currentMarketValue
        managementStyle
        productName
        quantity
        price
        subAssetClass
        ticker
        unrealizedGain
        unrealizedGainPercent
        yield
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
      id
    }
  }
`;

interface HoldingsDetailQueryReaderProps {
  queryRef: PreloadedQuery<HoldingsDetailQueryReaderQuery>;
  filterValues: FilterItem[];
  hasInitialFilters: boolean;
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string | null) => void;
}

export const HoldingsDetailQueryReader = ({
  queryRef,
  filterValues,
  hasInitialFilters,
  setFilterValues,
  setAsOfDate,
}: HoldingsDetailQueryReaderProps) => {
  const data = usePreloadedQuery(HoldingsDetailQueryReaderNode, queryRef);
  const date = data.clientsummary_viewer?.getHoldingsDetail[0]?.asOfDate;
  const accounts = useMemo(
    () =>
      combineUserProfileAndClientSummaryFinancialAccountInfo(
        data.userprofile_viewer
          ?.accounts as UserProfileFinancialAccountNickname[],
        data.clientsummary_viewer?.financialAccounts as FinancialAccountInfo[]
      ),
    [
      data.clientsummary_viewer?.financialAccounts,
      data.userprofile_viewer?.accounts,
    ]
  );

  const holdingsData = useMemo(
    () =>
      (
        data.clientsummary_viewer?.getHoldingsDetail as HoldingsDetailModel[]
      )?.map((holding) => {
        return {
          ...holding,
          accountNickname: findNicknameForAccountId(
            accounts,
            holding?.accountId
          ),
        } as HoldingsDetailModel;
      }),
    [accounts, data.clientsummary_viewer?.getHoldingsDetail]
  );

  const { selectedAccounts } = useContext(GlobalFilterContext);

  return (
    <HoldingsDetailView
      holdingsData={holdingsData}
      date={date}
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      setAsOfDate={setAsOfDate}
      accounts={accounts}
      selectedAccounts={selectedAccounts}
      hasInitialFilters={hasInitialFilters}
    />
  );
};
