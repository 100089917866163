import { Box, Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { PreloadedQuery } from 'react-relay';
import AsOfDate from 'src/components/reportHeader/AsOfDate';
import { LandingPageViewQuery } from './__generated__/LandingPageViewQuery.graphql';
import { GlobalAccountSelectWrapper } from 'src/components/global-account-select';

interface ReportWrapperProps {
  title: string;
  children: ReactNode;
  landingPageQueryRef?: PreloadedQuery<LandingPageViewQuery>;
  asOfDate?: string | null;
  hideGlobalAccountSelect?: boolean;
}

export const ReportWrapper = ({
  title,
  children,
  landingPageQueryRef,
  asOfDate,
  hideGlobalAccountSelect = false,
}: ReportWrapperProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="special" data-testid="ReportTitle">
          {title}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
            },
          })}
        >
          {landingPageQueryRef && !hideGlobalAccountSelect ? (
            <GlobalAccountSelectWrapper
              landingPageQueryRef={landingPageQueryRef}
            />
          ) : null}
          {asOfDate !== undefined ? <AsOfDate asOfDate={asOfDate} /> : null}
        </Box>
      </Grid>
      <Grid item xs={12} justifyContent="space-between" alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
};

export default ReportWrapper;
