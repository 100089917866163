import { styled } from '@mui/material/styles';

const PREFIX = 'LegendEntry';

const classes = {
  root: `${PREFIX}-root`,
  symbol: `${PREFIX}-symbol`,
  label: `${PREFIX}-label`,
};

const Root = styled('div')(
  ({ theme: { spacing, typography, breakpoints }, color }) => ({
    [`&.${classes.root}`]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: spacing(1),
    },

    [`& .${classes.symbol}`]: {
      marginRight: spacing(1),
      width: '10px',
      height: '10px',
      'border-radius': '50%',
      '-moz-border-radius': '50%',
      '-webkit-border-radius': '50%',
      background: color,
    },

    [`& .${classes.label}`]: {
      ...typography.body1,
      [breakpoints.down('sm')]: {
        ...typography.body3,
        wordBreak: 'break-all',
        whiteSpace: 'unset',
        color: '#FFFFF',
      },
    },
  })
);

type LegendEntryProps = {
  label: string | Date | number;
  color: string;
};

export function LegendEntry(props: LegendEntryProps) {
  const { label, color } = props;

  return (
    <Root className={classes.root} color={color}>
      <div className={classes.symbol} />
      <span className={classes.label}>
        {label instanceof Date ? label.toString() : label}
      </span>
    </Root>
  );
}

export default LegendEntry;
