import { Box, Divider, Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import WidgetWrapper from 'src/components/widget/Widget';
import { RowContent } from './@types';

export type VerticalContentProps<T extends RowContent> = {
  data: T[];
  children?: ReactNode;
};

const VerticalContent = ({
  data,
  children,
}: VerticalContentProps<RowContent>) => {
  return (
    <Grid
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Grid item xs={12}>
        {data.map((item) => (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 1,
              }}
            >
              <Typography variant="body2">{item.label}</Typography>
              <Typography variant="h4">{item.value}</Typography>
            </Box>
            <Divider
              sx={{ backgroundColor: (theme) => theme.palette.text.secondary }}
            />
          </>
        ))}
      </Grid>
      {children && (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 1,
              paddingTop: 1.6,
            }}
          >
            <Typography variant="body2">{children}</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

type ToutStackProps<T extends RowContent> = {
  title: string;
  data: T[];
  footer?: ReactNode;
};

export const ToutStack = ({
  data,
  title,
  footer,
}: ToutStackProps<RowContent>) => {
  return (
    <WidgetWrapper
      title={title}
      widgets={[
        {
          element: <VerticalContent data={data} children={footer} />,
        },
      ]}
    />
  );
};

export default ToutStack;
