import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { FilterItem } from 'src/components/table-filter/@types';
import { AccountBalancesQueryReaderQuery } from './__generated__/AccountBalancesQueryReaderQuery.graphql';
import { FinancialAccountInfo } from 'src/@types/global';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import graphql from 'babel-plugin-relay/macro';
import { AccountBalancesModel, AccountBalancesView } from '@newedge/reports';
import { useContext } from 'react';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import {
  UserProfileFinancialAccountNickname,
  UserProfileAccountGroup,
} from '@newedge/common';

export const AccountBalancesQueryReaderNode = graphql`
  query AccountBalancesQueryReaderQuery(
    $accountBalancesInput: GetAccountBalancesInput!
    $accountBalancesFilterInput: AccountBalancesModelFilterInput
  ) {
    clientsummary_viewer {
      getAccountBalances(
        input: $accountBalancesInput
        where: $accountBalancesFilterInput
      ) {
        accountDefaultName
        accountId
        accountType
        asOfDate
        cashBalance
        dailyChange
        isLiability
        marketValue
        managementStyle
        totalBalance
        unrealizedGl
        unrealizedGlPercent
        valuationId
        beginningMarketValue
        endingMarketValue
        netCashFlows
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
      id
    }
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
  }
`;

interface AccountBalancesQueryReaderProps {
  queryRef: PreloadedQuery<AccountBalancesQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
}

export const AccountBalancesQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  setAsOfDate,
}: AccountBalancesQueryReaderProps) => {
  const data = usePreloadedQuery(AccountBalancesQueryReaderNode, queryRef);

  const date = data.clientsummary_viewer?.getAccountBalances[0]?.asOfDate;
  const accountBalances = data.clientsummary_viewer
    ?.getAccountBalances as AccountBalancesModel[];
  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    data.userprofile_viewer?.accounts as UserProfileFinancialAccountNickname[],
    data.clientsummary_viewer?.financialAccounts as FinancialAccountInfo[]
  );
  const accountGroups = data.userprofile_viewer
    ?.accountGroups as UserProfileAccountGroup[];

  const { selectedAccounts } = useContext(GlobalFilterContext);

  return (
    <AccountBalancesView
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      setAsOfDate={setAsOfDate}
      date={date}
      accountBalances={accountBalances}
      accounts={accounts}
      accountGroups={accountGroups}
      selectedAccounts={selectedAccounts}
    />
  );
};
