import { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router';
import ClientPortalLogo from '../branding/ClientPortalLogo';

const PREFIX = 'Styled';

const classes = {
  root: `${PREFIX}-root`,
  centerVertical: `${PREFIX}-centerVertical`,
  logoOuterContainer: `${PREFIX}-logoOuterContainer`,
  logoContainer: `${PREFIX}-logoContainer`,
  messageRoot: `${PREFIX}-messageRoot`,
};

const Root = styled('div')(({ theme: { spacing } }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.centerVertical}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.logoOuterContainer}`]: {
    flexGrow: 1,
    display: 'flex',
  },

  [`& .${classes.logoContainer}`]: {
    margin: 'auto',
    width: 1094,
  },

  [`& .${classes.messageRoot}`]: {
    flexGrow: 2,
    maxWidth: 1350,
    padding: spacing(0, 3),
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

type Props = PropsWithChildren<{}> & {};

function MessageRoot({}: Props) {
  return (
    <Root className={classes.root}>
      <div className={classes.logoOuterContainer}>
        <div className={classes.logoContainer}>{<ClientPortalLogo />}</div>
      </div>
      <div className={classes.messageRoot}>
        <Outlet />
      </div>
    </Root>
  );
}

const Styled = MessageRoot;
export { Styled as MessageRoot };
export default Styled;
