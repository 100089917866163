/**
 * @generated SignedSource<<b8ad72cba5b3e3896431323a095afd34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileView_userprofile_viewer$data = {
  readonly accounts: ReadonlyArray<{
    readonly accountNickname: string;
  }>;
  readonly displayName: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ManageAccountGroupView_userprofile_viewer" | "ManageNicknames_userprofile_data">;
  readonly " $fragmentType": "ProfileView_userprofile_viewer";
};
export type ProfileView_userprofile_viewer$key = {
  readonly " $data"?: ProfileView_userprofile_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileView_userprofile_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileView_userprofile_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNickname",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageAccountGroupView_userprofile_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageNicknames_userprofile_data"
    }
  ],
  "type": "UserProfileViewer",
  "abstractKey": null
};

(node as any).hash = "6a73635db55b7bd8d230cd6aae517884";

export default node;
