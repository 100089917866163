/* eslint-disable no-console */

import {
  PublicClientApplication,
  LogLevel,
  Configuration as MsalConfig,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import appConfig from './appConfig';

const { userAgent } = window.navigator;
const msie = userAgent.indexOf('MSIE ');
const msie11 = userAgent.indexOf('Trident/');
const msedge = userAgent.indexOf('Edge/');
const firefox = userAgent.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// TODO: define logout flow. Right now all routes are authenticated, so it redirects to a login page.
const postLogoutPath = '/logged-out' as const;
const postLogoutRedirectUri = `${window.location.origin}${postLogoutPath}`;
const msalConfig: MsalConfig = {
  auth: {
    authority: `${appConfig.oidcAuthority}/${appConfig.oidcTenant}/${appConfig.oidcFlow}`,
    clientId: appConfig.oidcClient,
    redirectUri: appConfig.oidcRedirect,
    knownAuthorities: [appConfig.oidcAuthority],
    postLogoutRedirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    secureCookies: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string): void => {
        switch (level) {
          case LogLevel.Error:
            console.error('[MSAL]', message);
            return;
          case LogLevel.Info:
            console.info('[MSAL]', message);
            return;
          case LogLevel.Verbose:
            console.debug('[MSAL]', message);
            return;
          case LogLevel.Warning:
            console.warn('[MSAL]', message);
            break;
          default:
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 20000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

const msalClient = new PublicClientApplication(msalConfig);

export default msalClient;

// Available scopes for access token requests
export const accessScopes = {
  openid: 'openid',
  profile: 'profile',
  gatewayApi: `https://${appConfig.oidcTenant}/wm-gql-gateway/read`,
} as const;

export const loginRequest = {
  scopes: ['openid', 'profile'],
};

// prettier-ignore
export type AccessScopes = typeof accessScopes[keyof typeof accessScopes];
