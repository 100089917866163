import { Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export type NavigatablePaths =
  | '/my/cash-flow'
  | '/my/asset-allocation'
  | '/my/holdings'
  | '/my/account-activity'
  | '/my/account-balances';

export type MoreDetailsProps = {
  destination: NavigatablePaths;
  options?: NavigateOptions;
};

export const MoreDetailsLink = ({ destination, options }: MoreDetailsProps) => {
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => {
        window.scrollTo(0, 0);
        navigate(destination, options);
      }}
      underline="hover"
    >
      More details
      <ArrowForwardIcon
        style={{
          marginLeft: '5px',
          fontSize: '1.2rem',
        }}
      />
    </Link>
  );
};

export default MoreDetailsLink;
