import { LegendEntry } from './LegendEntry';
import { KeyValueTypes } from './@types';
import { ColorScheme } from 'src/assets/colorScheme';

export interface LegendData {
  label: KeyValueTypes;
  shade?: boolean;
}

export type CustomLegendProps = {
  series: LegendData[];
  vertical?: boolean;
  colorScheme: ColorScheme;
  reverse?: boolean;
};

export function CustomLegend({
  series,
  colorScheme,
  vertical = true,
  reverse = false,
}: CustomLegendProps) {
  const legendEntries = series.map(({ label }, index) => {
    return (
      <LegendEntry
        key={`legend-entry-${label}`}
        label={label}
        color={colorScheme[index].primary}
      />
    );
  });

  if (reverse) {
    legendEntries.reverse();
  }

  return <>{legendEntries}</>;
}

export default CustomLegend;
