/**
 * @generated SignedSource<<caff6ca7a04f043597cd218da973623e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAccountNicknameInput = {
  financialAccountId: number;
};
export type ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation$variables = {
  input: DeleteAccountNicknameInput;
};
export type ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation$data = {
  readonly DeleteAccountNickname: {
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
    }> | null;
    readonly viewer: {
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
    } | null;
  };
};
export type ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation = {
  response: ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation$data;
  variables: ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAccount",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNickname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "financialAccountId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "DeleteAccountNickname",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "DeleteAccountNickname",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0dddc2dbf0fd948485cc2c7e67d8802",
    "id": null,
    "metadata": {},
    "name": "ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation",
    "operationKind": "mutation",
    "text": "mutation ManageNicknameTableRow_DeleteFinancialAccountNicknameMutation(\n  $input: DeleteAccountNicknameInput!\n) {\n  DeleteAccountNickname(input: $input) {\n    viewer {\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      id\n    }\n    errors {\n      __typename\n      message\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a99bae4911ac90ce0f7497dcff1d148b";

export default node;
