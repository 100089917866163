/**
 * @generated SignedSource<<e2be7622e4dbfdb5a2470fad14cf3d13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageNicknames_clientsummary_data$data = {
  readonly financialAccounts: ReadonlyArray<{
    readonly accountName: string;
    readonly accountNumber: string;
    readonly accountType: string;
    readonly custodian: string;
    readonly id: string;
    readonly managementStyle: string;
    readonly registrationName: string;
  }>;
  readonly " $fragmentType": "ManageNicknames_clientsummary_data";
};
export type ManageNicknames_clientsummary_data$key = {
  readonly " $data"?: ManageNicknames_clientsummary_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageNicknames_clientsummary_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageNicknames_clientsummary_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAccount",
      "kind": "LinkedField",
      "name": "financialAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managementStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "custodian",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientSummaryViewer",
  "abstractKey": null
};

(node as any).hash = "100b27adb7c74ad79bf2b4a4ecccb340";

export default node;
