import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataAlignment } from '../sortable-table/@types';
import { CompactNestedTableColumn } from './@types';
import { CompactNestedTableRow } from './CompactNestedTableRow';

interface CompactNestedTableProps<T, S> {
  columns: CompactNestedTableColumn<T, S>[];
  data: T[];
}

export const CompactNestedTable = <T, S>({
  columns,
  data,
}: CompactNestedTableProps<T, S>) => {
  return (
    <TableContainer>
      <Table aria-label='compact nested table' sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: 1 }} width='5%' />
            {columns.map((column, index) => {
              const width = column.width;
              return (
                <TableCell
                  width={width ? width : undefined}
                  sx={{ padding: 1 }}
                  align={
                    column.dataAlignment === DataAlignment.Right
                      ? 'right'
                      : 'left'
                  }
                  key={`compact-nested-table-row-header-${index}`}
                >
                  <Typography variant='body1'>{column.label}</Typography>
                </TableCell>
              );
            })}
            <TableCell sx={{ padding: 1 }} width='5%' />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <CompactNestedTableRow
                columns={columns}
                rowData={row}
                key={`compact-nested-table-row-${index}`}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompactNestedTable;
