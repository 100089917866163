import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { newEdgeNamedColors } from 'src/assets/theme';

type TermsAndConditionsRouteGuardProps = PropsWithChildren<{
  acceptedTermsVersion: string;
  currentTermsVersion: string;
  onTermsAccepted: () => void;
}>;

// TODO:  Replace lorem ipsum with terms from the database and a gql call
const TermsAndConditionsRouteGuard = ({
  acceptedTermsVersion,
  currentTermsVersion,
  onTermsAccepted,
  children,
}: TermsAndConditionsRouteGuardProps) => {
  const [openTerms, setOpenTerms] = useState(
    () => acceptedTermsVersion !== currentTermsVersion
  );
  const [enabledAgree, setEnabledAgree] = useState(false);

  useEffect(() => {
    if (acceptedTermsVersion === currentTermsVersion) return;
    setOpenTerms(true);
  }, [acceptedTermsVersion, currentTermsVersion]);

  const handleAccept = () => {
    setOpenTerms(false);
    onTermsAccepted();
  };

  const handleClose = (
    _: object,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') return;
    setOpenTerms(false);
  };

  const isUserAtTheBottomOfTerms = (
    event: React.UIEvent<HTMLDivElement>
  ): boolean => {
    //Since the height can sometimes be a decimal, we can use a small range to
    // make sure it will return true without needing to be too precise.
    return (
      Math.abs(
        event.currentTarget.scrollHeight -
          (event.currentTarget.scrollTop + event.currentTarget.clientHeight)
      ) <= 1
    );
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (isUserAtTheBottomOfTerms(event)) {
      setEnabledAgree(true);
    }
  };

  return (
    <>
      <Dialog
        open={openTerms}
        onClose={handleClose}
        disableEscapeKeyDown
        aria-labelledby="terms-and-conditions-dialog-title"
        aria-describedby="terms-and-conditions-dialog-description"
        PaperProps={{ sx: { maxHeight: '50%' } }}
        sx={{
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <DialogTitle id="terms-and-conditions-dialog-title">
          {'Terms and Conditions'}
        </DialogTitle>
        <DialogContent
          id="terms-and-conditions-dialog-description"
          onScroll={handleOnScroll}
          sx={{ textAlign: 'justify' }}
        >
          <Typography variant="h3">
            Website Disclosure & Terms of Use
          </Typography>
          <Typography variant="body1">
            This website does not represent an offer of or a solicitation for
            advisory services in any state/jurisdiction of the U.S. or any
            country where NewEdge Capital Group, LLC, NewEdge Securities, Inc.,
            NewEdge Advisors, LLC and NewEdge Wealth, LLC or their affiliates
            (together, “NewEdge”) are not registered, notice filed, or exempt.
            NewEdge is not providing any investment, tax, legal or other advice
            through or in its provision of the website. You understand that any
            investment, tax, marketing or legal information provided in the
            website is general and educational in nature and should not be
            construed as advice. NewEdge provides advice and makes
            recommendations based on the specific needs and circumstances of
            each client. Please remember that different types of investments
            involve varying degrees of risk, and there can be no assurance that
            the future performance of any specific investment or investment
            strategy (including those undertaken or recommended by NewEdge) will
            be profitable or equal any historical performance level(s). Clients
            should carefully consider their own investment objectives and never
            rely on any single chart, graph or marketing piece in making
            investment decisions. This website may contain links to third-party
            sites. We do not control or endorse and are not responsible for the
            accuracy or reliability of any content, operation, or security of
            these sites. We are not responsible for their terms of use, privacy
            policies, or any other third-party websites that may be linked to or
            by their pages. Further, the presence of a link to a third-party
            site does not mean that NewEdge endorses that site, its products, or
            views expressed there. NewEdge provides these links merely for
            convenience and the presence of such third-party links are not an
            endorsement or recommendation by NewEdge. You use these sites at
            your own risk.
          </Typography>
          <Typography variant="body3">Intellectual Property Rights</Typography>
          <Typography variant="body1">
            Service Content, Software and Trademarks: You acknowledge and agree
            that our website may contain content or features (“Service Content”)
            that are protected by copyright, patent, trademark, trade secret or
            other proprietary rights and laws. Except as expressly authorized by
            us, you agree not to modify, copy, frame, scrape, rent, lease, loan,
            sell, distribute or create derivative works based on the website or
            the Service Content, in whole or in part, except that the foregoing
            does not apply to your own User Data (as defined below) that you
            legally provide through this website. In connection with your use of
            the website, you will not engage in or use any data mining, robots,
            scraping or similar data gathering or extraction methods. If you are
            blocked by NewEdge from accessing the website (including by blocking
            your IP address), you agree not to implement any measures to
            circumvent such blocking (e.g., by masking your IP address or using
            a proxy IP address). Any use of the website or the Service Content
            other than as specifically authorized herein is strictly prohibited.
            The technology and software underlying the website or distributed in
            connection therewith, including without limitation the Mobile App,
            are the property of NewEdge, our affiliates and our partners (the
            “Software”). You agree not to copy, modify, create a derivative work
            of, reverse engineer, reverse assemble, decompile, disassemble or
            otherwise attempt to discover any source code, sell, assign,
            sublicense, or otherwise transfer any right in the Software. NewEdge
            reserves any rights not expressly granted herein. The NewEdge name
            and logos are trademarks and service marks of NewEdge Capital Group,
            LLC collectively the “NECG Trademarks”). Other company, product,
            trade and service names and logos used and displayed via the website
            may be trademarks, trade names or service marks of their respective
            owners (together with the NECG Trademarks, the “Trademarks”) who may
            or may not endorse or be affiliated with or connected to NewEdge.
            The Trademarks are the sole and exclusive property of NewEdge or
            such third parties, as applicable, and you will not acquire any
            proprietary rights therein by reason of these Terms of Service or
            your access or use of the Service. Nothing in this Terms of USE or
            the website should be construed as granting, by implication,
            estoppel, or otherwise, any license or right to use any Trademarks
            displayed on or in connection with the website, without our prior
            written permission in each instance. As between you and NewEdge
            Capital Group, LLC all goodwill generated from the use of Trademarks
            will inure to our exclusive benefit. Third-Party Material: Under no
            circumstances will NewEdge be liable in any way for any content or
            materials of any third parties (including users), including, but not
            limited to, for any errors or omissions in any content, or for any
            loss or damage of any kind incurred as a result of the use of any
            such content. You acknowledge that NewEdge does not pre-screen
            content, but that NewEdge and its designees will have the right (but
            not the obligation) in their sole discretion to refuse or remove any
            content that is available via the website. Without limiting the
            foregoing, NewEdge and its designees will have the right to remove
            any content that violates these Terms of Use or is deemed by
            NewEdge, in its sole discretion, to be otherwise objectionable. You
            agree that you must evaluate, and bear all risks associated with,
            the use of any content, including any reliance on the accuracy,
            completeness, or usefulness of such content. User Data Transmitted
            Through the Website: With respect to the data, content or other
            materials you upload through the website or share with other users
            or recipients (collectively, “User Data”), you represent and warrant
            that you own all right, title and interest in and to such User Data,
            including, without limitation, all copyrights and rights of
            publicity contained therein. You acknowledge and agree that NewEdge
            may preserve content and may also disclose content if required to do
            so by Applicable Law or in the good faith belief that such
            preservation or disclosure is reasonably necessary to: (a) comply
            with legal process, Applicable Laws or government requests; (b)
            enforce these Terms of Use; (c) respond to claims that any content
            violates the rights of third parties; or (d) protect the rights,
            property, or personal safety of NewEdge, its users and the public.
            You understand that the technical processing and transmission of the
            Service, including your content, may involve (i) transmissions over
            various networks; and (ii) changes to conform and adapt to technical
            requirements of connecting networks or devices.
          </Typography>
          <Typography variant="body3">Indemnity and Release</Typography>
          <Typography variant="body1">
            You agree to defend, indemnify and hold harmless NewEdge and its
            affiliates, directors, officers, equity holders, and employees,
            against any third-party claims, actions or proceedings, damages,
            losses, costs and expenses (including reasonable attorneys’ fees)
            resulting from, arising out of, or in connection with, your access
            or use of the website, any User Data, your breach of these Terms of
            Use, your violation of Applicable Law or your negligent acts or
            omissions and/or willful misconduct. If you are a California
            resident, you waive California Civil Code Section 1542, which says:
            “A general release does not extend to claims that the creditor or
            releasing party does not know or suspect to exist in his or her
            favor at the time of executing the release and that, if known by him
            or her, would have materially affected his or her settlement with
            the debtor or released.” If you are a resident of another
            jurisdiction, you waive any comparable statute or doctrine to the
            extent permitted under Applicable Law.
          </Typography>
          <Typography variant="body3">Disclaimer of Warranties</Typography>
          <Typography variant="body1">
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. NEWEDGE ON BEHALF
            OF ITSELF AND THE OTHER NEWEDGE PARTIES EXPRESSLY DISCLAIM ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
            WITHOUT LIMITATION, ANY WARRANTIES CONCERNING ACCESS OR USE OF THIS
            WEBSITE; TELECOMMUNICATIONS, COMPUTERS AND INTERNET USAGE; AND THE
            USAGE OF INFORMATION POSTED ON THE SITE, WHETHER OR NOT SUCH
            INFORMATION IS PASSWORD ACCESSIBLE, INCLUDING, BUT NOT LIMITED TO
            THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE AND NON-INFRINGEMENT. NEWEDGE ON BEHALF OF ITSELF AND
            THE OTHER NEWEDGE PARTIES MAKE NO WARRANTY THAT (A) THE WEBSITE WILL
            MEET YOUR REQUIREMENTS, (B) THE WEBSITE WILL BE UNINTERRUPTED,
            TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED
            FROM THE USE OF THE WEBSITE WILL BE ACCURATE OR RELIABLE, OR (IV)
            THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET
            YOUR EXPECTATIONS.
          </Typography>
          <Typography variant="body3">Limitation of Liability</Typography>
          <Typography variant="body1">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE NEWEDGE PARTIES WILL NOT
            BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
            LIMITED TO, DAMAGES FOR LOST TIME, INCOME, REVENUE, GOODWILL,
            PROFITS, LOSS OF PROFITS, USE, LOSS OF USE, TRADING LOSSES, LOSS OF
            OTHER COST OR SAVINGS, LOSS OF DATA OR OTHER INTANGIBLE LOSSES (EVEN
            IF ANY NEWEDGE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
            LIABILITY OR OTHERWISE, RESULTING FROM, ARISING OUT OF, OR IN
            CONNECTION WITH: (A) THE USE OR THE INABILITY TO USE THE SERVICE;
            (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
            RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR
            OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH
            OR FROM THE SERVICE; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF
            YOUR TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF ANY THIRD
            PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE
            SERVICE. IN NO EVENT WILL ANY NEWEDGE PARTY’S TOTAL LIABILITY TO YOU
            FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU
            HAVE PAID NEWEDGE IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE
            HUNDRED DOLLARS ($100). SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME
            OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IN
            SUCH JURISDICTIONS, THE LIABILITY OF THE NEWEDGE SHALL BE LIMITED TO
            THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. IF YOU ARE
            DISSATISFIED WITH ANY PORTION OF THE WEBSITE OR WITH THESE TERMS OF
            USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE
            SERVICE.
          </Typography>
          <Typography variant="body3">Notice for New Jersey Users</Typography>
          <Typography variant="body1">
            IF YOU ARE A USER FROM NEW JERSEY, THE INDEMNITY AND RELEASE,
            DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY SECTIONS ARE
            INTENDED TO BE ONLY AS BROAD AND INCLUSIVE AS IS PERMITTED UNDER NEW
            JERSEY LAW. IF ANY PORTION OF SUCH SECTIONS IS HELD TO BE INVALID
            UNDER NEW JERSEY LAW, THE INVALIDITY OF SUCH PORTION SHALL NOT
            AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THOSE SECTIONS.
          </Typography>
          <Typography variant="body3">Notice for California Users</Typography>
          <Typography variant="body1">
            Under California Civil Code Section 1789.3, users of the website
            from California are entitled to the following specific consumer
            rights notice: The Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            may be contacted in writing at 1625 North Market Blvd., Suite N 112,
            Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800)
            952-5210. You may contact us at NewEdge, Suite 510, 1251 Waterfront
            Place, Pittsburgh, PA, 15222.
          </Typography>
          <Typography variant="body3">Notice for California Users</Typography>
          <Typography variant="body1">
            Under California Civil Code Section 1789.3, users of the Service
            from California are entitled to the following specific consumer
            rights notice: The Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            may be contacted in writing at 1625 North Market Blvd., Suite N 112,
            Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800)
            952-5210. You may contact us at NewEdge, Suite 510, 1251 Waterfront
            Place, Pittsburgh, PA, 15222.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAccept}
            variant="contained"
            disabled={!enabledAgree}
            sx={{
              background: (theme) =>
                enabledAgree
                  ? theme.palette.primary.main
                  : newEdgeNamedColors.cadet,
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};

export default TermsAndConditionsRouteGuard;
