import { Box, Button, styled, Theme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TileProps } from 'src/components/cards/@types';

const ButtonWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 1,
});

interface ToutInfoProps {
  eyebrowText: TileProps['eyebrowText'];
  headlineText: TileProps['headlineText'];
}

const ToutInfo = ({ eyebrowText, headlineText }: ToutInfoProps) => {
  return (
    <div>
      <Typography variant="h4" sx={{ padding: 1 }}>
        {eyebrowText}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          flexGrow: 1,
          paddingBottom: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {headlineText}
      </Typography>
    </div>
  );
};

interface AccountBalanceToutsProps {
  toutData: TileProps[];
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

interface AccountBalanceMobileViewToutsProps {
  netAssets: TileProps | undefined;
  totalAssets: TileProps | undefined;
  totalLiabilities: TileProps | undefined;
  seeMoreDetail: (event: React.MouseEvent<HTMLElement>) => void;
}

interface MobileToutProps {
  label: string;
  value: string | number | undefined;
}

const MobileTout = ({ label, value }: MobileToutProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.greyPercent[28]}`,
        borderRadius: theme.spacing(1),
        overflow: 'hidden',
        flex: 1,
        padding: theme.spacing(1),
      })}
    >
      <Typography variant="body2">{label}</Typography>
      <Typography variant="h4">{value}</Typography>
    </Box>
  );
};

const AccountBalanceMobileView = ({
  netAssets,
  totalAssets,
  totalLiabilities,
  seeMoreDetail,
}: AccountBalanceMobileViewToutsProps) => {
  return (
    <Box sx={{ width: '100%', borderRadius: 1.5, overflow: 'hidden' }}>
      <Box sx={(theme) => ({ backgroundColor: theme.palette.greyPercent[28] })}>
        <Typography variant="body1" sx={{ padding: 1 }}>
          Account Balances
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.greyPercent[10],
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        })}
      >
        {totalAssets && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2">Total Assets</Typography>
            <Typography variant="h3">{totalAssets.headlineText}</Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1 }}>
          {totalLiabilities && (
            <MobileTout
              label="Total Liabilities"
              value={totalLiabilities.headlineText as string}
            />
          )}
          {netAssets && (
            <MobileTout
              label="Net Assets"
              value={netAssets.headlineText as string}
            />
          )}
        </Box>
        <ButtonWrapper>
          <Button onClick={(event) => seeMoreDetail(event)}>
            See More Detail
          </Button>
        </ButtonWrapper>
      </Box>
    </Box>
  );
};

export const AccountBalanceTouts = ({
  toutData,
  onClick,
}: AccountBalanceToutsProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const getToutData = (text: string) => {
    return toutData.find((data) => data.eyebrowText === text);
  };

  const totalAssets = getToutData('Total Assets');
  const totalLiabilities = getToutData('Total Liabilities');
  const netAssets = getToutData('Net Assets');

  if (isMobile) {
    return (
      <AccountBalanceMobileView
        totalAssets={totalAssets}
        totalLiabilities={totalLiabilities}
        netAssets={netAssets}
        seeMoreDetail={onClick}
      />
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {toutData.map((data: TileProps, index) => (
        <ToutInfo
          eyebrowText={data.eyebrowText}
          headlineText={data.headlineText}
          key={`account-balance-tout-${index}`}
        />
      ))}
      <ButtonWrapper>
        <Button
          size="large"
          onClick={(event) => onClick(event)}
          sx={{ marginTop: 3 }}
        >
          See More Detail
        </Button>
      </ButtonWrapper>
    </Box>
  );
};

export default AccountBalanceTouts;
