import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { useNavigate } from 'react-router';
import { newEdgeNamedColors } from 'src/assets/theme';
import { Annotation } from 'src/components/cards/@types';
import ToutTile from 'src/components/cards/ToutTile';
import { ToutsWidget_data$key } from './__generated__/ToutsWidget_data.graphql';

export const ToutsWidgetQuery = graphql`
  fragment ToutsWidget_data on ClientSummaryViewer
  @argumentDefinitions(
    portfolioInput: { type: "GetPortfolioValuationBalancesInput!" }
    dailyChangeInput: { type: "GetDailyChangeForAccountsInput!" }
  ) {
    getPortfolioValuationBalances(input: $portfolioInput) {
      accountBalance
      cashBalance
    }
    getDailyChangeForAccounts(input: $dailyChangeInput) {
      dailyChange
    }
  }
`;

type ToutsWidgetProps = {
  annotations: Annotation[];
  data: ToutsWidget_data$key;
};

export function ToutsWidget(props: ToutsWidgetProps) {
  const toutData = useFragment(ToutsWidgetQuery, props.data);

  const navigate = useNavigate();

  const handleMoreDetailsClick = () => {
    navigate('/my/account-balances');
  };

  const toutInfo = [
    {
      eyebrow: 'Total Balance',
      headline:
        toutData?.getPortfolioValuationBalances !== undefined
          ? Number(toutData.getPortfolioValuationBalances.accountBalance)
          : undefined,
      captionText: 'More details',
      color: newEdgeNamedColors.white,
      smallHeadline: false,
      onClick: handleMoreDetailsClick,
    },
    {
      eyebrow: 'Cash & Cash Equivalents',
      headline:
        toutData?.getPortfolioValuationBalances !== undefined
          ? Number(toutData.getPortfolioValuationBalances.cashBalance)
          : undefined,
      captionText: 'More details',
      color: newEdgeNamedColors.white,
      onClick: handleMoreDetailsClick,
    },
    {
      eyebrow: 'Daily Change',
      headline:
        toutData?.getDailyChangeForAccounts !== undefined
          ? Number(toutData.getDailyChangeForAccounts.dailyChange)
          : undefined,
      captionText: 'More details',
      color: newEdgeNamedColors.white,
      useIconToDisplaySign: true,
      isPercent: true,
      onClick: handleMoreDetailsClick,
      annotation: props.annotations[0],
    },
    // {
    //   eyebrow: 'Past 30 Day Disbursements',
    //   headline: 789890,
    //   captionText: 'More detail',
    //   color: newEdgeNamedColors.white,
    //   backgroundColor: newEdgeNamedColors.zeus,
    //   smallHeadline: true,
    // },
    // {
    //   eyebrow: 'Past 30 Day Deposits',
    //   headline: 191101,
    //   captionText: 'More detail',
    //   color: newEdgeNamedColors.white,
    //   backgroundColor: newEdgeNamedColors.zeus,
    //   smallHeadline: true,
    // },
    // {
    //   eyebrow: 'Past 30 Day Income',
    //   headline: -980901,
    //   captionText: 'More detail',
    //   color: newEdgeNamedColors.white,
    //   backgroundColor: newEdgeNamedColors.zeus,
    //   smallHeadline: true,
    // },
  ];

  return (
    <>
      {toutInfo.map((x, i) => {
        return (
          <ToutTile
            key={`DashboardToutTile_${i}`}
            eyebrowText={x.eyebrow}
            headlineText={x.headline}
            captionText={x.captionText}
            color={x.color}
            useIconToDisplaySign={x.useIconToDisplaySign}
            isPercent={x.isPercent}
            onClick={x.onClick}
            annotationObject={x.annotation}
          />
        );
      })}
    </>
  );
}

export default ToutsWidget;
