import { Annotation } from 'src/components/cards/@types';

export const DashboardAnnotations: Annotation[] = [
  {
    identifier: 'dashboard-daily-change-annotation',
    superscriptText: '1',
    annotationText:
      'We use the daily balance method to calculate the interest on your account. This method applies a daily periodic rate to the principal in the account each day.',
  },
];
