import {
  ClientPortalFinancialAccount,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { FinancialAccountInfo } from 'src/@types/global';
import { convertRelayIdToIdentifier } from 'src/libs/relay/helpers/cache-helpers';

export const combineUserProfileAndClientSummaryFinancialAccountInfo = (
  userProfileAccounts: UserProfileFinancialAccountNickname[] | null | undefined,
  clientSummaryAccounts: FinancialAccountInfo[] | null | undefined
): ClientPortalFinancialAccount[] => {
  if (!userProfileAccounts || !clientSummaryAccounts)
    return [] as ClientPortalFinancialAccount[];
  return clientSummaryAccounts?.map(
    (clientSummaryFinancialAccount: FinancialAccountInfo) => {
      const userProfileAccountInfo = userProfileAccounts?.find(
        (userProfileAccount) => {
          const financialAccountIdResult = Number(
            convertRelayIdToIdentifier(
              'FinancialAccount',
              clientSummaryFinancialAccount.id
            )
          );
          return (
            userProfileAccount.financialAccountId === financialAccountIdResult
          );
        }
      );

      return {
        id: clientSummaryFinancialAccount.id,
        accountName: clientSummaryFinancialAccount.accountName,
        defaultAccountName: clientSummaryFinancialAccount.accountName,
        nicknameEntry: userProfileAccountInfo,
        financialAccountId: Number(
          convertRelayIdToIdentifier(
            'FinancialAccount',
            clientSummaryFinancialAccount.id
          )
        ),
        clientIdentifier: clientSummaryFinancialAccount.clientIdentifier,
        accountNumber: clientSummaryFinancialAccount.accountNumber,
        registrationName: clientSummaryFinancialAccount.registrationName,
        managementStyle: clientSummaryFinancialAccount.managementStyle,
        accountType: clientSummaryFinancialAccount.accountType,
        accountTypeAbbreviation:
          clientSummaryFinancialAccount.accountTypeAbbreviation,
        custodian: clientSummaryFinancialAccount.custodian,
      };
    }
  );
};

export default combineUserProfileAndClientSummaryFinancialAccountInfo;
