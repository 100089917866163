import { Cell } from 'recharts';
import uniqueId from 'lodash.uniqueid';
import { get } from 'lodash';
import { ColorScheme, ColorSchemeProvider } from 'src/assets/colorScheme';
import { DataKey } from 'recharts/types/util/types';

export function getValueByDataKey<T>(obj: T, dataKey: DataKey<T>) {
  if (obj === undefined) return undefined;
  if (typeof dataKey === 'function') {
    return dataKey(obj);
  }
  return get(obj, dataKey);
}

export function dataToCells<T = any>(
  data: ReadonlyArray<T>,
  colorScheme?: ColorScheme
): JSX.Element[] {
  return data.map((_, index) => {
    const fill = ColorSchemeProvider.forIndexFromScheme(
      colorScheme || [],
      index
    );

    return <Cell key={uniqueId('cell-')} fill={fill} />;
  });
}

/**
 * "Rounds" number up to the nearest half standard value (i.e. (330,000) => 350,000)
 * @param value Number value to be rounded
 */
const niceNum = (value: number) => {
  const exponent = Math.floor(Math.log10(value));
  const fraction = value / 10 ** exponent;

  return Math.ceil(fraction / 0.5) * 0.5 * 10 ** exponent;
};

/**
 * Calculates the friendly chart ticks for a given chart range
 * @param domainRange Tuple of Max and Min values of domain
 * @param maxTicks Number of ticks to render
 */
const getChartTicks = (domainRange: [number, number], maxTicks: number = 6) => {
  const range = niceNum(domainRange[1] - domainRange[0]);
  const tickSpacing = niceNum(range / maxTicks - 1);

  return new Array<number>(maxTicks).fill(0).reduce((acc, _, idx) => {
    const next = idx === 0 ? 0 : acc[idx - 1] + tickSpacing;
    acc.push(next);
    return acc;
  }, [] as number[]);
};

export { getChartTicks };
