import { useMsal } from '@azure/msal-react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  Theme,
  ListItemButton,
  Button,
} from '@mui/material';
import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutDialog = () => {
  const { instance } = useMsal();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleUserLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleLogoutCancellation = () => {
    setLogoutModalOpen(false);
  };

  const handleLogoutConfirmation = () => {
    instance.logout();
  };

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {isMobileOrTablet ? (
        <ListItemButton
          sx={{
            paddingRight: '10px',
            cursor: 'pointer',
            '& .MuiListItemText-root': {
              color: 'gray',
              textTransform: 'uppercase',
              fontSize: '1.4rem',
              lineHeight: '1.6rem',
            },
            '& .MuiListItemIcon-root': {
              minWidth: '32px',
              marginRight: '15px',
              fontSize: '24px',
              color: 'gray',
            },
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0)',
              '& .MuiListItemText-root': {
                color: 'white',
                marginLeft: '10px',
              },
              '& .MuiListItemIcon-root': {
                color: 'white',
              },
            },
          }}
          onClick={handleUserLogout}
        >
          <ListItemIcon
            sx={(theme) => ({
              ...theme.typography.h3,
            })}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} disableTypography />
        </ListItemButton>
      ) : (
        <div onClick={handleUserLogout}>Logout</div>
      )}
      <Dialog
        open={logoutModalOpen}
        onClose={handleLogoutCancellation}
        aria-labelledby="confirm-logout"
        sx={(theme) => ({
          ...theme.typography.body1,
        })}
      >
        <DialogContent>
          <DialogContentText id="confirm-logout-description">
            Log out from the portal?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancellation}>Cancel</Button>
          <Button autoFocus onClick={handleLogoutConfirmation}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogoutDialog;
