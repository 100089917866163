import { PreloadedQuery, useQueryLoader } from 'react-relay';
import { LandingPageViewQuery } from '../__generated__/LandingPageViewQuery.graphql';
import { Suspense, useContext, useEffect, useState } from 'react';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import {
  CashFlowDetailQueryReaderQuery,
  CashFlowDetailQueryReaderQuery$variables,
} from './__generated__/CashFlowDetailQueryReaderQuery.graphql';
import {
  CashFlowDetailQueryReader,
  CashFlowDetailQueryReaderNode,
} from './CashFlowDetailQueryReader';
import { FilterItem } from 'src/components/table-filter/@types';
import ReportWrapper from '../ReportWrapper';
import { Skeleton } from '@mui/material';

const applySecondaryFilters = (
  variables: CashFlowDetailQueryReaderQuery$variables,
  filterValues: FilterItem[]
): CashFlowDetailQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);
  let input = newVariables.cashFlowDetailInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.cashFlowDetailInput = {
      financialAccountIds: [...ids],
    };
  }
  return newVariables;
};

interface CashFlowDetailLoaderProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}
export const CashFlowDetailLoader = ({
  landingPageQueryRef,
}: CashFlowDetailLoaderProps) => {
  const { cashFlowDetailQueryVariables } = useContext(QueryVariablesContext);
  const [cashFlowQueryRef, loadCashFlowQuery] =
    useQueryLoader<CashFlowDetailQueryReaderQuery>(
      CashFlowDetailQueryReaderNode
    );

  const [filterValues, setFilterValues] = useState<FilterItem[]>([]);
  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  useEffect(() => {
    loadCashFlowQuery(
      applySecondaryFilters(cashFlowDetailQueryVariables, filterValues)
    );
  }, [cashFlowDetailQueryVariables, filterValues, loadCashFlowQuery]);

  return cashFlowQueryRef != null ? (
    <ReportWrapper
      title="Expected Cash Flow"
      landingPageQueryRef={landingPageQueryRef}
      asOfDate={asOfDate}
    >
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <CashFlowDetailQueryReader
          queryRef={cashFlowQueryRef}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setAsOfDate={setAsOfDate}
        />
      </Suspense>
    </ReportWrapper>
  ) : null;
};
