import {
  alpha,
  ButtonBase,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import clsx from 'clsx';
import { newEdgeNamedColors } from 'src/assets/theme';
import { abbreviateNumber } from 'src/libs/utils/numberFormatters';
import { TileProps } from './@types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router';

const PREFIX = 'ToutTile';

const classes = {
  root: `${PREFIX}-root`,
  eyebrow: `${PREFIX}-eyebrow`,
  eyebrowContainer: `${PREFIX}-eyebrowContainer`,
  annotation: `${PREFIX}-annotation`,
  headline: `${PREFIX}-headline`,
  headlineDetails: `${PREFIX}-headlineDetails`,
  caption: `${PREFIX}-caption`,
  alignText: `${PREFIX}-alignText`,
  captionLinkStyle: `${PREFIX}-captionLinkStyle`,
  iconContainer: `${PREFIX}-iconContainer`,
  icon: `${PREFIX}-icon`,
};

const StyledGrid = styled(Grid)(
  ({ theme: { spacing, typography, palette, breakpoints }, color }) => ({
    [`& .${classes.root}`]: {
      backgroundColor: palette.transparentBackground.main,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: '10px',
    },

    [`& .${classes.eyebrow}`]: {
      color,
      ...typography.toutEyebrow,
      [breakpoints.down('sm')]: {
        ...typography.body1,
      },
    },

    [`& .${classes.eyebrowContainer}`]: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: alpha(palette.greyPercent[24] as string, 0.65),
      borderRadius: '10px 10px 0 0',
      height: '3rem',
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },

    [`& .${classes.annotation}`]: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },

    [`& .${classes.headline}`]: {
      color,
      flexGrow: 1,
      ...typography.toutHeadline,
      display: 'flex',
      alignItems: 'center',
      fontSize: '3.6rem',
    },

    [`& .${classes.headlineDetails}`]: {
      display: 'flex',
      padding: spacing(1.5),
      alignItems: 'center',
      justifyContent: 'space-around',
    },

    [`& .${classes.caption}`]: {
      justifyContent: 'flex-start',
      ...typography.toutCaption,
    },

    [`& .${classes.alignText}`]: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [breakpoints.down('sm')]: {
        ...typography.h3,
      },
    }),

    [`& .${classes.captionLinkStyle}`]: {
      color: color ? color : 'black',
      textDecoration: 'none',
      ...typography.toutCaption,
      [breakpoints.down('sm')]: {
        ...typography.body2,
      },
    },

    [`& .${classes.iconContainer}`]: {
      lineHeight: '5.4rem',
    },

    [`& .${classes.icon}`]: {
      color: newEdgeNamedColors.white,
      marginRight: spacing(1),
    },
  })
);

export function ToutTile({
  eyebrowText,
  eyebrowLinkText,
  eyebrowLinkPath,
  secondaryFilters,
  headlineText,
  captionText,
  annotationObject,
  color,
  smallHeadline = false,
  useIconToDisplaySign,
  isPercent = false,
  ...props
}: TileProps): JSX.Element {
  const {
    root,
    eyebrow,
    eyebrowContainer,
    annotation,
    headline,
    caption,
    captionLinkStyle,
    alignText,
    iconContainer,
    icon,
    headlineDetails,
  } = classes;

  let headlineConverted: string | number;

  const renderSignIcon = (value: string | number) => {
    if (value.toString().indexOf('-') > -1) {
      return (
        <div className={clsx(iconContainer)}>
          <RemoveIcon sx={{ fontSize: '2rem' }} className={icon} />
        </div>
      );
    }

    return (
      <div className={clsx(iconContainer)}>
        <AddIcon sx={{ fontSize: '2rem' }} className={icon} />
      </div>
    );
  };

  if (
    typeof headlineText === 'string' &&
    !Number(headlineText) &&
    Number(headlineText) !== 0
  ) {
    headlineConverted = headlineText;
  } else {
    headlineConverted = abbreviateNumber(Number(headlineText), isPercent);
  }

  const renderHeadlineTextOrLoading = () => {
    if (headlineText !== undefined) {
      return (
        <Typography className={clsx(headline, alignText)}>
          {useIconToDisplaySign && renderSignIcon(headlineConverted)}
          {!smallHeadline &&
            useIconToDisplaySign &&
            headlineConverted.toString().replace('-', '')}
          {!smallHeadline && !useIconToDisplaySign && headlineConverted}
          {smallHeadline && headlineConverted}
        </Typography>
      );
    }
    return (
      <div style={{ padding: '8px 0px' }}>
        <Skeleton variant="rectangular" width="100%" height={64} />
      </div>
    );
  };

  const navigate = useNavigate();

  const handleEyebrowLinkClick = () => {
    if (eyebrowLinkPath != undefined && eyebrowLinkPath != null) {
      navigate(eyebrowLinkPath.toString(), { state: { secondaryFilters } });
    }
  };

  return (
    <StyledGrid item xs={12} sm={12} md={12} lg={4} color={color}>
      <Paper className={root}>
        <div className={eyebrowContainer}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography className={eyebrow}>{eyebrowText}</Typography>
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="row-reverse"
                display={
                  eyebrowLinkText == undefined || eyebrowLinkText == null
                    ? 'none'
                    : ''
                }
              >
                <Grid item>
                  <ButtonBase
                    onClick={handleEyebrowLinkClick}
                    sx={{ textDecoration: 'underline' }}
                  >
                    {eyebrowLinkText}
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {annotationObject && (
            <Typography
              className={annotation}
              onClick={() => {
                const element = document.getElementById(
                  annotationObject.identifier
                );
                window.scrollTo({
                  behavior: element ? 'smooth' : 'auto',
                  top: element ? element.offsetTop : 0,
                });
              }}
            >
              [{annotationObject.superscriptText}]
            </Typography>
          )}
        </div>
        <div className={headlineDetails}>
          {renderHeadlineTextOrLoading()}
          <Typography className={clsx(caption)}>
            {captionText && (
              <div className={clsx(alignText)}>
                <Link
                  className={clsx(captionLinkStyle, alignText)}
                  onClick={(event) => {
                    if (props.onClick === null || props.onClick === undefined) {
                      return;
                    } else {
                      props.onClick(event);
                    }
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  {captionText}
                  <ArrowForwardIcon
                    style={{
                      marginLeft: '5px',
                      fontSize: '1.2rem',
                    }}
                  />
                </Link>
              </div>
            )}
          </Typography>
        </div>
      </Paper>
    </StyledGrid>
  );
}

export default ToutTile;
