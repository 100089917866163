/**
 * @generated SignedSource<<0831c6486c864e228f93622a367a61bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CashFlowBreakdownWidget_data$data = {
  readonly id: string;
  readonly widgetData: ReadonlyArray<{
    readonly accountId: number;
    readonly asOfDate: any | null;
    readonly cashFlowDetailId: string;
    readonly incomeEstimateAmount: any | null;
    readonly incomeEstimateDate: any;
    readonly incomeTypeName: string;
    readonly isFederalTaxable: boolean;
    readonly isStateTaxable: boolean;
    readonly taxStatusName: string;
  }>;
  readonly " $fragmentType": "CashFlowBreakdownWidget_data";
};
export type CashFlowBreakdownWidget_data$key = {
  readonly " $data"?: CashFlowBreakdownWidget_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CashFlowBreakdownWidget_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CashFlowBreakdownWidget_data",
  "selections": [
    {
      "alias": "widgetData",
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "CashFlowDetailModel",
      "kind": "LinkedField",
      "name": "getCashFlowDetailProjection",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cashFlowDetailId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxStatusName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFederalTaxable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStateTaxable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomeTypeName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomeEstimateDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomeEstimateAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "asOfDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ClientSummaryViewer",
  "abstractKey": null
};

(node as any).hash = "8ddcbba844b0d41ca6c637fbfac2c99b";

export default node;
