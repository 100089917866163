import graphql from 'babel-plugin-relay/macro';
import {
  AccountActivityFilterOptions,
  AccountActivityView,
} from '@newedge/reports';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import {
  AccountActivityQueryReaderQuery,
  AccountActivityQueryReaderQuery$data,
} from './__generated__/AccountActivityQueryReaderQuery.graphql';
import {
  ClientPortalFinancialAccount,
  FilterItem,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import { FinancialAccountInfo } from 'src/@types/global';
import { AccountActivityModel } from '@newedge/reports/src/views/account-activity/@types';
import findNicknameForAccountId from 'src/libs/utils/findNicknameForAccountId';

export const AccountActivityQueryReaderQueryNode = graphql`
  query AccountActivityQueryReaderQuery(
    $allAccounts: GetAccountActivityInput!
    $selectedAccounts: GetAccountActivityInput!
    $filters: AccountActivityDataModelFilterInput
  ) {
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      getAccountActivity {
        data(input: $selectedAccounts, where: $filters) {
          accountId
          accountNumber
          amount
          transactionDate
          ticker
          productName
          units
          price
          transactionType
          managementStyle
        }
        filterOptions(input: $allAccounts) {
          managementStyles
          maxTransactionAmount
          minTransactionAmount
          transactionTypes
        }
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
      id
    }
  }
`;

const addAccountNickname = (
  activityData: AccountActivityQueryReaderQuery$data['clientsummary_viewer']['getAccountActivity']['data'],
  accountData: ClientPortalFinancialAccount[]
): AccountActivityModel[] => {
  return activityData?.map((o) => {
    return {
      ...o,
      accountNickname: findNicknameForAccountId(accountData, o.accountId),
    } as AccountActivityModel;
  });
};

interface AccountActivityQueryReaderProps {
  queryRef: PreloadedQuery<AccountActivityQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  hasInitialFilters: boolean;
}

export const AccountActivityQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  hasInitialFilters,
}: AccountActivityQueryReaderProps) => {
  const data = usePreloadedQuery(AccountActivityQueryReaderQueryNode, queryRef);

  const accounts: ClientPortalFinancialAccount[] =
    combineUserProfileAndClientSummaryFinancialAccountInfo(
      data.userprofile_viewer
        ?.accounts as UserProfileFinancialAccountNickname[],
      data.clientsummary_viewer?.financialAccounts as FinancialAccountInfo[]
    );

  const filteredAccountActivityData = addAccountNickname(
    data.clientsummary_viewer?.getAccountActivity.data,
    accounts
  );

  return (
    <AccountActivityView
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      filterOptions={
        data.clientsummary_viewer.getAccountActivity
          .filterOptions as AccountActivityFilterOptions
      }
      hasInitialFilters={hasInitialFilters}
      accounts={accounts}
      filteredAccountActivityData={filteredAccountActivityData}
    />
  );
};
