import {
  alpha,
  Box,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import graphql from 'babel-plugin-relay/macro';
import { useState } from 'react';
import { PreloadedQuery, useFragment, usePreloadedQuery } from 'react-relay';
import { useNavigate } from 'react-router';
import { FinancialAccountInfo } from 'src/@types/global';
import AccordionItem from 'src/components/accordion/AccordionItem';
import { combineUserProfileAndClientSummaryFinancialAccountInfo } from 'src/models/ClientPortalFinancialAccount';
import {
  AccountBalancesModel,
  computeAccountBalancesToutData,
  projectAccountGroupBalances,
} from '@newedge/reports';
import { DashboardViewAccountBalanceWidgetQueryNode } from '../DashboardView';
import { DashboardViewAccountBalanceWidgetQuery } from '../__generated__/DashboardViewAccountBalanceWidgetQuery.graphql';
import { AccountBalanceAccountOnlyTable } from './AccountBalanceAccountOnlyTable';
import { AccountBalanceTable } from './AccountBalanceTable';
import { AccountBalanceTouts } from './AccountBalanceTouts';
import { AccountBalanceWidget_query$key } from './__generated__/AccountBalanceWidget_query.graphql';
import {
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';

export const AccountBalanceWidgetQuery = graphql`
  fragment AccountBalanceWidget_query on Query
  @argumentDefinitions(
    input: { type: "GetAccountBalancesInput!" }
    where: { type: "AccountBalancesModelFilterInput" }
  ) {
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      getAccountBalances(input: $input, where: $where) {
        accountDefaultName
        accountId
        accountType
        cashBalance
        dailyChange
        isLiability
        marketValue
        managementStyle
        totalBalance
        unrealizedGl
        unrealizedGlPercent
        valuationId
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
      id
    }
  }
`;

const StyledTableContainer = styled('div')({
  height: '100%',
  flex: 1,
  overflowY: 'scroll',
  padding: 0,
  margin: 0,
});

interface AccountBalanceWidgetProps {
  queryRef: PreloadedQuery<DashboardViewAccountBalanceWidgetQuery>;
}

export const AccountBalanceWidget = ({
  queryRef,
}: AccountBalanceWidgetProps) => {
  const queryData = usePreloadedQuery(
    DashboardViewAccountBalanceWidgetQueryNode,
    queryRef
  );

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const handleMoreDetailClick = () => {
    navigate('/my/account-balances');
  };

  const fragmentData = useFragment<AccountBalanceWidget_query$key>(
    AccountBalanceWidgetQuery,
    queryData
  );

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    fragmentData.userprofile_viewer
      ?.accounts as UserProfileFinancialAccountNickname[],
    fragmentData.clientsummary_viewer
      ?.financialAccounts as FinancialAccountInfo[]
  );

  const accountBalancesDataWithNicknames: AccountBalancesModel[] | undefined =
    fragmentData.clientsummary_viewer?.getAccountBalances.map((o) => {
      const nickname = accounts.find(
        (account) => account.nicknameEntry?.financialAccountId === o.accountId
      )?.nicknameEntry?.accountNickname;
      return {
        ...o,
        accountNickname: nickname ? nickname : o.accountDefaultName,
      } as AccountBalancesModel;
    });

  const toutData = computeAccountBalancesToutData(
    accountBalancesDataWithNicknames
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const renderAccountBalancesTable = () => {
    if (
      fragmentData.userprofile_viewer &&
      fragmentData.userprofile_viewer.accountGroups.length === 0
    ) {
      return (
        <StyledTableContainer>
          <AccountBalanceAccountOnlyTable
            data={accountBalancesDataWithNicknames}
          />
        </StyledTableContainer>
      );
    }

    const accountGroupBalanceData = projectAccountGroupBalances(
      fragmentData.userprofile_viewer
        ?.accountGroups as UserProfileAccountGroup[],
      accountBalancesDataWithNicknames ?? []
    );

    return (
      <StyledTableContainer>
        <AccountBalanceTable data={accountGroupBalanceData} />
      </StyledTableContainer>
    );
  };

  return (
    <Box sx={{ width: '100%', paddingBottom: 3 }}>
      {isMobile ? (
        <AccountBalanceTouts
          toutData={toutData}
          onClick={handleMoreDetailClick}
        />
      ) : (
        <AccordionItem
          sx={{
            bgcolor: (theme) =>
              alpha(theme.palette.greyPercent[24] as string, 0.65),
          }}
          accordionSummaryProps={{ sx: { bgcolor: 'rgba(61, 61, 61, 0.65)' } }} // TODO: reference color from theme
          summaryComponent={
            <Typography variant="h3" sx={{ marginLeft: 2 }}>
              Account Balances
            </Typography>
          }
          detailsComponent={
            <Grid container sx={{ display: 'flex', height: '400px' }}>
              <Grid
                style={{ margin: 0, padding: 0 }}
                item
                xs={8}
                sx={{
                  height: '100%',
                  padding: 2,
                  paddingRight:
                    fragmentData.userprofile_viewer &&
                    fragmentData.userprofile_viewer.accountGroups.length === 0
                      ? 0
                      : 2,
                }}
              >
                {renderAccountBalancesTable()}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 2,
                  borderLeft: '2px solid #7F7F7F', // TODO: reference '#7F7F7F' from palette in theme
                  height: '100%',
                  margin: 0,
                }}
              >
                <AccountBalanceTouts
                  toutData={toutData}
                  onClick={handleMoreDetailClick}
                />
              </Grid>
            </Grid>
          }
          isExpanded={isExpanded}
          onChange={() => setIsExpanded(!isExpanded)}
        />
      )}
    </Box>
  );
};
