import _ from 'lodash';
import { DateTime } from 'luxon';
import { getCashFlowInput } from 'src/libs/relay/queryVariables';
import { CashFlowBreakdown, CashFlowDataEntry } from './@types';
import { CashFlowBreakdownWidget_data$data } from './__generated__/CashFlowBreakdownWidget_data.graphql';
import { CashFlowRecord, computeTotals } from '@newedge/reports';

interface DataIndex {
  month: number;
  year: number;
}

const getTotals = (data: readonly CashFlowRecord[]) => {
  const tableData = computeTotals(data);

  return tableData && tableData.length > 0
    ? tableData.find((x) => x.category === 'Totals')
    : undefined;
};

export const computeChartData = (
  data: CashFlowBreakdownWidget_data$data
): CashFlowDataEntry[] => {
  const newChartData: CashFlowDataEntry[] = [];

  // given the start date, get array of the next 12 months to drive data calcs
  const cashFlowInput = getCashFlowInput();

  const startMonth = DateTime.fromISO(cashFlowInput.startDate).month - 1;
  const startYear = DateTime.fromISO(cashFlowInput.startDate).year;

  const dates: DataIndex[] = [...Array(12).keys()].map((x) => {
    const month = ((x + startMonth) % 12) + 1;
    return {
      month,
      year: month <= startMonth ? startYear + 1 : startYear,
    };
  });

  if (!data) {
    return newChartData;
  }

  // group data by month
  const grouped = _.groupBy(data.widgetData, (value) => {
    const date = DateTime.fromISO(value?.incomeEstimateDate as string);
    return date.month;
  });

  const totals = getTotals(data.widgetData);

  dates
    .sort((date) => date.year * 100 + date.month)
    .forEach((date, index) => {
      const monthEntry = {
        key: date.month,
        data: {
          Taxable: 0,
          'Tax Exempt': 0,
          'Tax Deferred': 0,
        },
        metadata: { isComplete: true, year: date.year, total: 0 },
      } as CashFlowDataEntry;
      const incomes = grouped[date.month];
      if (incomes && incomes.length > 0) {
        incomes.forEach((incomeDetail) => {
          if (incomeDetail) {
            const key = incomeDetail.taxStatusName as keyof CashFlowBreakdown;
            const amount = incomeDetail.incomeEstimateAmount as number;
            monthEntry.data[key] = monthEntry.data[key] + amount;
          }
        });

        monthEntry.metadata.total = totals ? totals.cashArray[index] : 0;
      }
      newChartData.push(monthEntry);
    });

  return newChartData;
};

export const computeTotalCashFlowBreakdown = (
  data: CashFlowDataEntry[]
): CashFlowBreakdown => {
  const initialValue: CashFlowBreakdown = {
    Taxable: 0,
    'Tax Exempt': 0,
    'Tax Deferred': 0,
  };
  return data.reduce((output, item) => {
    return {
      Taxable: output.Taxable + item.data.Taxable,
      'Tax Exempt': output['Tax Exempt'] + item.data['Tax Exempt'],
      'Tax Deferred': output['Tax Deferred'] + item.data['Tax Deferred'],
    };
  }, initialValue);
};
