/**
 * @generated SignedSource<<639aade8a85710b87b08e5f63eb7f8d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetAccountBalancesInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type AccountBalancesModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountType?: StringOperationFilterInput | null;
  and?: ReadonlyArray<AccountBalancesModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  beginningMarketValue?: ComparableDecimalOperationFilterInput | null;
  cashBalance?: ComparableDecimalOperationFilterInput | null;
  dailyChange?: ComparableNullableOfDecimalOperationFilterInput | null;
  dailyChangeEndDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  dailyChangeStartDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  endingMarketValue?: ComparableDecimalOperationFilterInput | null;
  isLiability?: BooleanOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  marketValue?: ComparableDecimalOperationFilterInput | null;
  netCashFlows?: ComparableDecimalOperationFilterInput | null;
  or?: ReadonlyArray<AccountBalancesModelFilterInput> | null;
  totalBalance?: ComparableDecimalOperationFilterInput | null;
  unrealizedGl?: ComparableDecimalOperationFilterInput | null;
  unrealizedGlPercent?: ComparableNullableOfDecimalOperationFilterInput | null;
  valuationId?: ComparableInt32OperationFilterInput | null;
};
export type ComparableInt32OperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  nstartsWith?: string | null;
  or?: ReadonlyArray<StringOperationFilterInput> | null;
  startsWith?: string | null;
};
export type ComparableDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type BooleanOperationFilterInput = {
  eq?: boolean | null;
  neq?: boolean | null;
};
export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type DashboardViewAccountBalanceWidgetQuery$variables = {
  accountBalancesWidgetInput: GetAccountBalancesInput;
  accountBalancesWidgetWhere?: AccountBalancesModelFilterInput | null;
};
export type DashboardViewAccountBalanceWidgetQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountBalanceWidget_query">;
};
export type DashboardViewAccountBalanceWidgetQuery = {
  response: DashboardViewAccountBalanceWidgetQuery$data;
  variables: DashboardViewAccountBalanceWidgetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountBalancesWidgetInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountBalancesWidgetWhere"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "accountBalancesWidgetInput"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "accountBalancesWidgetWhere"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managementStyle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardViewAccountBalanceWidgetQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AccountBalanceWidget_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardViewAccountBalanceWidgetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNickname",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroup",
            "kind": "LinkedField",
            "name": "accountGroups",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountBalancesModel",
            "kind": "LinkedField",
            "name": "getAccountBalances",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountDefaultName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountId",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cashBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dailyChange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLiability",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "marketValue",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unrealizedGl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unrealizedGlPercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valuationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FinancialAccount",
            "kind": "LinkedField",
            "name": "financialAccounts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientIdentifier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationName",
                "storageKey": null
              },
              (v5/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountTypeAbbreviation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "custodian",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5aca5805144d29ba51a8009e84f66c10",
    "id": null,
    "metadata": {},
    "name": "DashboardViewAccountBalanceWidgetQuery",
    "operationKind": "query",
    "text": "query DashboardViewAccountBalanceWidgetQuery(\n  $accountBalancesWidgetInput: GetAccountBalancesInput!\n  $accountBalancesWidgetWhere: AccountBalancesModelFilterInput\n) {\n  ...AccountBalanceWidget_query_wPJAV\n}\n\nfragment AccountBalanceWidget_query_wPJAV on Query {\n  userprofile_viewer {\n    id\n    accounts {\n      accountNickname\n      financialAccountId\n    }\n    accountGroups {\n      id\n      name\n      accounts {\n        id\n        financialAccountId\n      }\n    }\n  }\n  clientsummary_viewer {\n    getAccountBalances(input: $accountBalancesWidgetInput, where: $accountBalancesWidgetWhere) {\n      accountDefaultName\n      accountId\n      accountType\n      cashBalance\n      dailyChange\n      isLiability\n      marketValue\n      managementStyle\n      totalBalance\n      unrealizedGl\n      unrealizedGlPercent\n      valuationId\n    }\n    financialAccounts {\n      id\n      accountName\n      clientIdentifier\n      accountNumber\n      registrationName\n      managementStyle\n      accountType\n      accountTypeAbbreviation\n      custodian\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "67607a7897cf46e804d6c3b9967e6cd1";

export default node;
