import {
  Box,
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import {
  donutChartColorScheme,
  getPrimaryAndActiveColors,
} from 'src/assets/chartColorSchemes';
import AssetAllocationDonutChart from 'src/components/charts/AssetAllocationDonutChart';
import Widget from 'src/components/widget/Widget';
import { AssetAllocationMenu } from './AssetAllocationMenu';
import { AssetClassAllocationWidget_data$key } from './__generated__/AssetClassAllocationWidget_data.graphql';
import { LegendData } from 'src/components/charts/CustomLegend';

export const AssetClassAllocationWidgetQuery = graphql`
  fragment AssetClassAllocationWidget_data on ClientSummaryViewer
  @argumentDefinitions(input: { type: "GetAssetAllocationInput!" }) {
    getAssetAllocationByAssetClass(input: $input) {
      assetClassName
      marketValue
      percentOfAccounts
    }
    id
  }
`;

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

interface AssetDataType {
  assetClassName: string;
  marketValue: number;
  percentOfAccounts: number;
}

interface AssetClassAllocationChartDataType {
  name: string;
  value: number;
  percentOfTotal: number;
}

const ASSET_CATEGORIES = [
  'Cash & Cash Equivalents',
  'Fixed Income',
  'US Equity',
  'International Equity',
  'Alternatives',
  'Real Assets',
  'Other',
];

const generateChartData = (assetData: AssetDataType[] | undefined = []) => {
  const chartData: AssetClassAllocationChartDataType[] = [];
  ASSET_CATEGORIES.forEach((category) => {
    chartData.push({
      name: category,
      value: 0,
      percentOfTotal: 0,
    });
  });

  assetData?.forEach((entry) => {
    let keyValue = entry?.assetClassName;
    if (keyValue === 'Cash and Cash Equivalents') {
      keyValue = 'Cash & Cash Equivalents';
    }
    let idx = chartData.findIndex((value) => value.name === keyValue);
    if (idx === -1) {
      return;
    }
    chartData[idx].value = chartData[idx].value + entry.marketValue;
    chartData[idx].percentOfTotal =
      chartData[idx].percentOfTotal + entry.percentOfAccounts;
  });
  return chartData;
};

const StyledInnerItem = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
}));

type AssetClassAllocationWidgetProps = {
  data: AssetClassAllocationWidget_data$key;
};

function AssetClassAllocationWidget({ data }: AssetClassAllocationWidgetProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const assetData = useFragment(AssetClassAllocationWidgetQuery, data);

  const chartData = generateChartData(
    assetData?.getAssetAllocationByAssetClass as AssetDataType[]
  );

  const legendData: LegendData[] = ASSET_CATEGORIES.map((category) => {
    let shade = false;
    const dataEntry = chartData.find((o) => o.name === category);
    if (dataEntry?.value === 0) {
      shade = true;
    }
    return {
      label: category,
      shade,
    };
  });

  const totalAssets = chartData.reduce((acc, curr) => {
    acc += curr.value;
    return acc;
  }, 0);
  const newScheme = getPrimaryAndActiveColors(donutChartColorScheme);

  const renderInnerElement = (): JSX.Element => {
    return assetData?.getAssetAllocationByAssetClass !== undefined ? (
      <>
        <StyledInnerItem>
          <Typography variant="body2">
            {formatter.format(totalAssets)}
          </Typography>
        </StyledInnerItem>
        <StyledInnerItem>
          <Typography variant="body1">Current Value</Typography>
        </StyledInnerItem>
      </>
    ) : (
      <></>
    );
  };

  const assetAllocationTile = (
    <Container
      sx={(theme) => ({
        width: 600,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          width: '330px',
        },
        [theme.breakpoints.up('md')]: {
          width: '500px',
        },
      })}
    >
      {!isMobile && <Box sx={{ marginTop: '25px' }}></Box>}
      <AssetAllocationDonutChart<AssetClassAllocationChartDataType>
        data={chartData}
        legendWidth={250}
        height={265}
        {...(isMobile && { width: 150 })}
        legendData={legendData}
        colorScheme={newScheme}
        inner={renderInnerElement()}
        totalValue={totalAssets}
      />
    </Container>
  );

  return (
    <Widget
      title="Asset Allocation"
      detailsMenu={!isMobile ? <AssetAllocationMenu /> : null}
      widgets={[
        {
          element: assetAllocationTile,
        },
      ]}
      paperProps={{ sx: { height: '100%' } }}
    />
  );
}

export default AssetClassAllocationWidget;
