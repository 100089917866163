import { Grid } from '@mui/material';

import AccountListItem from './AccountListItem';
import {
  ClientPortalAccountGroup,
  ClientPortalFinancialAccount,
} from '@newedge/common';

interface AccountListProps {
  accountGroup: ClientPortalAccountGroup;
  onDeleteAccountFromAccountGroup: (
    account: ClientPortalFinancialAccount,
    accountGroup: ClientPortalAccountGroup
  ) => void;
}

export const AccountList = ({
  accountGroup,
  onDeleteAccountFromAccountGroup,
}: AccountListProps) => {
  return (
    <Grid
      container
      sx={(theme) => ({ width: '100%', margin: theme.spacing(0, 3) })}
    >
      {accountGroup.accounts.map((account, index) => {
        if (account) {
          return (
            <AccountListItem
              key={account.id}
              account={account}
              accountGroup={accountGroup}
              onDelete={onDeleteAccountFromAccountGroup}
              index={index}
            />
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default AccountList;
