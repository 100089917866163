import {
  alpha,
  ButtonProps,
  createTheme,
  darken,
  lighten,
  Theme,
} from '@mui/material';
import {
  GreyPercentOptions,
  PaletteOptions,
  ThemeOptions,
} from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import chroma from 'chroma-js';
import WebFont from 'webfontloader';
import { ColorScheme } from './colorScheme';
import { macgColors } from './macgColors';

WebFont.load({
  google: {
    families: ['Source Sans Pro:400,700'],
  },
  typekit: {
    id: 'cgl1qko', // Meno Banner
  },
});

export const styleConstants = {
  semiBoldFontWeight: 600,
};

export const newEdgeNamedColors = {
  zeus: '#1A1613',
  indigo: '#193A5E',
  lightIndigo: '#B1CDEA',
  persian: '#003CC9',
  lightPersian: '#D8E4FF',
  astral: '#3289AE',
  lightAstral: '#90C7DF',
  marigold: '#F1A020',
  lightMarigold: '#F8D091',
  saffron: '#FECE2F',
  lightSaffron: '#FEE79A',

  // Wealth specific
  camel: '#CDAC83',

  // Capital Partners specific
  calypso: '#2D799A',
  nile: '#254566',

  // Capital Group specific
  cadet: '#9BA2AA',
  lightCadet: '#F0F0F0',
  iron: '#DBDCDC',

  // General
  white: '#FFFFFF',
  alabaster: '#fafafa',
  black: '#000',
  eerieBlack: '#1A1A1A',
  astralComplimentary: '#31C16B',
  astralDark: darken('#3289AE', 0.75),
  ambassador: '#0D3055',
};

export const grey: GreyPercentOptions = {
  4: '#0A0A0A',
  8: '#141414',
  10: '#1A1A1A',
  12: '#1F1F1F',
  14: '#242424',
  16: '#292929',
  20: '#333333',
  24: '#3D3D3D',
  26: '#424242',
  28: '#474747',
  32: '#525252',
  36: '#5C5C5C',
  40: '#666666',
  42: '#6B6B6B',
  50: '#7F7F7F',
  60: '#999999',
};

export const getChromaColorScheme = (
  numberOfColors: number,
  referenceColors: string[]
) =>
  chroma
    .scale(referenceColors)
    .mode('lch')
    .correctLightness()
    .colors(numberOfColors)
    .map((x) => ({ primary: x, active: lighten(x, 0.25) }));

export const chartColorsScheme: ColorScheme = [
  {
    primary: newEdgeNamedColors.indigo,
    active: newEdgeNamedColors.lightIndigo,
  },
  {
    primary: newEdgeNamedColors.persian,
    active: newEdgeNamedColors.lightPersian,
  },
  {
    primary: newEdgeNamedColors.astral,
    active: newEdgeNamedColors.lightAstral,
  },
  {
    primary: newEdgeNamedColors.indigo,
    active: newEdgeNamedColors.lightIndigo,
  },
  {
    primary: newEdgeNamedColors.marigold,
    active: newEdgeNamedColors.lightMarigold,
  },
  {
    primary: newEdgeNamedColors.saffron,
    active: newEdgeNamedColors.lightSaffron,
  },
  {
    primary: newEdgeNamedColors.calypso,
    active: alpha(newEdgeNamedColors.lightIndigo, 0.5),
  },
];

const paletteLightModeOverride: PaletteOptions = {
  type: 'light',
  text: {
    primary: newEdgeNamedColors.zeus,
    secondary: alpha(newEdgeNamedColors.zeus, 0.54),
    disabled: alpha(newEdgeNamedColors.zeus, 0.38),
  },
  primary: {
    main: newEdgeNamedColors.zeus,
  },
  secondary: {
    main: newEdgeNamedColors.iron,
  },
  background: {
    paper: newEdgeNamedColors.white,
    default: newEdgeNamedColors.alabaster,
  },
  greyPercent: grey,
  transparentBackground: {
    main: alpha(newEdgeNamedColors.white, 0.65),
  },
  action: {
    active: newEdgeNamedColors.zeus,
    hover: alpha(newEdgeNamedColors.zeus, 0.08),
    selected: alpha(newEdgeNamedColors.zeus, 0.16),
    disabled: alpha(newEdgeNamedColors.zeus, 0.3),
    disabledBackground: alpha(newEdgeNamedColors.zeus, 0.12),
  },
  divider: newEdgeNamedColors.cadet,
};

const paletteDarkModeOverride: PaletteOptions = {
  type: 'dark',
  text: {
    primary: newEdgeNamedColors.white,
    secondary: alpha(newEdgeNamedColors.white, 0.7),
    disabled: alpha(newEdgeNamedColors.white, 0.5),
  },
  // TODO: What should our primary, and secondary colors really be?
  primary: {
    main: '#0A42C6',
    light: '#1C60FF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#5C5C5C',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#EE2E3D',
    contrastText: '#FFFFFF',
  },
  iron: {
    main: newEdgeNamedColors.iron,
  },
  background: {
    paper: grey[10],
    default: newEdgeNamedColors.black,
  },
  greyPercent: grey,
  transparentBackground: {
    main: alpha(newEdgeNamedColors.eerieBlack, 0.65),
  },
  action: {
    active: newEdgeNamedColors.white,
    hover: alpha(newEdgeNamedColors.white, 0.08),
    selected: alpha(newEdgeNamedColors.white, 0.16),
    disabled: alpha(newEdgeNamedColors.white, 0.3),
    disabledBackground: alpha(newEdgeNamedColors.white, 0.12),
  },
  divider: newEdgeNamedColors.lightCadet,
};

const typographyOverride: TypographyOptions = {
  fontFamily: '"Source Sans Pro", "Arial", sans-serif',
  h1: {
    fontSize: '4.8rem',
  },
  h2: {
    fontSize: '3.8rem',
  },
  h3: {
    fontSize: '2.4rem',
  },
  h4: {
    fontSize: '1.8rem',
  },
  subhead: {
    fontSize: '1.8rem',
    color: newEdgeNamedColors.ambassador,
  },
  body1: {
    fontSize: '1.6rem',
  },
  body2: {
    fontSize: '3rem',
  },
  body3: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: newEdgeNamedColors.persian,
  },
  body4: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: newEdgeNamedColors.ambassador,
  },
  body5: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  link: {
    fontSize: '1.4rem',
    color: newEdgeNamedColors.lightCadet,
  },
  special: {
    fontFamily: '"meno-banner-extra-condensed", "Arial", sans-serif',
    fontSize: '4rem',
  },
  special2: {
    fontFamily: '"meno-banner-extra-condensed", "Arial", sans-serif',
    fontSize: '4rem',
    color: newEdgeNamedColors.ambassador,
  },
  disclosure: {
    fontSize: '1rem',
    color: newEdgeNamedColors.cadet,
  },
  button: {
    textTransform: 'none',
    lineHeight: '1.4rem',
  },
  caption: {
    fontSize: '1.4rem',
  },
  toutCaption: {
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
  },
  toutEyebrow: {
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
  },
  toutHeadline: {
    fontSize: '6.4rem',
    lineHeight: '6.4rem',
  },
  filterHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  tableHeaderRow: {
    backgroundColor: 'rgba(255, 255, 255, 0.20)',
  },
  tableHeaderText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1.6rem',
    textAlign: 'center',
  },
};

const getComponentStyleOverrides = () => {
  return {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: newEdgeNamedColors.black,
          color: newEdgeNamedColors.white,
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained' as const },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({ margin: theme.spacing(1) }),
        sizeSmall: ({ theme }: { theme: Theme }) => ({
          fontSize: '1rem',
          lineHeight: '1rem',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        }),
        sizeMedium: ({ theme }: { theme: Theme }) => ({
          fontSize: '1.4rem',
          lineHeight: '1.4rem',
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }),
        sizeLarge: ({ theme }: { theme: Theme }) => ({
          fontSize: '1.6rem',
          lineHeight: '1.6rem',
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }),
        containedPrimary: ({ theme }: { theme: Theme }) => ({
          background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          color: theme.palette.primary.contrastText,
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: newEdgeNamedColors.iron,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'unset',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          backgroundColor: '#202020',
          fontSize: '2.0rem',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          '&.Mui-selected': {
            color: theme.palette.text.primary,
            backgroundColor: theme.extensions.grey[26],
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: { fontFamily: 'Source Sans Pro' },
      },
    },
  };
};

export const createThemeOptions = (useDarkMode: boolean): ThemeOptions => ({
  palette: useDarkMode ? paletteDarkModeOverride : paletteLightModeOverride,
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  typography: typographyOverride,
  components: getComponentStyleOverrides(),
  extensions: {
    toolbarHeight: 124,
    color: {
      ...macgColors,
    },
    font: {
      infographicHeading: {
        fontSize: '1.8rem',
      },
      infographicLabel: {
        fontFamily: '"Source Sans Pro", "Arial", sans-serif',
        fontSize: '1.4rem',
      },
      infographicTickLabelSm: {
        fontFamily: '"Helvetica","Arial", sans-serif',
        fontSize: '1.4rem',
      },
      infographicTickLabelLg: {
        fontFamily: '"Helvetica","Arial", sans-serif',
        fontSize: '2.1rem',
      },
      infographicSelectionHeadingSm: {
        fontFamily: '"Arial", "Helvetica",sans-serif',
        fontSize: '1.4rem',
      },
      infographicSelectionHeadingLg: {
        fontFamily: '"Arial", "Helvetica",sans-serif',
        fontSize: '2.1rem',
      },
      tooltip: {
        fontFamily: '"Source Sans Pro", "Arial", sans-serif',
        fontSize: '2.4rem',
      },
      graphAxis: {
        fontFamily: '"Source Sans Pro", "Arial", sans-serif',
        fontSize: '2.4rem',
        fontWeight: 'normal',
      },
    },
    filter: {
      12: 'rgba(255, 255, 255, 0.12)',
      20: 'rgba(255, 255, 255, 0.20)',
    },
    grey: {
      ...grey,
    },
    borders: {
      light: `1px solid ${newEdgeNamedColors.white}`,
      dark: `1px solid #000000`,
      grey28: `2px solid ${grey[28]}`,
    },
    mobileReportTable: {
      borderColor: '#6C6C6C',
      stripe: '#333333',
      label: '#888888',
    },
  },
  clientPortalExtensions: {
    drawerWidth: 280,
  },
});

export const themeWithMobileTypographyOptions = (theme: Theme) => {
  theme.typography = {
    ...theme.typography,
    special: {
      ...theme.typography.special,
      [theme.breakpoints.down('md')]: {
        fontSize: '2.6rem',
      },
    },
    h3: {
      ...theme.typography.h3,
      [theme.breakpoints.down('md')]: {
        fontSize: '2.4rem',
      },
    },
    h4: {
      ...theme.typography.h4,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.8rem',
      },
    },
    body1: {
      ...theme.typography.body1,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.6rem',
      },
    },
    body2: {
      ...theme.typography.body2,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
    },
    body3: {
      ...theme.typography.body3,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
  };
  return theme;
};

const clientPortalTheme = (prefersDarkMode: boolean) => {
  const themeOptions = createThemeOptions(prefersDarkMode);
  const theme = createTheme(themeOptions);
  return themeWithMobileTypographyOptions(theme);
};

export default clientPortalTheme;
