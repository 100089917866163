import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(1),
}));

interface CreateAccountGroupInputProps {
  label: string;
  value: string;
  hasErrors: boolean;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateAccountGroup: (value: string) => void;
}

export const CreateAccountGroupInput = ({
  label,
  value,
  hasErrors,
  onCreateAccountGroup,
  onValueChange,
}: CreateAccountGroupInputProps) => {
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onCreateAccountGroup(value);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <TextField
        error={!!hasErrors}
        id="add-account-group"
        label={label}
        value={value}
        onChange={onValueChange}
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            width: '25%',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'gray',
            },
          },
        })}
        variant="outlined"
        color="iron"
      />
      <Button onClick={() => onCreateAccountGroup(value)} disabled={hasErrors}>
        Add
      </Button>
    </StyledForm>
  );
};

export default CreateAccountGroupInput;
