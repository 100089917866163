import { Divider, Grid, Slide, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { useCallback, useReducer } from 'react';
import { useFragment, useMutation } from 'react-relay';
import AccordionItem from 'src/components/accordion/AccordionItem';
import { convertGuidToRelayId } from 'src/libs/relay/helpers/cache-helpers';
import { v4 as uuid } from 'uuid';
import AccountGroupList from './view/AccountGroupList';
import CreateAccountGroupInput from './CreateAccountGroupInput';
import reducer, {
  ActionType,
  setInitialState,
} from './ManageAccountGroupReducer';
import { ManageAccountGroupView_clientsummary_viewer$key } from './__generated__/ManageAccountGroupView_clientsummary_viewer.graphql';
import { ManageAccountGroupView_userprofile_viewer$key } from './__generated__/ManageAccountGroupView_userprofile_viewer.graphql';
import { ManageAccountGroupView_CreateAccountGroupMutation } from './__generated__/ManageAccountGroupView_CreateAccountGroupMutation.graphql';
import { ManageAccountGroupView_RemoveAccountsFromAccountGroupMutation } from './__generated__/ManageAccountGroupView_RemoveAccountsFromAccountGroupMutation.graphql';
import { ManageAccountGroupView_UpdateAccountGroupNameMutation } from './__generated__/ManageAccountGroupView_UpdateAccountGroupNameMutation.graphql';
import AddAccountsToAccountGroup from './edit/AddAccountsToAccountGroup';
import { ManageAccountGroupView_DeactivateAccountGroupMutation } from './__generated__/ManageAccountGroupView_DeactivateAccountGroupMutation.graphql';
import { combineUserProfileAndClientSummaryFinancialAccountInfo } from 'src/models/ClientPortalFinancialAccount';
import { FinancialAccountInfo } from 'src/@types/global';
import {
  ClientPortalAccountGroup,
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
  UserProfileFinancialAccount,
  UserProfileFinancialAccountNickname,
  validateAccountGroupName,
} from '@newedge/common';

export const ManageAccountGroupsUserProfileQuery = graphql`
  fragment ManageAccountGroupView_userprofile_viewer on UserProfileViewer {
    id
    accounts {
      accountNickname
      financialAccountId
    }
    accountGroups {
      id
      name
      accounts {
        id
        financialAccountId
      }
    }
  }
`;

export const ManageAccountGroupsClientSummaryQuery = graphql`
  fragment ManageAccountGroupView_clientsummary_viewer on ClientSummaryViewer {
    financialAccounts {
      id
      accountName
      clientIdentifier
      accountNumber
      registrationName
      managementStyle
      accountType
      accountTypeAbbreviation
      custodian
    }
  }
`;

const CreateAccountGroupMutation = graphql`
  mutation ManageAccountGroupView_CreateAccountGroupMutation(
    $input: CreateAccountGroupInput!
  ) {
    CreateAccountGroup(input: $input) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

const DeactivateAccountGroupMutation = graphql`
  mutation ManageAccountGroupView_DeactivateAccountGroupMutation(
    $input: DeleteAccountGroupInput!
  ) {
    DeactivateAccountGroup(input: $input) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

const RemoveAccountsFromAccountGroupMutation = graphql`
  mutation ManageAccountGroupView_RemoveAccountsFromAccountGroupMutation(
    $input: AccountGroupAccountChangesInput!
  ) {
    RemoveAccountsFromAccountGroup(input: $input) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

const UpdateAccountGroupNameMutation = graphql`
  mutation ManageAccountGroupView_UpdateAccountGroupNameMutation(
    $input: AccountGroupNameChangeInput!
  ) {
    UpdateAccountGroupName(input: $input) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

export interface ManageAccountGroupState {
  selectedAccountGroup: UserProfileAccountGroup | null;
  createEditLabel: string;
  accountGroupNames: string[];
  textFieldValue: string;
  hasCreateErrors: boolean;
  errorTextValue: string;
  showAccountGroups: boolean;
  alreadySelectedAccounts: ClientPortalFinancialAccount[];
  addedAccounts: ClientPortalFinancialAccount[];
}

interface ManageAccountGroupViewProps {
  userProfileFragmentQueryRef: ManageAccountGroupView_userprofile_viewer$key | null;
  clientSummaryFragmentQueryRef: ManageAccountGroupView_clientsummary_viewer$key | null;
}

export const ManageAccountGroupView = ({
  userProfileFragmentQueryRef,
  clientSummaryFragmentQueryRef,
}: ManageAccountGroupViewProps) => {
  const userProfileData = useFragment(
    ManageAccountGroupsUserProfileQuery,
    userProfileFragmentQueryRef
  );

  const clientSummaryData = useFragment(
    ManageAccountGroupsClientSummaryQuery,
    clientSummaryFragmentQueryRef
  );

  const accountsWithNicknames =
    combineUserProfileAndClientSummaryFinancialAccountInfo(
      userProfileData?.accounts as UserProfileFinancialAccountNickname[],
      clientSummaryData?.financialAccounts as FinancialAccountInfo[]
    );

  const [state, dispatch] = useReducer(
    reducer,
    {
      accountGroups:
        userProfileData?.accountGroups as UserProfileAccountGroup[],
      accounts: accountsWithNicknames as ClientPortalFinancialAccount[],
    },
    setInitialState
  );

  const {
    selectedAccountGroup,
    createEditLabel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    accountGroupNames, // Used to ensure added account groups must be unique in reducer.
    textFieldValue,
    hasCreateErrors,
    errorTextValue,
    showAccountGroups,
    alreadySelectedAccounts,
    addedAccounts,
  }: ManageAccountGroupState = state;

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    dispatch({
      type: ActionType.TEXT_FIELD_VALUE_CHANGE,
      payload: { value },
    });
  };

  const handleAccountGroupAddAccounts = (
    accountGroup: ClientPortalAccountGroup
  ) => {
    dispatch({
      type: ActionType.TOGGLE_SHOW_ACCOUNT_GROUPS,
      payload: {
        selectedAccountGroup: accountGroup,
        textFieldValue: '',
        addedAccounts: [],
      },
    });
  };

  const handleClickAccountGroup = (accountGroup: ClientPortalAccountGroup) => {
    dispatch({
      type: ActionType.ACCOUNT_GROUP_SELECTED,
      payload: { accountGroup },
    });
  };

  const [commitDeactivateAccountGroup] =
    useMutation<ManageAccountGroupView_DeactivateAccountGroupMutation>(
      DeactivateAccountGroupMutation
    );

  const deactivateAccountGroup = useCallback(
    async (id: string) => {
      commitDeactivateAccountGroup({
        variables: {
          input: {
            id,
          },
        },
        updater: (store) => {
          // For some reason, there is not a connection between the userprofile_viewer.accountGroups and the store's AccountGroups.
          const root = store.getRoot();
          const userProfileViewer = root.getLinkedRecord('userprofile_viewer');
          const accountGroups =
            userProfileViewer?.getLinkedRecords('accountGroups');
          const removedAccountGroup = store.get(id);
          if (accountGroups !== null && accountGroups !== undefined) {
            if (userProfileViewer !== null && userProfileViewer !== undefined) {
              const updatedAccountGroups = accountGroups.filter(
                (accountGroup) =>
                  accountGroup.getDataID() !== removedAccountGroup!.getDataID()
              );
              userProfileViewer.setLinkedRecords(
                updatedAccountGroups,
                'accountGroups'
              );
              store.delete(removedAccountGroup!.getDataID());
            }
          }
        },
      });
    },
    [commitDeactivateAccountGroup]
  );

  const handleDeleteAccountGroup = async (
    accountGroup: ClientPortalAccountGroup
  ) => {
    // Deselect the Account Group.
    dispatch({
      type: ActionType.ACCOUNT_GROUP_SELECTED,
      payload: { accountGroup: null },
    });
    await deactivateAccountGroup(accountGroup.id);
    dispatch({
      type: ActionType.REMOVED_ACCOUNT_GROUP,
      payload: { name: accountGroup.name, accounts: accountGroup.accounts },
    });
  };

  const [commitUpdateAccountGroupName] =
    useMutation<ManageAccountGroupView_UpdateAccountGroupNameMutation>(
      UpdateAccountGroupNameMutation
    );
  const updateAccountGroupName = useCallback(
    async (id: string, newName: string) => {
      commitUpdateAccountGroupName({
        variables: {
          input: {
            id,
            newName,
          },
        },
      });
    },
    [commitUpdateAccountGroupName]
  );

  const [commitCreateAccountGroupMutation] =
    useMutation<ManageAccountGroupView_CreateAccountGroupMutation>(
      CreateAccountGroupMutation
    );
  const createAccountGroup = useCallback(
    async (id: string, name: string) => {
      commitCreateAccountGroupMutation({
        variables: {
          input: {
            id,
            name,
          },
        },
        updater: (store) => {
          // For some reason, there is not a connection between the userprofile_viewer.accountGroups and the store's AccountGroups.
          const root = store.getRoot();
          const userProfileViewer = root.getLinkedRecord('userprofile_viewer');
          const accountGroups =
            userProfileViewer?.getLinkedRecords('accountGroups');
          const addedAccountGroup = store.get(
            convertGuidToRelayId('AccountGroup', id)
          );
          if (accountGroups !== null && accountGroups !== undefined) {
            if (userProfileViewer !== null && userProfileViewer !== undefined) {
              userProfileViewer.setLinkedRecords(
                [...accountGroups!, addedAccountGroup!],
                'accountGroups'
              );
            }
          }
        },
      });
    },
    [commitCreateAccountGroupMutation]
  );

  const handleCreateAccountGroup = async (newName: string) => {
    if (newName === '') {
      dispatch({
        type: ActionType.ERROR,
        payload: {
          errorTextValue: 'Please enter in an account group name.',
          hasCreateErrors: true,
        },
      });
      return;
    }

    await createAccountGroup(uuid(), newName);
    dispatch({
      type: ActionType.ADDED_ACCOUNT_GROUP,
      payload: { newName },
    });
  };

  const handleAccountGroupEditModeClickAway = useCallback(() => {
    dispatch({
      type: ActionType.ERROR,
      payload: {
        errorTextValue: '',
      },
    });
  }, []);

  const handleEditAccountGroupNameInputValueChange = (
    value: string,
    currentAccountGroupName: string
  ) => {
    if (value) {
      const errorMessage = validateAccountGroupName(
        value,
        accountGroupNames,
        currentAccountGroupName
      );

      dispatch({
        type: ActionType.ERROR,
        payload: {
          errorTextValue: errorMessage ?? '',
        },
      });
    }
  };

  const handleUpdateAccountGroupName = async (
    accountGroup: ClientPortalAccountGroup,
    newName: string
  ): Promise<boolean> => {
    const errorMessage = validateAccountGroupName(
      newName,
      accountGroupNames,
      accountGroup.name
    );

    if (errorMessage) {
      dispatch({
        type: ActionType.ERROR,
        payload: {
          errorTextValue: errorMessage,
        },
      });
      return false;
    }

    await updateAccountGroupName(accountGroup.id, newName);
    dispatch({
      type: ActionType.UPDATED_ACCOUNT_GROUP,
      payload: { newName, selectedAccountGroup },
    });
    return true;
  };

  const [commitRemoveAccountsFromAccountGroup] =
    useMutation<ManageAccountGroupView_RemoveAccountsFromAccountGroupMutation>(
      RemoveAccountsFromAccountGroupMutation
    );

  const removeAccountsFromAccountGroup = useCallback(
    async (id: string, accounts: UserProfileFinancialAccount[]) => {
      if (accounts) {
        commitRemoveAccountsFromAccountGroup({
          variables: {
            input: {
              id,
              accounts,
            },
          },
        });
      }
    },
    [commitRemoveAccountsFromAccountGroup]
  );

  const handleDeleteAccountFromAccountGroup = async (
    account: ClientPortalFinancialAccount,
    accountGroup: ClientPortalAccountGroup
  ) => {
    const result: ClientPortalFinancialAccount[] =
      alreadySelectedAccounts!.filter(
        (currentAccount: ClientPortalFinancialAccount) =>
          currentAccount.financialAccountId !== account.financialAccountId
      );
    dispatch({
      type: ActionType.ACCOUNTS_CONFIRMED,
      payload: {
        alreadySelectedAccounts: result,
      },
    });
    const accountEntryInGroup = account.accountGroupEntry?.accounts.find(
      (o) => o.financialAccountId === account.financialAccountId
    );
    if (accountEntryInGroup) {
      await removeAccountsFromAccountGroup(accountGroup.id, [
        accountEntryInGroup,
      ]);
    }
  };

  const getClientPortalAccountGroups = (): ClientPortalAccountGroup[] => {
    if (userProfileData === null || userProfileData === undefined) {
      return [] as ClientPortalAccountGroup[];
    }
    return userProfileData?.accountGroups.map((accountGroup) => {
      return {
        id: accountGroup.id,
        name: accountGroup.name,
        accounts: accountGroup.accounts.map((account) => {
          const foundAccount = accountsWithNicknames.find(
            (cpAccount) =>
              cpAccount.financialAccountId === account.financialAccountId
          );
          if (foundAccount) {
            foundAccount.accountGroupEntry =
              accountGroup as UserProfileAccountGroup;
          }
          return foundAccount;
        }) as ClientPortalFinancialAccount[],
      };
    });
  };

  return (
    <Grid item>
      <AccordionItem
        summaryComponent={
          <Typography variant="h4">Manage Account Groups</Typography>
        }
        detailsComponent={
          <Grid item xs={12} sx={{ overflow: 'hidden' }}>
            <Slide
              direction="right"
              in={showAccountGroups}
              unmountOnExit={true}
            >
              <Grid
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                container
              >
                <Grid item xs={12}>
                  <Typography sx={{ marginBottom: '2rem' }}>
                    Create a new Account Group and add unassigned accounts.
                    <br />
                    <br />
                    Use the buttons on the Account Group records to edit them.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    sx={({ palette }) => ({
                      borderColor: palette.greyPercent[50],
                    })}
                  />
                </Grid>

                <Grid
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  container
                >
                  <Grid
                    item
                    rowSpacing={0}
                    xs={12}
                    sx={({ spacing }) => ({ marginBottom: spacing(3) })}
                  >
                    <Grid item xs={12}>
                      <CreateAccountGroupInput
                        label={createEditLabel}
                        value={textFieldValue}
                        hasErrors={hasCreateErrors}
                        onCreateAccountGroup={handleCreateAccountGroup}
                        onValueChange={handleTextFieldChange}
                      />
                    </Grid>

                    {errorTextValue && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{ color: '#f44336', paddingLeft: '2rem' }}
                        >
                          {errorTextValue}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <AccountGroupList
                      accountGroups={getClientPortalAccountGroups()}
                      selectedAccountGroup={selectedAccountGroup}
                      onClickAccountGroup={handleClickAccountGroup}
                      onAccountGroupAddAccounts={handleAccountGroupAddAccounts}
                      onDeleteAccountGroup={handleDeleteAccountGroup}
                      onDeleteAccountFromAccountGroup={
                        handleDeleteAccountFromAccountGroup
                      }
                      onUpdateAccountGroupName={handleUpdateAccountGroupName}
                      onEditAccountGroupNameInputValueChange={
                        handleEditAccountGroupNameInputValueChange
                      }
                      onEditModeClickAway={handleAccountGroupEditModeClickAway}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
            <Slide
              direction="left"
              in={!showAccountGroups}
              unmountOnExit={true}
            >
              <AddAccountsToAccountGroup
                allAccounts={accountsWithNicknames}
                alreadySelectedAccounts={alreadySelectedAccounts}
                dispatch={dispatch}
                addedAccounts={addedAccounts}
                selectedAccountGroup={selectedAccountGroup}
              />
            </Slide>
          </Grid>
        }
      />
    </Grid>
  );
};

export default ManageAccountGroupView;
