import {
  ColumnDataType,
  DataAlignment,
} from 'src/components/sortable-table/@types';
import {
  AccountBalanceViewModel,
  AccountGroupBalanceViewModel,
} from '@newedge/reports';
import { CompactNestedTable, CompactNestedTableColumn } from '@newedge/common';

interface AccountBalanceTableProps {
  data: AccountGroupBalanceViewModel[];
}

export const AccountBalanceTable = ({ data }: AccountBalanceTableProps) => {
  const columns: CompactNestedTableColumn<
    AccountGroupBalanceViewModel,
    AccountBalanceViewModel
  >[] = [
    {
      id: 'AccountGroupName',
      nestedId: 'Nickname',
      nestedAccessor: (rowData) => rowData.Accounts,
      label: 'Name',
      dataType: ColumnDataType.String,
      maxLength: 72,
    },
    {
      id: 'TotalBalance',
      nestedId: 'TotalBalance',
      nestedAccessor: (rowData) => rowData.Accounts,
      label: 'Total Balance',
      dataType: ColumnDataType.Currency,
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'CashBalance',
      nestedId: 'CashBalance',
      nestedAccessor: (rowData) => rowData.Accounts,
      label: 'Cash & Cash Equivalents',
      dataType: ColumnDataType.Currency,
      dataAlignment: DataAlignment.Right,
      width: '35%',
    },
  ];
  data.forEach((accountGroup) => {
    accountGroup.Accounts.sort((a, b) => b.TotalBalance - a.TotalBalance);
    return accountGroup;
  });
  let groupedAccounts = data
    .filter((item) => item.AccountGroupId !== '0')
    .sort((a, b) => b.TotalBalance - a.TotalBalance);
  const ungroupedAccount = data.find((item) => item.AccountGroupId === '0');
  if (ungroupedAccount) {
    groupedAccounts.push(ungroupedAccount);
  }

  return <CompactNestedTable columns={columns} data={groupedAccounts} />;
};

export default AccountBalanceTable;
