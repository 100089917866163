import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import ClientPortalLogo from 'src/components/branding/ClientPortalLogo';
import UserAccountMenu from './UserAccountMenu';
import MenuIcon from '@mui/icons-material/Menu';

const PREFIX = 'TopBar';

const classes = {
  appBarContent: `${PREFIX}-appBarContent`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  mobileLogoContainer: `${PREFIX}-mobileLogoContainer`,
  mobileIconContainer: `${PREFIX}-mobileIconContainer`,
  grow: `${PREFIX}-grow`,
};

const StyledAppBar = styled(AppBar)(({ theme: { breakpoints, spacing } }) => ({
  [`& .${classes.appBarContent}`]: {
    padding: spacing(2),
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: spacing(5, 2, 1, 2),
      margin: 'auto',
    },
  },

  [`& .${classes.sectionDesktop}`]: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  [`& .${classes.mobileLogoContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.mobileIconContainer}`]: {
    position: 'absolute',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
}));

type TopBarProps = {
  userDisplayName?: string | null;
  toggleDrawerOpen: (toggleDrawer: boolean) => void;
};
// TODO: userDisplayName should probably be in some global state object (redux, context)
export function TopBar({ userDisplayName, toggleDrawerOpen }: TopBarProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <StyledAppBar position={isMobile ? 'fixed' : 'relative'}>
      <Toolbar className={classes.appBarContent}>
        <div className={classes.mobileIconContainer}>
          <MenuIcon
            onClick={() => toggleDrawerOpen(true)}
            style={{
              color: '#0A42C6',
              fontSize: '4rem',
            }}
          />
        </div>
        <div className={classes.mobileLogoContainer}>
          <ClientPortalLogo />
        </div>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <UserAccountMenu userDisplayName={userDisplayName} />
        </div>
      </Toolbar>
    </StyledAppBar>
  );
}

export default TopBar;
