import { ManageAccountGroupState } from './ManageAccountGroupView';
import {
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
  UserProfileFinancialAccount,
  validateAccountGroupName,
} from '@newedge/common';

export enum ActionType {
  ACCOUNT_GROUP_SELECTED = 'account_group_selected',
  TEXT_FIELD_VALUE_CHANGE = 'text_field_value_change',
  TOGGLE_SHOW_ACCOUNT_GROUPS = 'toggle_show_account_groups',
  ACCOUNTS_SELECTED = 'accounts_selected',
  ACCOUNTS_CONFIRMED = 'accounts_confirmed',
  ADDED_ACCOUNT_GROUP = 'added_account_group',
  UPDATED_ACCOUNT_GROUP = 'updated_account_group',
  REMOVED_ACCOUNT_GROUP = 'removed_account_group',
  ERROR = 'error',
}

type Action = { type: ActionType; payload?: any };

function toggleGroupSelected(
  currentAccountGroup: UserProfileAccountGroup | null,
  selectedAccountGroup: UserProfileAccountGroup | null
) {
  if (selectedAccountGroup === null) {
    return {
      selectedAccountGroup: null,
      createEditLabel: 'Create Account Group',
      textFieldValue: '',
      selectedAccountGroupName: '',
      errorTextValue: '',
    };
  }
  // Clicking the same account group resets the selected account group.
  if (currentAccountGroup?.id === selectedAccountGroup?.id) {
    return {
      selectedAccountGroup: null,
      createEditLabel: 'Create Account Group',
      textFieldValue: '',
      selectedAccountGroupName: '',
      errorTextValue: '',
    };
  }
  // Select the Account
  return {
    selectedAccountGroup,
    selectedAccountGroupName: selectedAccountGroup.name,
    errorTextValue: '',
  };
}

export default function reducer(
  state: ManageAccountGroupState,
  action: Action
) {
  switch (action.type) {
    case ActionType.ACCOUNT_GROUP_SELECTED: {
      const result = toggleGroupSelected(
        state.selectedAccountGroup,
        action.payload.accountGroup
      );
      return {
        ...state,
        ...result,
      };
    }
    case ActionType.TEXT_FIELD_VALUE_CHANGE: {
      const { value } = action.payload;

      const errorMessage = validateAccountGroupName(
        value,
        state.accountGroupNames
      );

      if (errorMessage) {
        return {
          ...state,
          errorTextValue: errorMessage,
          textFieldValue: action.payload.value,
          hasCreateErrors: true,
        };
      }

      return {
        ...state,
        errorTextValue: '',
        hasCreateErrors: false,
        textFieldValue: action.payload.value,
      };
    }
    case ActionType.TOGGLE_SHOW_ACCOUNT_GROUPS: {
      return {
        ...state,
        showAccountGroups: !state.showAccountGroups,
        ...action.payload,
      };
    }
    case ActionType.ACCOUNTS_SELECTED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.ADDED_ACCOUNT_GROUP: {
      const { newName } = action.payload;

      return {
        ...state,
        textFieldValue: '',
        accountGroupNames: [...state.accountGroupNames, newName],
      };
    }
    case ActionType.UPDATED_ACCOUNT_GROUP: {
      const { newName, selectedAccountGroup } = action.payload;
      const accountGroupNames = state.accountGroupNames.filter(
        (name) => name !== selectedAccountGroup.name
      );
      return {
        ...state,
        selectedAccountGroup: { ...state.selectedAccountGroup, name: newName },
        accountGroupNames: [...accountGroupNames, newName],
      };
    }
    case ActionType.REMOVED_ACCOUNT_GROUP: {
      const {
        accounts,
        name,
      }: { accounts: UserProfileFinancialAccount[]; name: string } =
        action.payload;
      // Ensure the accounts under the removed account group are available to be selected for new account groups.
      const removedAccountIds = accounts.map((account) =>
        account ? account.financialAccountId : undefined
      );
      const newAlreadySelectedAccounts = state.alreadySelectedAccounts.filter(
        (account) => !removedAccountIds.includes(account.financialAccountId)
      );
      return {
        ...state,
        accountGroupNames: state.accountGroupNames.filter(
          (accountGroupName) => accountGroupName !== name
        ),
        alreadySelectedAccounts: newAlreadySelectedAccounts,
      };
    }
    case ActionType.ACCOUNTS_CONFIRMED: {
      return {
        ...state,
        alreadySelectedAccounts: action.payload.alreadySelectedAccounts,
      };
    }
    case ActionType.ERROR: {
      return {
        ...state,
        hasCreateErrors: action.payload.hasCreateErrors,
        errorTextValue: action.payload.errorTextValue,
      };
    }
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
}

interface InitialStateArgs {
  accountGroups: UserProfileAccountGroup[];
  accounts: ClientPortalFinancialAccount[];
}

export function setInitialState({
  accountGroups,
  accounts,
}: InitialStateArgs): ManageAccountGroupState {
  const accountGroupNames = accountGroups.map(
    (accountGroup) => accountGroup.name
  );

  const alreadySelectedAccounts: ClientPortalFinancialAccount[] = [];
  accountGroups.forEach((accountGroup) => {
    accountGroup.accounts.forEach((account: UserProfileFinancialAccount) => {
      const clientSummaryAccount = accounts.find(
        (o) => o.financialAccountId === account.financialAccountId
      );
      if (clientSummaryAccount) {
        alreadySelectedAccounts.push(clientSummaryAccount);
      }
    });
  });

  const initialState: ManageAccountGroupState = {
    selectedAccountGroup: null,
    createEditLabel: 'Create Account Group',
    accountGroupNames,
    textFieldValue: '',
    hasCreateErrors: false,
    errorTextValue: '',
    showAccountGroups: true,
    alreadySelectedAccounts,
    addedAccounts: [],
  };
  return initialState;
}
