import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import graphql from 'babel-plugin-relay/macro';
import { useState } from 'react';
import { useFragment } from 'react-relay';
import { FinancialAccountInfo } from 'src/@types/global';
import AccordionItem from 'src/components/accordion/AccordionItem';
import getAccountDisplayName from 'src/libs/utils/getAccountDisplayName';
import { combineUserProfileAndClientSummaryFinancialAccountInfo } from 'src/models/ClientPortalFinancialAccount';
import { ManageNicknameTableRow } from './ManageNicknameTableRow';
import { ManageNicknames_clientsummary_data$key } from './__generated__/ManageNicknames_clientsummary_data.graphql';
import { ManageNicknames_userprofile_data$key } from './__generated__/ManageNicknames_userprofile_data.graphql';
import { UserProfileFinancialAccountNickname } from '@newedge/common';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.secondary.main,
  fontWeight: 'bold',
  border: 'unset',
  ...theme.typography.h4,
}));

export const ManageAliasesClientSummaryDataQuery = graphql`
  fragment ManageNicknames_clientsummary_data on ClientSummaryViewer {
    financialAccounts {
      id
      accountName
      accountNumber
      registrationName
      managementStyle
      accountType
      custodian
    }
  }
`;
export const ManageAliasesUserProfileDataQuery = graphql`
  fragment ManageNicknames_userprofile_data on UserProfileViewer {
    id
    accounts {
      accountNickname
      financialAccountId
    }
    accountGroups {
      id
      name
      accounts {
        id
        financialAccountId
      }
    }
  }
`;

type ManageAliasesProps = {
  clientSummaryFragmentQueryRef: ManageNicknames_clientsummary_data$key | null;
  userProfileFragmentQueryRef: ManageNicknames_userprofile_data$key | null;
};

export const ManageNicknames = ({
  clientSummaryFragmentQueryRef,
  userProfileFragmentQueryRef,
}: ManageAliasesProps) => {
  const clientSummaryData = useFragment(
    ManageAliasesClientSummaryDataQuery,
    clientSummaryFragmentQueryRef
  );

  const userProfileData = useFragment(
    ManageAliasesUserProfileDataQuery,
    userProfileFragmentQueryRef
  );

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    userProfileData?.accounts as UserProfileFinancialAccountNickname[],
    clientSummaryData?.financialAccounts as FinancialAccountInfo[]
  );

  const rowsPerPage = 30;
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    pageNumber: number
  ) => {
    setPage(pageNumber);
  };

  // Don't include characters from the encoded ID value
  const keysToIgnore = [
    'id',
    'financialAccountId',
    'userProfileData',
    'accountName',
  ];
  const financialAccountRows =
    accounts !== undefined
      ? accounts
          .filter((financialAccount) => {
            //if searchValue is blank ignore
            if (searchValue && searchValue !== '') {
              if (
                getAccountDisplayName(financialAccount)
                  .toLowerCase()
                  ?.indexOf(searchValue.toLowerCase()) > -1
              ) {
                return true;
              }

              const searchAccountAccountGroup =
                userProfileData?.accountGroups.find((accountGroup) =>
                  accountGroup.accounts.some(
                    (accountGroupAccount) =>
                      accountGroupAccount.financialAccountId ===
                      financialAccount.financialAccountId
                  )
                );
              if (
                searchAccountAccountGroup &&
                searchAccountAccountGroup.name
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
              ) {
                return true;
              }

              //Convert all data props to values to search thru
              return Object.entries(financialAccount).some(
                (value: any) =>
                  !keysToIgnore.includes(value[0]) &&
                  typeof value[1] !== 'object' &&
                  value[1]?.toLowerCase()?.indexOf(searchValue.toLowerCase()) >
                    -1
              );
            }

            return true;
          })
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  const headers = [
    { name: 'Account Name', align: 'left' },
    { name: 'Account Number', align: 'left' },
    { name: 'Registration Name', align: 'left' },
    { name: 'Management Style', align: 'left' },
    { name: 'Account Type', align: 'left' },
    { name: 'Custodian', align: 'left' },
    { name: 'Account Group', align: 'left' },
  ];

  return (
    <Grid item>
      <AccordionItem
        summaryComponent={
          <Typography variant="h4">Manage Account Names</Typography>
        }
        detailsComponent={
          <Grid
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '2rem' }}>
                Click any existing Account Name to update the name. Use the
                search box to narrow results.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Grid direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={12}>
                  <TableContainer
                    component={Paper}
                    sx={(theme) => ({
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.background.paper,
                      borderColor: theme.palette.secondary.main,
                    })}
                  >
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        {clientSummaryData &&
                          clientSummaryData.financialAccounts && (
                            <TableRow>
                              <StyledTableCell colSpan={4}>
                                <TextField
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: 'gray',
                                      },
                                    },
                                  }}
                                  style={{ width: '75%' }}
                                  type="search"
                                  variant="outlined"
                                  size="medium"
                                  onChange={handleSearch}
                                  value={searchValue}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  color="iron"
                                />
                                <Button onClick={() => setSearchValue('')}>
                                  See All
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell colSpan={8}>
                                <TablePagination
                                  sx={(theme) => ({
                                    '& .MuiTablePagination-displayedRows': {
                                      ...theme.typography.link,
                                    },
                                  })}
                                  component="div"
                                  count={
                                    clientSummaryData.financialAccounts
                                      ? clientSummaryData.financialAccounts
                                          .length
                                      : 0
                                  }
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handlePageChange}
                                  rowsPerPageOptions={[]}
                                />
                              </StyledTableCell>
                            </TableRow>
                          )}
                        <TableRow>
                          {headers.map(({ name, align }) => {
                            return (
                              <StyledTableCell
                                key={name}
                                align={align as TableCellProps['align']}
                              >
                                {name}
                              </StyledTableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {financialAccountRows.map((financialAccount, index) => (
                          <ManageNicknameTableRow
                            key={index}
                            financialAccount={financialAccount}
                            accountGroups={
                              userProfileData && userProfileData.accountGroups
                                ? (userProfileData as any).accountGroups.filter(
                                    (accountGroup: any) => {
                                      return accountGroup.accounts.some(
                                        (account: any) =>
                                          account.financialAccountId ===
                                          financialAccount.financialAccountId
                                      );
                                    }
                                  )
                                : []
                            }
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default ManageNicknames;
