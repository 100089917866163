import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { FilterItem } from 'src/components/table-filter/@types';
import { CashFlowDetailQueryReaderQuery } from './__generated__/CashFlowDetailQueryReaderQuery.graphql';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import { FinancialAccountInfo } from 'src/@types/global';
import { useContext } from 'react';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import {
  CashFlowDetailView,
  CashFlowDetailProjectionItem,
} from '@newedge/reports';
import { UserProfileFinancialAccountNickname } from '@newedge/common';

export const CashFlowDetailQueryReaderNode = graphql`
  query CashFlowDetailQueryReaderQuery(
    $cashFlowDetailInput: GetCashFlowDetailProjectionInput!
    $cashFlowDetailFilterInput: CashFlowDetailModelFilterInput!
  ) {
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      id
      getCashFlowDetailProjection(
        input: $cashFlowDetailInput
        where: $cashFlowDetailFilterInput
      ) {
        cashFlowDetailId
        accountId
        taxStatusName
        isFederalTaxable
        isStateTaxable
        incomeTypeName
        incomeEstimateDate
        incomeEstimateAmount
        asOfDate
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
    }
  }
`;

interface CashFlowDetailQueryReaderProps {
  queryRef: PreloadedQuery<CashFlowDetailQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
}

export const CashFlowDetailQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  setAsOfDate,
}: CashFlowDetailQueryReaderProps) => {
  const data = usePreloadedQuery(CashFlowDetailQueryReaderNode, queryRef);

  const { selectedAccounts } = useContext(GlobalFilterContext);

  const date =
    data.clientsummary_viewer.getCashFlowDetailProjection?.[0]?.asOfDate;
  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    data.userprofile_viewer?.accounts as UserProfileFinancialAccountNickname[],
    data.clientsummary_viewer?.financialAccounts as FinancialAccountInfo[]
  );
  const cashFlowDetailProjection = data.clientsummary_viewer
    .getCashFlowDetailProjection as CashFlowDetailProjectionItem[];

  return (
    <CashFlowDetailView
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      setAsOfDate={setAsOfDate}
      date={date}
      accounts={accounts}
      selectedAccounts={selectedAccounts}
      cashFlowDetailProjection={cashFlowDetailProjection}
    />
  );
};
