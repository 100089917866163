import { useMemo } from 'react';
import { YAxisProps } from 'recharts';
import { getChartTicks } from './chartUtils';
import useTickFormatter from './useTickFormatter';

/**
 * Calculates the YAxis domain and tick properties based on the data.
 * @param data The chart data used to calculate the axis values.  If multiple series, each ChartDataShape must include 1 series
 * @param keys An options list of keys, if not provided all keys in the data object will be used when calculating min and max domain.
 * @param abbreviationMin A cutoff for tick formatting. If provided all values less than this will use the raw number.
 */
export function useYAxis(
  domain: [number, number],
  abbreviationMin?: number
): YAxisProps {
  const ticks = useMemo(() => {
    return getChartTicks(domain);
  }, [domain]);
  const tickFormatter = useTickFormatter(abbreviationMin);
  return { domain, ticks, tickFormatter };
}

export default useYAxis;
