/**
 * @generated SignedSource<<9d7a3f5cc8e17343b29d8c474976dc39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetClassAllocationWidget_data$data = {
  readonly getAssetAllocationByAssetClass: ReadonlyArray<{
    readonly assetClassName: string;
    readonly marketValue: any;
    readonly percentOfAccounts: any;
  }>;
  readonly id: string;
  readonly " $fragmentType": "AssetClassAllocationWidget_data";
};
export type AssetClassAllocationWidget_data$key = {
  readonly " $data"?: AssetClassAllocationWidget_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetClassAllocationWidget_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetClassAllocationWidget_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "concreteType": "AssetAllocationByAssetClassModel",
      "kind": "LinkedField",
      "name": "getAssetAllocationByAssetClass",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assetClassName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "marketValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentOfAccounts",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ClientSummaryViewer",
  "abstractKey": null
};

(node as any).hash = "a07e4b3ace58f077c8b4b0780b466da1";

export default node;
