import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import ConfirmActions from './ConfirmActions';
import DeleteIcon from '@mui/icons-material/Delete';

interface ConfirmDeleteDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  message: string;
  submit: () => void;
}

export const ConfirmDeleteDialog = ({
  open,
  setOpen,
  message,
  submit,
}: ConfirmDeleteDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-delete"
      PaperProps={{
        sx: (theme) => ({ backgroundColor: theme.palette.greyPercent[42] }),
      }}
    >
      <DialogContent
        sx={{
          maxWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DeleteIcon sx={{ fontSize: 40 }} />
        <DialogContentText sx={{ textAlign: 'center', color: '#FFFFFF' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <ConfirmActions
        handleCancel={() => {
          setOpen(false);
        }}
        handleSubmit={() => {
          submit();
          setOpen(false);
        }}
        cancelButton={{
          text: 'Cancel',
          color: 'secondary',
          sx: (theme) => ({
            backgroundColor: theme.palette.greyPercent[60],
            color: '#000000',
          }),
        }}
        submitButton={{
          text: 'Delete',
          color: 'warning',
        }}
      />
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
