/**
 * @generated SignedSource<<225977f204fbcc2bfb8309c421b9a39b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageAccountGroupView_userprofile_viewer$data = {
  readonly accountGroups: ReadonlyArray<{
    readonly accounts: ReadonlyArray<{
      readonly financialAccountId: number;
      readonly id: any;
    }>;
    readonly id: string;
    readonly name: string;
  }>;
  readonly accounts: ReadonlyArray<{
    readonly accountNickname: string;
    readonly financialAccountId: number;
  }>;
  readonly id: string;
  readonly " $fragmentType": "ManageAccountGroupView_userprofile_viewer";
};
export type ManageAccountGroupView_userprofile_viewer$key = {
  readonly " $data"?: ManageAccountGroupView_userprofile_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageAccountGroupView_userprofile_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageAccountGroupView_userprofile_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNickname",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroup",
      "kind": "LinkedField",
      "name": "accountGroups",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserProfileViewer",
  "abstractKey": null
};
})();

(node as any).hash = "23231fa50f87d38067f1671b65271c2b";

export default node;
