import { SvgIcon, SvgIconProps } from '@mui/material';

const HoldingsDetailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 492.94 495.12">
      <path
        d="M431.63,252.28h0a11.79,11.79,0,0,0-11.79,11.79v13.46a47.05,47.05,0,0,0,11.79,92.62,23.58,23.58,0,1,1-23.57,23.58,11.79,11.79,0,1,0-23.58,0,47.13,47.13,0,0,0,35.36,45.47v13.46a11.79,11.79,0,0,0,11.79,11.79h0a11.78,11.78,0,0,0,11.79-11.79V439.2a47.05,47.05,0,0,0-11.79-92.62A23.58,23.58,0,1,1,455.2,323a11.79,11.79,0,1,0,23.58,0,47.13,47.13,0,0,0-35.36-45.47V264.07h0a11.78,11.78,0,0,0-11.79-11.78Z"
        transform="translate(-42.91 -29.42)"
      />
      <path
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          strokeWidth: '7px',
        }}
        d="M518,453.29"
        transform="translate(-42.91 -29.42)"
      />
      <path
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        d="M289.93,46.21c128.36,0,232.41,104.06,232.41,232.42"
        transform="translate(-42.91 -29.42)"
      />
      <path
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        d="M350,503.2a232.64,232.64,0,0,1-60.1,7.84C161.57,511,57.52,407,57.52,278.63c0-2.81,0-5.6.14-8.39a232,232,0,0,1,8.63-55.07"
        transform="translate(-42.91 -29.42)"
      />
      <line
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        x1="74.31"
        y1="404.73"
        x2="410.13"
        y2="83.64"
      />
      <line
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        x1="14.76"
        y1="240.82"
        x2="237.43"
        y2="244.82"
      />
      <line
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        x1="247.02"
        y1="481.62"
        x2="247.3"
        y2="242.82"
      />
      <line
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        x1="27.87"
        y1="326.77"
        x2="113.2"
        y2="246.62"
      />
      <line
        style={{
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          fill: 'none',
          strokeWidth: '27px',
        }}
        color={props.htmlColor}
        x1="140.99"
        y1="457.42"
        x2="242.22"
        y2="362.33"
      />
      <path
        d="M113.45,153.31l18.6,0,0-2.46v-69a12,12,0,0,1,12-12h22.15a12,12,0,0,1,12,12v71.34l17.39,0h0V41.42a12,12,0,0,1,12-12H229.7a12,12,0,0,1,12,12V151.75l0,1.62h7.88a12,12,0,0,1,12,12v4.43a12,12,0,0,1-12,12H54.91a12,12,0,0,1-12-12v-4.43a12,12,0,0,1,12-12l12.37,0V120.91a12,12,0,0,1,12-12h22.15a12,12,0,0,1,12,12v32.4Z"
        transform="translate(-42.91 -29.42)"
      />
    </SvgIcon>
  );
};

export { HoldingsDetailIcon };
