import { DateTime } from 'luxon';

export interface VariablesObject {
  cashFlowDashboardInput: {
    startDate: string;
    endDate: string;
  };
  cashFlowDetailInput: {
    startDate: string;
    endDate: string;
  };
}

export const getCashFlowInput = () => {
  const dt = DateTime.now();
  const startDate = dt.startOf('month').plus({ month: 1 });
  const endDate = dt.plus({ year: 1 });

  return {
    startDate: startDate.toFormat('yyyy-MM-dd'),
    endDate: endDate.toFormat('yyyy-MM-dd'),
    financialAccountIds: [1],
  };
};

export const getCashFlowDetailInput = () => {
  const dt = DateTime.now();
  const startDate = dt.startOf('month').plus({ month: 1 });
  const endDate = dt.plus({ year: 1 });
  return {
    startDate: startDate.toFormat('yyyy-MM-dd'),
    endDate: endDate.toFormat('yyyy-MM-dd'),
    financialAccountIds: [1],
  };
};

export const getQueryVariables = () => {
  const variables = {} as VariablesObject;
  variables.cashFlowDashboardInput = getCashFlowInput();
  variables.cashFlowDetailInput = getCashFlowDetailInput();
  return variables;
};
