import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import NewEdgeLogoTextSvg from './NewEdgeLogoTextSvg';

const PREFIX = 'ClientPortalLogo';

const classes = {
  root: `${PREFIX}-root`,
  logoContainer: `${PREFIX}-logoContainer`,
  logo: `${PREFIX}-logo`,
};

const logoWidth = 236;
const logoHeight = 86;

const Root = styled('div')(
  ({
    theme: {
      breakpoints,
      extensions: { toolbarHeight },
      palette,
      spacing,
    },
  }) => ({
    [`&.${classes.root}`]: {
      display: 'flex',
      flexDirection: 'column',
    },

    [`& .${classes.logoContainer}`]: {
      height: toolbarHeight,
      flex: 1,
      paddingBottom: spacing(0.5),
      display: 'inline-flex',
      '& .small': {},
    },

    [`& .${classes.logo}`]: {
      width: logoWidth,
      height: logoHeight,
      fill: palette.text.primary,
      objectFit: 'cover',
      [breakpoints.down('md')]: {
        width: logoWidth / 2,
        height: logoHeight / 2,
      },
    },
  })
);

function ClientPortalLogo() {
  const navigate = useNavigate();
  return (
    <Root onClick={() => navigate('/my/dashboard')} className={classes.root}>
      <div className={classes.logoContainer}>
        <NewEdgeLogoTextSvg
          className={classes.logo}
          preserveAspectRatio={'xMinYMin slice'}
        />
      </div>
    </Root>
  );
}

export default ClientPortalLogo;
