import { Skeleton } from '@mui/lab';
import { Suspense, useContext, useEffect, useState } from 'react';
import { PreloadedQuery, useQueryLoader } from 'react-relay';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import { FilterItem } from 'src/components/table-filter/@types';
import { LandingPageViewQuery } from '../__generated__/LandingPageViewQuery.graphql';
import {
  HoldingsDetailQueryReaderQuery,
  HoldingsDetailQueryReaderQuery$variables,
} from './__generated__/HoldingsDetailQueryReaderQuery.graphql';
import ReportWrapper from '../ReportWrapper';
import {
  HoldingsDetailQueryReader,
  HoldingsDetailQueryReaderNode,
} from './HoldingsDetailQueryReader';
import { useLocation } from 'react-router';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';

const applySecondaryFilters = (
  variables: HoldingsDetailQueryReaderQuery$variables,
  filterValues: FilterItem[]
): HoldingsDetailQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);

  let input = newVariables.holdingsDetailInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.holdingsDetailInput = {
      financialAccountIds: [...ids],
    };
  }

  let where = newVariables.holdingsDetailFilterInput;
  const nonAccountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier !== 'accountId'
  );
  if (nonAccountIdFilters.length > 0) {
    const and: any = [];
    const or: any = [];
    nonAccountIdFilters.forEach((o) => {
      if (o.predicateIdentifier) {
        switch (o.logic) {
          case 'or':
            or.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
            break;
          case 'and':
          case undefined:
          default:
            and.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
        }
      }
    });
    where = {
      or,
      and,
    };
    if (where.and?.length === 0) {
      delete where.and;
    }
    if (where.or?.length === 0) {
      delete where.or;
    }
    newVariables.holdingsDetailFilterInput = where;
  }

  return newVariables;
};

const getInitialFilterItems = (state: FilterItem[]): FilterItem[] => {
  if (!state) {
    return [];
  }
  return state;
};

interface HoldingsDetailLoaderProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}

export const HoldingsDetailLoader = ({
  landingPageQueryRef,
}: HoldingsDetailLoaderProps) => {
  const { holdingsDetailQueryVariables } = useContext(QueryVariablesContext);
  const [holdingsDetailQueryRef, loadHoldingsDetailQuery] =
    useQueryLoader<HoldingsDetailQueryReaderQuery>(
      HoldingsDetailQueryReaderNode
    );

  const { setSelectedAccounts, allAccounts } = useContext(GlobalFilterContext);
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.accountFilter) {
      setSelectedAccounts([state.accountFilter]);
      return () => {
        setSelectedAccounts(allAccounts);
      };
    }
  }, [allAccounts, setSelectedAccounts, state]);

  const initialFilterItems: FilterItem[] = state
    ? getInitialFilterItems(state.secondaryFilters as FilterItem[])
    : [];

  const [filterValues, setFilterValues] =
    useState<FilterItem[]>(initialFilterItems);
  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  useEffect(() => {
    loadHoldingsDetailQuery(
      applySecondaryFilters(holdingsDetailQueryVariables, filterValues)
    );
  }, [filterValues, holdingsDetailQueryVariables, loadHoldingsDetailQuery]);

  return holdingsDetailQueryRef != null ? (
    <ReportWrapper
      title="Holdings"
      asOfDate={asOfDate}
      landingPageQueryRef={landingPageQueryRef}
    >
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <HoldingsDetailQueryReader
          queryRef={holdingsDetailQueryRef}
          filterValues={filterValues}
          setAsOfDate={setAsOfDate}
          setFilterValues={setFilterValues}
          hasInitialFilters={
            initialFilterItems && initialFilterItems.length > 0
          }
        />
      </Suspense>
    </ReportWrapper>
  ) : null;
};

export default HoldingsDetailLoader;
