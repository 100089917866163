import { Button, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState } from 'react';
import { useNavigate } from 'react-router';

interface AssetAllocationMenuProps {
  navigationState?: Object;
}

function AssetAllocationMenu({ navigationState }: AssetAllocationMenuProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ marginLeft: 'auto', color: '#FFFFFF' }}
        variant="text"
      >
        <MoreHorizIcon fontSize="large" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/my/asset-allocation', {
              state: navigationState ?? {
                selectedTab: 'by-asset-class',
                setFirstRowOpen: true,
              },
            });
          }}
        >
          View Full Report
        </MenuItem>
      </Menu>
    </>
  );
}

export { AssetAllocationMenu };
export default AssetAllocationMenu;
