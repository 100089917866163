import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { newEdgeNamedColors } from 'src/assets/theme';
import WidgetWrapper from 'src/components/widget/Widget';
import { getPrimaryAndActiveColors } from 'src/assets/chartColorSchemes';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { To, useNavigate } from 'react-router-dom';
import { CashFlowBreakdown, CashFlowDataEntry } from './@types';
import {
  computeChartData,
  computeTotalCashFlowBreakdown as computeCashFlowBreakdownTotals,
} from './cashFlowHelpers';
import { CashFlowMenu } from './CashFlowMenu';
import { CashFlowBreakdownWidget_data$key } from './__generated__/CashFlowBreakdownWidget_data.graphql';
import { RowContent } from 'src/components/cards/@types';
import ToutStack from 'src/components/cards/ToutStack';
import MoreDetailsLink from 'src/components/navigation/MoreDetailsLink';
import abbreviateNumber, {
  NumberFormatType,
  formatNumber,
} from 'src/libs/utils/numberFormatters';
import {
  ChartDataShape,
  CustomTick,
  GenericChartMeta,
} from 'src/components/charts/@types';
import useYAxis from 'src/components/charts/useYAxis';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import CustomLegend from 'src/components/charts/CustomLegend';
import { Info } from 'luxon';

export const CashFlowBreakdownWidgetQuery = graphql`
  fragment CashFlowBreakdownWidget_data on ClientSummaryViewer
  @argumentDefinitions(
    input: { type: "GetCashFlowDetailProjectionInput!" }
    where: { type: "CashFlowDetailModelFilterInput!" }
  ) {
    widgetData: getCashFlowDetailProjection(input: $input, where: $where) {
      cashFlowDetailId
      accountId
      taxStatusName
      isFederalTaxable
      isStateTaxable
      incomeTypeName
      incomeEstimateDate
      incomeEstimateAmount
      asOfDate
    }
    id
  }
`;

// order here correlates to bar render order from bottom to top
export const CashFlowTypes = ['Tax Exempt', 'Tax Deferred', 'Taxable'] as const;

// prettier-ignore
export type CashFlowCategory = typeof CashFlowTypes[number];

export type CashFlowBreakdownDataShape = Record<
  CashFlowCategory,
  number | null
>;
export type CashFlowBreakdownChartDataShape = ChartDataShape<
  CashFlowBreakdownDataShape,
  GenericChartMeta
>;

// TODO: define types for the props that are implicity passed to this element

interface CustomizedAxisTickProps {
  x?: string | number;
  y?: string | number;
  stroke?: string;
  payload?: CustomTick;
}
const CustomizedAxisTick = ({
  x,
  y,
  stroke,
  payload,
}: CustomizedAxisTickProps) => {
  const month = payload?.value.split(' ')[0];
  const year = payload?.value.split(' ')[1];
  return (
    <text
      x={x}
      y={Number(y) + 8}
      stroke={stroke}
      fill="#F0F0F0"
      fontSize="1.2rem"
      textAnchor="middle"
    >
      <tspan
        x={x}
        dy="0.2em"
        style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {month + ' '}
      </tspan>
      <tspan>{year}</tspan>
    </text>
  );
};

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<any, any>): JSX.Element | null => {
  const payloadValue = payload && payload[0];
  const completePayload = payloadValue?.payload;

  const cashflowValues: JSX.Element[] = [];
  if (completePayload) {
    CashFlowTypes.forEach((type) => {
      cashflowValues.unshift(
        <Typography variant="h3">
          {type}: {abbreviateNumber(Number(completePayload.data[type]), false)}
        </Typography>
      );
    });
  }

  return (
    <>
      {active && completePayload && (
        <Box
          sx={(theme) => ({
            backgroundColor: 'rgba(80, 80, 80)',
            '& a': {
              color: newEdgeNamedColors.white,
              textDecoration: 'underline',
            },
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
          })}
        >
          {cashflowValues}
          <Typography variant="link">Click to See More Detail »</Typography>
        </Box>
      )}
    </>
  );
};

type CashFlowBreakdownWidgetProps = {
  data: CashFlowBreakdownWidget_data$key;
};

function CashFlowBreakdownWidget({ data }: CashFlowBreakdownWidgetProps) {
  const cashFlowData = useFragment(CashFlowBreakdownWidgetQuery, data);

  const colorScheme = getPrimaryAndActiveColors([
    newEdgeNamedColors.persian,
    newEdgeNamedColors.astral,
    newEdgeNamedColors.marigold,
  ]);
  const navigate = useNavigate();
  const chartData: CashFlowDataEntry[] = computeChartData(cashFlowData);

  const cashFlowBreakDownTotals: CashFlowBreakdown =
    computeCashFlowBreakdownTotals(chartData);

  let maxDomainValue = 0;
  chartData.forEach((o) => {
    const sum = Object.values(o.data).reduce((a, b) => a + b);
    if (sum > maxDomainValue) {
      maxDomainValue = sum;
    }
  });

  const yAxisProps = useYAxis([0, maxDomainValue]);

  const legendSeries = CashFlowTypes.map((type) => {
    let shade = true;
    chartData.forEach((item) => {
      if (item.data[type] > 0) {
        shade = false;
      }
    });
    return {
      label: type,
      shade,
    };
  });

  const chart = useMemo(() => {
    const navigateTo: To = '/my/cash-flow';

    const onClickHandler = () => {
      navigate(navigateTo);
    };

    return (
      <Box
        sx={{
          height: '400px',
          width: '100%',
          display: 'flex',
          '& .recharts-surface .recharts-cartesian-grid .recharts-cartesian-grid-horizontal line:last-child':
            {
              strokeOpacity: 0,
            },
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={400}
            height={400}
            data={chartData}
            margin={{
              top: 24,
              right: 0,
              left: 0,
              bottom: 24,
            }}
            barSize={16}
            barGap={4}
            barCategoryGap={'10%'}
            onClick={onClickHandler}
          >
            <XAxis
              tick={<CustomizedAxisTick />}
              tickLine={false}
              dataKey={(d) => {
                return Info.months('short')[d.key - 1] + ' ' + d.metadata.year;
              }}
            />
            <XAxis
              xAxisId="1"
              dataKey={(d) => d.metadata.total}
              label={{
                value: 'Totals',
                position: 'left',
                fill: newEdgeNamedColors.lightCadet,
                fontSize: '1.2rem',
              }}
              tick={{
                fontSize: '1.2rem',
                fill: newEdgeNamedColors.lightCadet,
              }}
              tickFormatter={(value) =>
                formatNumber(value, NumberFormatType.Currency, 0, 0)
              }
              tickLine={false}
              axisLine={false}
              dy={2}
            />
            <YAxis
              {...yAxisProps}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: '1.4rem', fill: newEdgeNamedColors.lightCadet }}
            />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{ zIndex: 1500 }}
              cursor={{
                fill: 'rgba(61, 61, 61, 0.65)',
                cursor: 'pointer',
                pointerEvents: 'fill',
              }}
            />
            <Bar
              dataKey="data['Tax Exempt']"
              stackId="a"
              fill={newEdgeNamedColors.persian}
              cursor={'pointer'}
            />
            <Bar
              dataKey="data['Tax Deferred']"
              stackId="a"
              fill={newEdgeNamedColors.astral}
              cursor={'pointer'}
            />
            <Bar
              dataKey="data['Taxable']"
              stackId="a"
              fill={newEdgeNamedColors.marigold}
              cursor={'pointer'}
            />
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              content={
                <CustomLegend
                  colorScheme={colorScheme}
                  series={legendSeries}
                  reverse={true}
                />
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }, [chartData, colorScheme, legendSeries, navigate, yAxisProps]);

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const mobileRowContent: RowContent[] = Object.entries(
    cashFlowBreakDownTotals
  ).map(([key, value]) => {
    const result: RowContent = {
      label: key,
      value: value === 0 ? 'N/A' : abbreviateNumber(value),
    };
    return result;
  });

  const renderComponents = isMobileOrTablet ? (
    <ToutStack
      title="Expected Cash Flow"
      data={mobileRowContent}
      footer={<MoreDetailsLink destination="/my/cash-flow" />}
    />
  ) : (
    <WidgetWrapper
      title="Expected Cash Flow"
      detailsMenu={<CashFlowMenu />}
      widgets={[
        {
          element: chart,
        },
      ]}
    />
  );

  return renderComponents;
}

export default CashFlowBreakdownWidget;
