import React, { useState } from 'react';

interface ProviderValue {
  checkUnsavedChanges: Function;
  handleSetUnsavedChanges: Function;
}

export const UnsavedChangesContext = React.createContext({} as ProviderValue);

interface UnsavedChangesProviderProps {
  children: React.ReactNode;
}

// context to track unsaved changes for child components
export const UnsavedChangesProvider = ({
  children,
}: UnsavedChangesProviderProps) => {
  const [unsavedChanges, setUnsavedChanges] = useState({});

  // page view should call this function to check if there are pending changes
  const checkUnsavedChanges = () => {
    return Object.values(unsavedChanges).includes(true);
  };

  // child components should call this function with their unique name string
  // boolean value is true when there are pending changes, false otherwise
  const handleSetUnsavedChanges = (name: string, value: boolean) => {
    setUnsavedChanges({ ...unsavedChanges, [name]: value });
  };

  return (
    <UnsavedChangesContext.Provider
      value={{
        checkUnsavedChanges,
        handleSetUnsavedChanges,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};
