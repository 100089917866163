import {
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  LabelProps,
  Label,
} from 'recharts';
import { abbreviateNumber } from 'src/libs/utils/numberFormatters';
import { Box, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { newEdgeNamedColors } from 'src/assets/theme';
import { DonutChartProps } from './@types';
import CustomLegend, { LegendData } from './CustomLegend';
import { dataToCells } from './chartUtils';
import { NavigateOptions, To, useNavigate } from 'react-router';
import { ContentType } from 'recharts/types/component/Label';

const renderLegendSkeletonItem = (width: number) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1.5 }}>
      <Skeleton variant="circular" width={10} height={10} />
      <Skeleton
        variant="rounded"
        animation="wave"
        width={width}
        height={15}
        sx={{ ml: 1 }}
      />
    </Box>
  );
};

const renderLoadingSkeleton = (diameter: number) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Skeleton variant="circular" width={diameter} height={diameter} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {renderLegendSkeletonItem(120)}
        {renderLegendSkeletonItem(140)}
        {renderLegendSkeletonItem(80)}
        {renderLegendSkeletonItem(100)}
        {renderLegendSkeletonItem(80)}
      </Box>
    </Box>
  );
};

interface DonutChartSizingProps {
  pieWidth: number;
  pieHeight: number;
  arcWidth: number;
  arcStroke: number;
}

const StyledInnerContentWrapper = styled('div')<DonutChartSizingProps>(
  ({ pieWidth, pieHeight, arcWidth, arcStroke }) => {
    const width = pieWidth - 2 * (arcWidth + arcStroke);
    const height = pieHeight - 2 * (arcWidth + arcStroke);
    return {
      position: 'relative',
      top: arcWidth + arcStroke,
      left: arcWidth + arcStroke,
      width,
      height,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    };
  }
);

type CustomLabelProps = {
  pieWidth: number;
  pieHeight: number;
  arcWidth: number;
  arcStroke: number;
  inner?: ContentType;
} & LabelProps;

const CustomLabel = (props: CustomLabelProps): JSX.Element => {
  const { pieWidth, pieHeight, arcWidth, arcStroke, inner } = props;
  return (
    <foreignObject pointerEvents={'none'} x="0" y="0" width="50%" height="100%">
      <StyledInnerContentWrapper
        pieWidth={pieWidth}
        pieHeight={pieHeight}
        arcWidth={arcWidth}
        arcStroke={arcStroke}
      >
        {inner instanceof Function ? inner(props) : inner}
      </StyledInnerContentWrapper>
    </foreignObject>
  );
};

type CustomTooltipProps = {} & TooltipProps<any, any>;

const CustomTooltip = ({ payload }: CustomTooltipProps): JSX.Element | null => {
  const payloadValue = payload && payload[0];
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const onTooltipTouchHandler = (e: any) => {
    e.preventDefault();
    const navigateTo: To = '/my/asset-allocation';
    const assetClassName = payloadValue?.name;

    const navigateOptions: NavigateOptions = {
      state: {
        selectedTab: 'by-asset-class',
        category: assetClassName,
      },
    };
    navigate(navigateTo, navigateOptions);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: 'rgba(80, 80, 80)',
          '& a': {
            color: newEdgeNamedColors.white,
            textDecoration: 'underline',
          },
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1),
          // Recharts sets pointerEvents to none by default. For mobile, we want it to be auto.
          pointerEvents: isMobile ? 'auto' : 'none',
        })}
      >
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          {payloadValue?.name}
        </Typography>
        <Typography variant="body2">
          {abbreviateNumber(Number(payloadValue?.value), false)}
        </Typography>
        {payloadValue?.payload?.payload?.percentOfTotal && (
          <Typography variant="body2">
            {abbreviateNumber(
              Number(payloadValue?.payload?.payload?.percentOfTotal),
              true
            )}
          </Typography>
        )}
        <Typography onTouchEnd={(e: any) => onTooltipTouchHandler(e)}>
          Click to See More Detail »
        </Typography>
      </Box>
    </>
  );
};

type Props<TData> = DonutChartProps<TData> & {
  legendData?: LegendData[];
  totalValue: number;
};

export function AssetAllocationDonutChart<TData>(props: Props<TData>) {
  const {
    data,
    inner,
    legend = 'vertical',
    legendData = [],
    width: pieWidth = 224,
    height: pieHeight = 224,
    legendWidth,
    colorScheme,
    totalValue,
  } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const cx = pieWidth / 2 - 5;
  const cy = pieHeight / 2 - 5;

  let width = pieWidth;
  let height = pieHeight;
  const arcStroke = 2;
  const arcWidth = isMobile ? 10 : 18;

  const outerRadius = width / 2 - arcStroke;
  const innerRadius = outerRadius - arcWidth;

  switch (legend) {
    case 'vertical':
      width += legendWidth ?? 150;
      break;
    case 'horizontal':
      height += 60;
      width += legendWidth ?? 250;
      break;
    default:
  }

  const navigate = useNavigate();
  const navigateTo: To = '/my/asset-allocation';

  const onClickHandler = (eventData: any) => {
    if (isMobile) {
      // Show tooltip when clicked on mobile.
      return;
    }
    const assetClassName: string =
      eventData.assetClass ?? eventData.payload.payload.name;

    const navigateOptions: NavigateOptions = {
      state: {
        selectedTab: 'by-asset-class',
        category: assetClassName,
      },
    };
    navigate(navigateTo, navigateOptions);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        margin: 0,
        padding: 0,
        width: '100%',
        height: height,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        {totalValue !== undefined && data.length > 0 ? (
          <PieChart>
            <Pie
              cx={cx}
              cy={cy}
              width={pieWidth}
              data={data}
              dataKey="value"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              strokeWidth={0}
              startAngle={90}
              endAngle={-270}
              onClick={(e) => onClickHandler(e)}
            >
              <Label
                content={
                  <CustomLabel
                    pieWidth={pieWidth}
                    pieHeight={pieHeight}
                    arcStroke={arcStroke}
                    arcWidth={arcWidth}
                    inner={inner}
                  />
                }
              />
              {dataToCells(data, colorScheme)}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              content={
                <CustomLegend colorScheme={colorScheme} series={legendData} />
              }
            />
          </PieChart>
        ) : totalValue === undefined ? (
          renderLoadingSkeleton(pieWidth)
        ) : data.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">No Data</Typography>
          </Box>
        ) : (
          <></>
        )}
      </ResponsiveContainer>
    </Box>
  );
}

export default AssetAllocationDonutChart;
