/**
 * @generated SignedSource<<37dc3f48d872c8a113fa4e466485f5d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetAccountBalancesInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type AccountBalancesModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountType?: StringOperationFilterInput | null;
  and?: ReadonlyArray<AccountBalancesModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  beginningMarketValue?: ComparableDecimalOperationFilterInput | null;
  cashBalance?: ComparableDecimalOperationFilterInput | null;
  dailyChange?: ComparableNullableOfDecimalOperationFilterInput | null;
  dailyChangeEndDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  dailyChangeStartDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  endingMarketValue?: ComparableDecimalOperationFilterInput | null;
  isLiability?: BooleanOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  marketValue?: ComparableDecimalOperationFilterInput | null;
  netCashFlows?: ComparableDecimalOperationFilterInput | null;
  or?: ReadonlyArray<AccountBalancesModelFilterInput> | null;
  totalBalance?: ComparableDecimalOperationFilterInput | null;
  unrealizedGl?: ComparableDecimalOperationFilterInput | null;
  unrealizedGlPercent?: ComparableNullableOfDecimalOperationFilterInput | null;
  valuationId?: ComparableInt32OperationFilterInput | null;
};
export type ComparableInt32OperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  nstartsWith?: string | null;
  or?: ReadonlyArray<StringOperationFilterInput> | null;
  startsWith?: string | null;
};
export type ComparableDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type BooleanOperationFilterInput = {
  eq?: boolean | null;
  neq?: boolean | null;
};
export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type AccountBalancesQueryReaderQuery$variables = {
  accountBalancesFilterInput?: AccountBalancesModelFilterInput | null;
  accountBalancesInput: GetAccountBalancesInput;
};
export type AccountBalancesQueryReaderQuery$data = {
  readonly clientsummary_viewer: {
    readonly financialAccounts: ReadonlyArray<{
      readonly accountName: string;
      readonly accountNumber: string;
      readonly accountType: string;
      readonly accountTypeAbbreviation: string;
      readonly clientIdentifier: any;
      readonly custodian: string;
      readonly id: string;
      readonly managementStyle: string;
      readonly registrationName: string;
    }>;
    readonly getAccountBalances: ReadonlyArray<{
      readonly accountDefaultName: string | null;
      readonly accountId: number;
      readonly accountType: string | null;
      readonly asOfDate: any | null;
      readonly beginningMarketValue: any;
      readonly cashBalance: any;
      readonly dailyChange: any | null;
      readonly endingMarketValue: any;
      readonly isLiability: boolean;
      readonly managementStyle: string | null;
      readonly marketValue: any;
      readonly netCashFlows: any;
      readonly totalBalance: any;
      readonly unrealizedGl: any;
      readonly unrealizedGlPercent: any | null;
      readonly valuationId: number;
    }>;
    readonly id: string;
  };
  readonly userprofile_viewer: {
    readonly accountGroups: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
      readonly id: string;
      readonly name: string;
    }>;
    readonly accounts: ReadonlyArray<{
      readonly accountNickname: string;
      readonly financialAccountId: number;
    }>;
    readonly id: string;
  } | null;
};
export type AccountBalancesQueryReaderQuery = {
  response: AccountBalancesQueryReaderQuery$data;
  variables: AccountBalancesQueryReaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountBalancesFilterInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountBalancesInput"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managementStyle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientSummaryViewer",
    "kind": "LinkedField",
    "name": "clientsummary_viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "accountBalancesInput"
          },
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "accountBalancesFilterInput"
          }
        ],
        "concreteType": "AccountBalancesModel",
        "kind": "LinkedField",
        "name": "getAccountBalances",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountDefaultName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "asOfDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dailyChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLiability",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "marketValue",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unrealizedGl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unrealizedGlPercent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valuationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beginningMarketValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endingMarketValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netCashFlows",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAccount",
        "kind": "LinkedField",
        "name": "financialAccounts",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registrationName",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountTypeAbbreviation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodian",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserProfileViewer",
    "kind": "LinkedField",
    "name": "userprofile_viewer",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccount",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNickname",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "accountGroups",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountBalancesQueryReaderQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountBalancesQueryReaderQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "2b8e59d5cdd98c1a235c70227f94383f",
    "id": null,
    "metadata": {},
    "name": "AccountBalancesQueryReaderQuery",
    "operationKind": "query",
    "text": "query AccountBalancesQueryReaderQuery(\n  $accountBalancesInput: GetAccountBalancesInput!\n  $accountBalancesFilterInput: AccountBalancesModelFilterInput\n) {\n  clientsummary_viewer {\n    getAccountBalances(input: $accountBalancesInput, where: $accountBalancesFilterInput) {\n      accountDefaultName\n      accountId\n      accountType\n      asOfDate\n      cashBalance\n      dailyChange\n      isLiability\n      marketValue\n      managementStyle\n      totalBalance\n      unrealizedGl\n      unrealizedGlPercent\n      valuationId\n      beginningMarketValue\n      endingMarketValue\n      netCashFlows\n    }\n    financialAccounts {\n      id\n      accountName\n      clientIdentifier\n      accountNumber\n      registrationName\n      managementStyle\n      accountType\n      accountTypeAbbreviation\n      custodian\n    }\n    id\n  }\n  userprofile_viewer {\n    id\n    accounts {\n      accountNickname\n      financialAccountId\n    }\n    accountGroups {\n      id\n      name\n      accounts {\n        id\n        financialAccountId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae1e78871bc6039b43e3b5937e57647d";

export default node;
