import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { Skeleton } from '@mui/lab';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import graphql from 'babel-plugin-relay/macro';
import { Suspense, useContext, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import BackgroundPng from 'src/assets/img/bg-img.png';
import { Annotation } from 'src/components/cards/@types';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import { HoldingsDetailIcon } from 'src/components/icons';
import MobileNavigationButton from 'src/components/navigation/MobileNavigationButton';
import { LandingPageViewGqlQuery } from '../LandingPageView';
import { LandingPageViewQuery } from '../__generated__/LandingPageViewQuery.graphql';
import { AccountBalanceWidget } from './AccountBalanceWidget/AccountBalanceWidget';
import { DashboardAnnotations } from './annotations';
import AssetClassAllocationWidget from './AssetClassAllocationWidget/AssetClassAllocationWidget';
import SubAssetClassWidget from './AssetClassAllocationWidget/SubAssetClassWidget';
import CashFlowBreakdownWidget from './CashFlowBreakdownWidget/CashFlowBreakdownWidget';
import { ToutsWidget } from './ToutsWidget/ToutsWidget';
import { DashboardViewAccountBalanceWidgetQuery } from './__generated__/DashboardViewAccountBalanceWidgetQuery.graphql';
import type { DashboardViewQuery } from './__generated__/DashboardViewQuery.graphql';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import { GlobalAccountSelectWrapper } from 'src/components/global-account-select';

export const DashboardViewQueryNode = graphql`
  query DashboardViewQuery(
    $cashFlowDashboardInput: GetCashFlowDetailProjectionInput!
    $cashFlowDashboardFilterInput: CashFlowDetailModelFilterInput!
    $getPortfolioValuationBalancesInput: GetPortfolioValuationBalancesInput!
    $getDailyChangeForAccountsInput: GetDailyChangeForAccountsInput!
    $assetAllocationWidgetInput: GetAssetAllocationInput!
    $subAssetClassWidgetInput: GetTopSubAssetClassesInput!
  ) {
    userprofile_viewer {
      id
      displayName
    }
    clientsummary_viewer {
      ...CashFlowBreakdownWidget_data
        @arguments(
          input: $cashFlowDashboardInput
          where: $cashFlowDashboardFilterInput
        )
      ...ToutsWidget_data
        @arguments(
          portfolioInput: $getPortfolioValuationBalancesInput
          dailyChangeInput: $getDailyChangeForAccountsInput
        )
      ...AssetClassAllocationWidget_data
        @arguments(input: $assetAllocationWidgetInput)
      ...SubAssetClassWidget_data
        @arguments(
          input: $subAssetClassWidgetInput
          input2: $assetAllocationWidgetInput
        )
    }
  }
`;

export const DashboardViewAccountBalanceWidgetQueryNode = graphql`
  query DashboardViewAccountBalanceWidgetQuery(
    $accountBalancesWidgetInput: GetAccountBalancesInput!
    $accountBalancesWidgetWhere: AccountBalancesModelFilterInput
  ) {
    ...AccountBalanceWidget_query
      @arguments(
        input: $accountBalancesWidgetInput
        where: $accountBalancesWidgetWhere
      )
  }
`;

interface FootNotesProps {
  annotations: Annotation[];
}

const FootNotes = ({ annotations }: FootNotesProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '2rem',
      }}
    >
      {annotations.map((annotation, index) => (
        <Typography
          key={`dashboard-view-footnotes-${index}`}
          id={`${annotation.identifier}`}
        >
          {annotation.superscriptText} {annotation.annotationText}
        </Typography>
      ))}
    </Box>
  );
};

const DashboardBackgroundImage = () => {
  return (
    <Box
      component={'img'}
      src={`${BackgroundPng}`}
      alt=""
      sx={{
        position: 'fixed',
        top: '100px',
        left: '50%',
        transform: ' translate(-50%, 0)',
        zIndex: -'1',
        height: '100vh',
      }}
    />
  );
};

type DashboardViewProps = {
  queryRef: PreloadedQuery<DashboardViewQuery>;
};

const DashboardView = ({ queryRef }: DashboardViewProps) => {
  const data = usePreloadedQuery(DashboardViewQueryNode, queryRef);

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        spacing={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <ToutsWidget
          annotations={DashboardAnnotations}
          data={data.clientsummary_viewer}
        />
      </Grid>
      <Grid item container spacing={3} justifyContent="space-between">
        {isMobileOrTablet ? (
          <>
            <Grid item xs={12}>
              <AssetClassAllocationWidget data={data.clientsummary_viewer} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={6}>
              <AssetClassAllocationWidget data={data.clientsummary_viewer} />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <SubAssetClassWidget data={data.clientsummary_viewer} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <CashFlowBreakdownWidget data={data.clientsummary_viewer} />
        </Grid>
      </Grid>
      {!isMobileOrTablet ? null : (
        <>
          <Grid item xs={12}>
            <MobileNavigationButton
              icon={<HoldingsDetailIcon fontSize="large" />}
              title="Holdings Report"
              path="/my/holdings"
            />
          </Grid>
          <Grid item xs={12}>
            <MobileNavigationButton
              icon={<AccessTimeIcon fontSize="large" />}
              title="Account Activity"
              path="/my/account-activity"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FootNotes annotations={DashboardAnnotations} />
      </Grid>
    </Grid>
  );
};

const DashboardHeader = ({
  queryRef,
}: {
  queryRef: PreloadedQuery<LandingPageViewQuery>;
}) => {
  const data = usePreloadedQuery(LandingPageViewGqlQuery, queryRef);

  return (
    <div style={{ padding: '3rem 0' }}>
      <Typography variant="special" data-testid="DashboardProfileName">
        Welcome, {data?.userprofile_viewer?.displayName}!
      </Typography>
    </div>
  );
};

interface DashboardLoaderProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}

export const DashboardLoader = ({
  landingPageQueryRef,
}: DashboardLoaderProps) => {
  const { accountBalanceWidgetQueryVariables, dashboardQueryVariables } =
    useContext(QueryVariablesContext);
  const { selectedAccounts, allAccounts } = useContext(GlobalFilterContext);

  const [dashboardQueryRef, loadDashboardQuery] =
    useQueryLoader<DashboardViewQuery>(DashboardViewQueryNode);
  useEffect(() => {
    if (selectedAccounts.length !== 0) {
      loadDashboardQuery(dashboardQueryVariables);
    }
  }, [dashboardQueryVariables, loadDashboardQuery, selectedAccounts.length]);

  const [accountBalanceQueryRef, loadAccountBalanceQuery] =
    useQueryLoader<DashboardViewAccountBalanceWidgetQuery>(
      DashboardViewAccountBalanceWidgetQueryNode
    );
  useEffect(() => {
    if (allAccounts.length !== 0) {
      loadAccountBalanceQuery(accountBalanceWidgetQueryVariables);
    }
  }, [
    accountBalanceWidgetQueryVariables,
    allAccounts.length,
    loadAccountBalanceQuery,
  ]);

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const render = () => {
    const nodes = [];
    if (landingPageQueryRef != null) {
      nodes.push(
        <DashboardHeader
          queryRef={landingPageQueryRef}
          key="dashboard-header"
        />
      );
    }
    if (accountBalanceQueryRef != null) {
      nodes.push(
        <AccountBalanceWidget
          queryRef={accountBalanceQueryRef}
          key="account-balance-widget"
        />
      );
    }
    if (landingPageQueryRef != null) {
      nodes.push(
        <Grid
          container
          spacing={3}
          key="portfolio-breakdown-global-account-select"
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              mb: 3,
            }}
          >
            <Typography variant="special">Portfolio Overview</Typography>
            <GlobalAccountSelectWrapper
              landingPageQueryRef={landingPageQueryRef}
            />
          </Grid>
        </Grid>
      );
    }
    if (dashboardQueryRef != null) {
      nodes.push(
        <div key="dashboard-view">
          {!isMobileOrTablet && <DashboardBackgroundImage />}
          <Suspense
            fallback={
              <Skeleton variant="rectangular" width="100%" height={200} />
            }
          >
            <DashboardView queryRef={dashboardQueryRef} />
          </Suspense>
        </div>
      );
    }
    return nodes;
  };
  return <>{render()}</>;
};

export default DashboardView;
