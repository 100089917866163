import { Context, createContext, useContext } from 'react';

const getTypedContext = <T>(reactContext: any) =>
  reactContext as unknown as Context<T>;

export const useGenericContext = <T>(reactContext: any) =>
  useContext(getTypedContext<T>(reactContext));

export const createNamedContext = <TContext>(
  contextName: string,
  defaultValue: TContext
) => {
  const context = createContext(defaultValue);
  context.displayName = contextName.endsWith('Context')
    ? contextName
    : `${contextName}Context`;
  // these properties aren't exposed in the type but are used by the react development
  // tools to provide a name to the components
  (context.Provider as any).displayName = `${contextName}.Provider`;
  (context.Consumer as any).displayName = `${contextName}.Consumer`;
  return context;
};
