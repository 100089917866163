/**
 * @generated SignedSource<<c55ba146a292a79bf6e21b1741cce574>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetHoldingsDetailInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type HoldingsDetailModelFilterInput = {
  accountId?: ComparableInt32OperationFilterInput | null;
  and?: ReadonlyArray<HoldingsDetailModelFilterInput> | null;
  asOfDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  assetClass?: StringOperationFilterInput | null;
  costBasis?: ComparableNullableOfDecimalOperationFilterInput | null;
  currentMarketValue?: ComparableNullableOfDecimalOperationFilterInput | null;
  holdingsId?: ComparableInt32OperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  or?: ReadonlyArray<HoldingsDetailModelFilterInput> | null;
  price?: ComparableNullableOfDecimalOperationFilterInput | null;
  productName?: StringOperationFilterInput | null;
  quantity?: ComparableNullableOfDecimalOperationFilterInput | null;
  subAssetClass?: StringOperationFilterInput | null;
  ticker?: StringOperationFilterInput | null;
  unrealizedGain?: ComparableNullableOfDecimalOperationFilterInput | null;
  unrealizedGainPercent?: ComparableNullableOfDecimalOperationFilterInput | null;
  yield?: ComparableNullableOfDecimalOperationFilterInput | null;
};
export type ComparableInt32OperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  nstartsWith?: string | null;
  or?: ReadonlyArray<StringOperationFilterInput> | null;
  startsWith?: string | null;
};
export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type HoldingsDetailQueryReaderQuery$variables = {
  holdingsDetailFilterInput?: HoldingsDetailModelFilterInput | null;
  holdingsDetailInput: GetHoldingsDetailInput;
};
export type HoldingsDetailQueryReaderQuery$data = {
  readonly clientsummary_viewer: {
    readonly financialAccounts: ReadonlyArray<{
      readonly accountName: string;
      readonly accountNumber: string;
      readonly accountType: string;
      readonly accountTypeAbbreviation: string;
      readonly clientIdentifier: any;
      readonly custodian: string;
      readonly id: string;
      readonly managementStyle: string;
      readonly registrationName: string;
    }>;
    readonly getHoldingsDetail: ReadonlyArray<{
      readonly accountId: number;
      readonly asOfDate: any | null;
      readonly assetClass: string | null;
      readonly costBasis: any | null;
      readonly currentMarketValue: any | null;
      readonly holdingsId: number;
      readonly managementStyle: string | null;
      readonly price: any | null;
      readonly productName: string | null;
      readonly quantity: any | null;
      readonly subAssetClass: string | null;
      readonly ticker: string | null;
      readonly unrealizedGain: any | null;
      readonly unrealizedGainPercent: any | null;
      readonly yield: any | null;
    }>;
    readonly id: string;
  };
  readonly userprofile_viewer: {
    readonly accountGroups: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
      readonly id: string;
      readonly name: string;
    }>;
    readonly accounts: ReadonlyArray<{
      readonly accountNickname: string;
      readonly financialAccountId: number;
    }>;
    readonly id: string;
  } | null;
};
export type HoldingsDetailQueryReaderQuery = {
  response: HoldingsDetailQueryReaderQuery$data;
  variables: HoldingsDetailQueryReaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "holdingsDetailFilterInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "holdingsDetailInput"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managementStyle",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserProfileViewer",
    "kind": "LinkedField",
    "name": "userprofile_viewer",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccount",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNickname",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "accountGroups",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientSummaryViewer",
    "kind": "LinkedField",
    "name": "clientsummary_viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "holdingsDetailInput"
          },
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "holdingsDetailFilterInput"
          }
        ],
        "concreteType": "HoldingsDetailModel",
        "kind": "LinkedField",
        "name": "getHoldingsDetail",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "holdingsId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assetClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costBasis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "asOfDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subAssetClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentMarketValue",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ticker",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unrealizedGain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unrealizedGainPercent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "yield",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAccount",
        "kind": "LinkedField",
        "name": "financialAccounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registrationName",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountTypeAbbreviation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodian",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HoldingsDetailQueryReaderQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HoldingsDetailQueryReaderQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "21939d58a8194febe393a60b9b0cb169",
    "id": null,
    "metadata": {},
    "name": "HoldingsDetailQueryReaderQuery",
    "operationKind": "query",
    "text": "query HoldingsDetailQueryReaderQuery(\n  $holdingsDetailInput: GetHoldingsDetailInput!\n  $holdingsDetailFilterInput: HoldingsDetailModelFilterInput\n) {\n  userprofile_viewer {\n    id\n    accounts {\n      accountNickname\n      financialAccountId\n    }\n    accountGroups {\n      id\n      name\n      accounts {\n        id\n        financialAccountId\n      }\n    }\n  }\n  clientsummary_viewer {\n    getHoldingsDetail(input: $holdingsDetailInput, where: $holdingsDetailFilterInput) {\n      holdingsId\n      accountId\n      assetClass\n      costBasis\n      asOfDate\n      subAssetClass\n      currentMarketValue\n      managementStyle\n      productName\n      quantity\n      price\n      ticker\n      unrealizedGain\n      unrealizedGainPercent\n      yield\n    }\n    financialAccounts {\n      id\n      accountName\n      clientIdentifier\n      accountNumber\n      registrationName\n      managementStyle\n      accountType\n      accountTypeAbbreviation\n      custodian\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e38a8730227d6415d6eecf928994d5a0";

export default node;
