import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Dispatch } from 'react';
import { ActionType } from '../ManageAccountGroupReducer';
import {
  ClientPortalFinancialAccount,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';

const classes = {
  disabledRow: `disabled-row`,
};

const StyledDataGrid = styled(DataGrid)(
  ({ theme: { typography, palette } }) => ({
    height: 600,
    width: '100%',
    borderWidth: '1px',
    borderRadius: 0,
    borderColor: palette.greyPercent[50],
    ...typography.body1,
    '& .MuiDataGrid-cell': {
      border: 'none',
    },
    '& .MuiTablePagination-caption': {
      ...typography.link,
    },
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
      {
        display: 'none',
      },
    '& .MuiCheckbox-root.Mui-disabled': {
      display: 'none',
    },
    [`& .${classes.disabledRow}`]: {
      opacity: 0.5,
    },
    '& .MuiDataGrid-footerContainer': {
      border: 'none',
    },
    '& .MuiTablePagination-displayedRows': {
      ...typography.link,
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: palette.greyPercent[16],
      border: 'none',
    },
  })
);

interface AccountDataGridProps {
  allAccounts: ClientPortalFinancialAccount[];
  alreadySelectedAccounts: ClientPortalFinancialAccount[];
  dispatch: Dispatch<any>;
  filterText?: string;
  addedAccounts: ClientPortalFinancialAccount[];
}

export const AccountDataGrid = ({
  allAccounts,
  alreadySelectedAccounts,
  dispatch,
  filterText,
  addedAccounts,
}: AccountDataGridProps) => {
  const columns: GridColDef[] = [
    {
      field: 'accountNickname',
      headerName: 'Account Name',
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        const accountName = params!.row.accountName;
        const userProfileGridCellValue = params!.row.nicknameEntry;
        if (
          userProfileGridCellValue === null ||
          userProfileGridCellValue === undefined
        ) {
          return accountName;
        }
        return (userProfileGridCellValue as UserProfileFinancialAccountNickname)
          .accountNickname;
      },
    },
    {
      field: 'managementStyle',
      headerName: 'Management Style',
      editable: false,
      flex: 1,
    },
    {
      field: 'accountGroup',
      headerName: 'Account Group',
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        return params!.row.accountGroupEntry?.name;
      },
    },
  ];

  const handleIsRowSelectable = (rowId: number) => {
    const exists = alreadySelectedAccounts.filter((account) => {
      return account.financialAccountId === rowId;
    });
    if (exists.length > 0) {
      return false;
    }
    return true;
  };

  let accounts = allAccounts;

  if (filterText) {
    const filterTextLowerCase: string = filterText.toLowerCase();

    accounts = allAccounts.filter(
      (account: ClientPortalFinancialAccount) =>
        (account.nicknameEntry?.accountNickname &&
          account.nicknameEntry.accountNickname
            .toLowerCase()
            .includes(filterTextLowerCase)) ||
        (!account.nicknameEntry?.accountNickname &&
          account.accountName.toLowerCase().includes(filterTextLowerCase)) ||
        account.managementStyle.toLowerCase().includes(filterTextLowerCase)
    );
  }

  return (
    <StyledDataGrid
      rows={accounts}
      columns={columns}
      getRowId={(row) => row.financialAccountId}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 50,
          },
        },
        sorting: {
          sortModel: [
            {
              field: 'accountGroup',
              sort: 'asc',
            },
          ],
        },
      }}
      columnHeaderHeight={40}
      rowHeight={40}
      pageSizeOptions={[50]}
      checkboxSelection
      rowSelectionModel={addedAccounts
        .filter((account) =>
          accounts.some(
            (x) => x.financialAccountId === account.financialAccountId
          )
        )
        .map((x) => x.financialAccountId)}
      isRowSelectable={(params: GridRowParams) => {
        return handleIsRowSelectable(params.id as number);
      }}
      getRowClassName={(params) => {
        return params.row.accountGroupName ? classes.disabledRow : '';
      }}
      disableRowSelectionOnClick
      onRowSelectionModelChange={(ids) => {
        const selectedIds = new Set(ids);
        const selectedRowData = allAccounts.filter((row) =>
          selectedIds.has(row.financialAccountId)
        );

        dispatch({
          type: ActionType.ACCOUNTS_SELECTED,
          payload: {
            addedAccounts: [
              ...selectedRowData,
              ...addedAccounts.filter(
                (account) =>
                  !accounts.some(
                    (x) => x.financialAccountId === account.financialAccountId
                  )
              ),
            ],
          },
        });
      }}
    />
  );
};

export default AccountDataGrid;
