import { ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import { newEdgeNamedColors } from 'src/assets/theme';

const PREFIX = 'MobileNavigationButton';

const classes = {
  button: `${PREFIX}-button`,
  title: `${PREFIX}-title`,
  titleIcon: `${PREFIX}-titleIcon`,
  link: `${PREFIX}-link`,
  linkIcon: `${PREFIX}-linkIcon`,
  moreDetailsLabel: `${PREFIX}-moreDetailsLabel`,
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  [`&.${classes.button}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    padding: theme.spacing(2),
    border: '1px solid #444444',
    borderRadius: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: newEdgeNamedColors.eerieBlack,
  },

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.titleIcon}`]: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.link}`]: {
    display: 'flex',
    alignItems: 'center',
    color: '#888888',
    ...theme.typography.link,
  },

  [`& .${classes.linkIcon}`]: {
    marginLeft: '5px',
  },

  [`& .${classes.moreDetailsLabel}`]: {
    ...theme.typography.link,
  },
}));

interface MobileNavigationButtonProps {
  icon: JSX.Element;
  title: string;
  path: string;
}

export const MobileNavigationButton = ({
  icon,
  title,
  path,
}: MobileNavigationButtonProps) => {
  const navigate = useNavigate();
  return (
    <StyledButtonBase
      className={classes.button}
      onClick={() => {
        navigate(path);
      }}
    >
      <div className={classes.title}>
        <span className={classes.titleIcon}>{icon}</span>
        <Typography variant="h4">{title}</Typography>
      </div>
      <div className={classes.link}>
        <Typography className={classes.moreDetailsLabel}>
          More details
        </Typography>
        <ArrowForwardIcon
          classes={{ root: classes.linkIcon }}
          fontSize="small"
        />
      </div>
    </StyledButtonBase>
  );
};

export default MobileNavigationButton;
