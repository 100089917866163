/**
 * @generated SignedSource<<525331dfe3808b3280d97f0971de67f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetAccountActivityInput = {
  financialAccountIds: ReadonlyArray<number>;
};
export type AccountActivityDataModelFilterInput = {
  accountDefaultName?: StringOperationFilterInput | null;
  accountId?: ComparableInt32OperationFilterInput | null;
  accountNumber?: StringOperationFilterInput | null;
  amount?: ComparableNullableOfDecimalOperationFilterInput | null;
  and?: ReadonlyArray<AccountActivityDataModelFilterInput> | null;
  dataDate?: ComparableDateTimeOperationFilterInput | null;
  managementStyle?: StringOperationFilterInput | null;
  noiseConfidenceLevel?: ComparableNullableOfDecimalOperationFilterInput | null;
  or?: ReadonlyArray<AccountActivityDataModelFilterInput> | null;
  price?: ComparableNullableOfDecimalOperationFilterInput | null;
  productName?: StringOperationFilterInput | null;
  ticker?: StringOperationFilterInput | null;
  transactionDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  transactionId?: IdOperationFilterInput | null;
  transactionType?: StringOperationFilterInput | null;
  units?: ComparableNullableOfDecimalOperationFilterInput | null;
};
export type IdOperationFilterInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
};
export type ComparableInt32OperationFilterInput = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  ngt?: number | null;
  ngte?: number | null;
  nin?: ReadonlyArray<number> | null;
  nlt?: number | null;
  nlte?: number | null;
};
export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any | null> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null;
  contains?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ncontains?: string | null;
  nendsWith?: string | null;
  neq?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  nstartsWith?: string | null;
  or?: ReadonlyArray<StringOperationFilterInput> | null;
  startsWith?: string | null;
};
export type ComparableDateTimeOperationFilterInput = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any> | null;
  lt?: any | null;
  lte?: any | null;
  neq?: any | null;
  ngt?: any | null;
  ngte?: any | null;
  nin?: ReadonlyArray<any> | null;
  nlt?: any | null;
  nlte?: any | null;
};
export type AccountActivityQueryReaderQuery$variables = {
  allAccounts: GetAccountActivityInput;
  filters?: AccountActivityDataModelFilterInput | null;
  selectedAccounts: GetAccountActivityInput;
};
export type AccountActivityQueryReaderQuery$data = {
  readonly clientsummary_viewer: {
    readonly financialAccounts: ReadonlyArray<{
      readonly accountName: string;
      readonly accountNumber: string;
      readonly accountType: string;
      readonly accountTypeAbbreviation: string;
      readonly clientIdentifier: any;
      readonly custodian: string;
      readonly id: string;
      readonly managementStyle: string;
      readonly registrationName: string;
    }>;
    readonly getAccountActivity: {
      readonly data: ReadonlyArray<{
        readonly accountId: number;
        readonly accountNumber: string | null;
        readonly amount: any | null;
        readonly managementStyle: string;
        readonly price: any | null;
        readonly productName: string | null;
        readonly ticker: string | null;
        readonly transactionDate: any | null;
        readonly transactionType: string | null;
        readonly units: any | null;
      }>;
      readonly filterOptions: {
        readonly managementStyles: ReadonlyArray<string>;
        readonly maxTransactionAmount: any | null;
        readonly minTransactionAmount: any | null;
        readonly transactionTypes: ReadonlyArray<string | null>;
      };
    };
    readonly id: string;
  };
  readonly userprofile_viewer: {
    readonly accountGroups: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
      readonly id: string;
      readonly name: string;
    }>;
    readonly accounts: ReadonlyArray<{
      readonly accountNickname: string;
      readonly financialAccountId: number;
    }>;
    readonly id: string;
  } | null;
};
export type AccountActivityQueryReaderQuery = {
  response: AccountActivityQueryReaderQuery$data;
  variables: AccountActivityQueryReaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allAccounts"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedAccounts"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managementStyle",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserProfileViewer",
    "kind": "LinkedField",
    "name": "userprofile_viewer",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccount",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNickname",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "accountGroups",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientSummaryViewer",
    "kind": "LinkedField",
    "name": "clientsummary_viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountActivityModel",
        "kind": "LinkedField",
        "name": "getAccountActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "selectedAccounts"
              },
              {
                "kind": "Variable",
                "name": "where",
                "variableName": "filters"
              }
            ],
            "concreteType": "AccountActivityDataModel",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountId",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ticker",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionType",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "allAccounts"
              }
            ],
            "concreteType": "AccountActivityFilterOptions",
            "kind": "LinkedField",
            "name": "filterOptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "managementStyles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxTransactionAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minTransactionAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionTypes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAccount",
        "kind": "LinkedField",
        "name": "financialAccounts",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientIdentifier",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registrationName",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountTypeAbbreviation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custodian",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountActivityQueryReaderQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountActivityQueryReaderQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "136f855c8aaf1ce50157e7f741805394",
    "id": null,
    "metadata": {},
    "name": "AccountActivityQueryReaderQuery",
    "operationKind": "query",
    "text": "query AccountActivityQueryReaderQuery(\n  $allAccounts: GetAccountActivityInput!\n  $selectedAccounts: GetAccountActivityInput!\n  $filters: AccountActivityDataModelFilterInput\n) {\n  userprofile_viewer {\n    id\n    accounts {\n      accountNickname\n      financialAccountId\n    }\n    accountGroups {\n      id\n      name\n      accounts {\n        id\n        financialAccountId\n      }\n    }\n  }\n  clientsummary_viewer {\n    getAccountActivity {\n      data(input: $selectedAccounts, where: $filters) {\n        accountId\n        accountNumber\n        amount\n        transactionDate\n        ticker\n        productName\n        units\n        price\n        transactionType\n        managementStyle\n      }\n      filterOptions(input: $allAccounts) {\n        managementStyles\n        maxTransactionAmount\n        minTransactionAmount\n        transactionTypes\n      }\n    }\n    financialAccounts {\n      id\n      accountName\n      clientIdentifier\n      accountNumber\n      registrationName\n      managementStyle\n      accountType\n      accountTypeAbbreviation\n      custodian\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fd53fe483d5f9e4bba4c3d74c582aa7";

export default node;
