/**
 * @generated SignedSource<<ea75426f7f11133c9a76da218b7803b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileView_clientsummary_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ManageAccountGroupView_clientsummary_viewer" | "ManageNicknames_clientsummary_data">;
  readonly " $fragmentType": "ProfileView_clientsummary_viewer";
};
export type ProfileView_clientsummary_viewer$key = {
  readonly " $data"?: ProfileView_clientsummary_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileView_clientsummary_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileView_clientsummary_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageAccountGroupView_clientsummary_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageNicknames_clientsummary_data"
    }
  ],
  "type": "ClientSummaryViewer",
  "abstractKey": null
};

(node as any).hash = "409aeec1fa173d7d47ce9edd58ed6aec";

export default node;
