import { Suspense } from 'react';
import { styled } from '@mui/material/styles';

import { Outlet } from 'react-router';
import PageFooter from './PageFooter';
import { Skeleton } from '@mui/lab';

const StyledMain = styled('main')(({ theme: { spacing, breakpoints } }) => ({
  padding: spacing(3),
  marginLeft: '65px',
  width: '90vw',
  maxWidth: '1400px',
  [breakpoints.down('md')]: {
    marginLeft: 0,
    overflow: 'hidden',
    width: '100%',
  },
}));

function PageWrapper() {
  return (
    <StyledMain>
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <Outlet />
      </Suspense>
      <PageFooter />
    </StyledMain>
  );
}

export default PageWrapper;
