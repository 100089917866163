/**
 * @generated SignedSource<<cb29290f1a68054e8da4672ac77753b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountGroupNameChangeInput = {
  id: string;
  newName: string;
};
export type ManageAccountGroupView_UpdateAccountGroupNameMutation$variables = {
  input: AccountGroupNameChangeInput;
};
export type ManageAccountGroupView_UpdateAccountGroupNameMutation$data = {
  readonly UpdateAccountGroupName: {
    readonly accountGroup: {
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
      readonly id: string;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
    }> | null;
  };
};
export type ManageAccountGroupView_UpdateAccountGroupNameMutation = {
  response: ManageAccountGroupView_UpdateAccountGroupNameMutation$data;
  variables: ManageAccountGroupView_UpdateAccountGroupNameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountGroup",
  "kind": "LinkedField",
  "name": "accountGroup",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "financialAccountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageAccountGroupView_UpdateAccountGroupNameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountGroupPayload",
        "kind": "LinkedField",
        "name": "UpdateAccountGroupName",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageAccountGroupView_UpdateAccountGroupNameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountGroupPayload",
        "kind": "LinkedField",
        "name": "UpdateAccountGroupName",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adfe462f06f7887b603c55f029eed851",
    "id": null,
    "metadata": {},
    "name": "ManageAccountGroupView_UpdateAccountGroupNameMutation",
    "operationKind": "mutation",
    "text": "mutation ManageAccountGroupView_UpdateAccountGroupNameMutation(\n  $input: AccountGroupNameChangeInput!\n) {\n  UpdateAccountGroupName(input: $input) {\n    accountGroup {\n      id\n      name\n      accounts {\n        id\n        financialAccountId\n      }\n    }\n    errors {\n      __typename\n      message\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9722bb7ccda03b71c3d2f1995077b0a";

export default node;
