/**
 * @generated SignedSource<<f70c453b1e034d983e34804684cc20ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalAccountSelectWrapper_query$data = {
  readonly clientsummary_viewer: {
    readonly financialAccounts: ReadonlyArray<{
      readonly accountName: string;
      readonly id: string;
    }>;
  };
  readonly userprofile_viewer: {
    readonly accountGroups: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
      readonly id: string;
      readonly name: string;
    }>;
    readonly accounts: ReadonlyArray<{
      readonly accountNickname: string;
      readonly financialAccountId: number;
    }>;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "GlobalAccountSelectWrapper_query";
};
export type GlobalAccountSelectWrapper_query$key = {
  readonly " $data"?: GlobalAccountSelectWrapper_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalAccountSelectWrapper_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalAccountSelectWrapper_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfileViewer",
      "kind": "LinkedField",
      "name": "userprofile_viewer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAccount",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountNickname",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountGroup",
          "kind": "LinkedField",
          "name": "accountGroups",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "accounts",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientSummaryViewer",
      "kind": "LinkedField",
      "name": "clientsummary_viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAccount",
          "kind": "LinkedField",
          "name": "financialAccounts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8406ee65186d42659f4ac3f22604244e";

export default node;
