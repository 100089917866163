import { Skeleton } from '@mui/lab';
import { Suspense, useContext, useEffect, useState } from 'react';
import { PreloadedQuery, useQueryLoader } from 'react-relay';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import { FilterItem } from 'src/components/table-filter/@types';
import { LandingPageViewQuery } from '../__generated__/LandingPageViewQuery.graphql';
import {
  AssetAllocationDetailQueryReaderQuery,
  AssetAllocationDetailQueryReaderQuery$variables,
} from './__generated__/AssetAllocationDetailQueryReaderQuery.graphql';
import ReportWrapper from '../ReportWrapper';
import {
  AssetAllocationDetailQueryReader,
  AssetAllocationDetailQueryReaderNode,
} from './AssetAllocationDetailQueryReader';

const applySecondaryFilters = (
  variables: AssetAllocationDetailQueryReaderQuery$variables,
  filterValues: FilterItem[]
): AssetAllocationDetailQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);
  let input = newVariables.assetAllocationInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.assetAllocationInput = {
      financialAccountIds: [...ids],
    };
  }
  return newVariables;
};

interface AssetAllocationLoaderProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}

export const AssetAllocationDetailLoader = ({
  landingPageQueryRef,
}: AssetAllocationLoaderProps) => {
  const { assetAllocationQueryVariables } = useContext(QueryVariablesContext);
  const [assetAllocationQueryRef, loadAssetAllocationQuery] =
    useQueryLoader<AssetAllocationDetailQueryReaderQuery>(
      AssetAllocationDetailQueryReaderNode
    );

  const [filterValues, setFilterValues] = useState<FilterItem[]>([]);
  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  useEffect(() => {
    loadAssetAllocationQuery(
      applySecondaryFilters(assetAllocationQueryVariables, filterValues)
    );
  }, [assetAllocationQueryVariables, filterValues, loadAssetAllocationQuery]);

  return assetAllocationQueryRef != null ? (
    <ReportWrapper
      title="Asset Allocation"
      landingPageQueryRef={landingPageQueryRef}
      asOfDate={asOfDate}
    >
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <AssetAllocationDetailQueryReader
          queryRef={assetAllocationQueryRef}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setAsOfDate={setAsOfDate}
        />
      </Suspense>
    </ReportWrapper>
  ) : null;
};
