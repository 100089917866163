import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { grey } from 'src/assets/theme';
import CustomButton from 'src/components/buttons/CustomButton';

type MobileProfileDialogProps = {
  isModalOpen: boolean;
  toggleModalOpen: (toggleModal: boolean) => void;
  toggleDrawerOpen: (toggleDrawer: boolean) => void;
};

const MobileProfileDialog = ({
  isModalOpen,
  toggleModalOpen,
  toggleDrawerOpen,
}: MobileProfileDialogProps) => {
  const handleClose = () => {
    toggleModalOpen(false);
    toggleDrawerOpen(false);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => handleClose()}
      aria-labelledby="ok"
      sx={(theme) => ({ ...theme.typography.body1 })}
    >
      <DialogContent
        sx={{ backgroundColor: grey[42], textAlign: 'center', width: 'auto' }}
      >
        <DialogContentText
          sx={{ width: '75%', margin: 'auto' }}
          id="mobile-profile-description"
        >
          This feature can only be accessed on your desktop.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          backgroundColor: grey[42],
        }}
      >
        <CustomButton
          buttonProps={{
            onClick: () => handleClose(),
            sx: {
              width: '12rem',
              color: 'common.white',
              margin: '.5rem',
              background: '#0A42C6',
              fontSize: '1.4rem',
            },
          }}
        >
          Ok
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default MobileProfileDialog;
