import { Button, Grid, Paper, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import ConfirmDeleteDialog from 'src/components/modals/ConfirmDeleteDialog';
import {
  ClientPortalAccountGroup,
  ClientPortalFinancialAccount,
  TooltipWrapper,
} from '@newedge/common';

const DELETE_ACCOUNT_CONFIRMATION_MESSAGE =
  'Are you sure you want to remove this Account from this Account Group?';

interface AccountListItemProps {
  account: ClientPortalFinancialAccount;
  accountGroup: ClientPortalAccountGroup;
  onDelete: (
    account: ClientPortalFinancialAccount,
    accountGroup: ClientPortalAccountGroup
  ) => void;
  index?: number;
}

export const AccountListItem = ({
  account,
  accountGroup,
  onDelete,
  index,
}: AccountListItemProps) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  const showConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={(theme) => ({
            padding: theme.spacing(1, 2),
            backgroundColor:
              index && index % 2 > 0
                ? theme.palette.greyPercent[8]
                : theme.palette.greyPercent[12],
          })}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3} sx={{ p: 1 }}>
              <TooltipWrapper text={account.accountName} maxLength={72} />
            </Grid>
            <Grid item xs={3} sx={{ p: 1 }}>
              <Typography sx={{ overflowWrap: 'anywhere' }}>
                {account.nicknameEntry?.accountNickname}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ p: 1 }}>
              <Typography>Account Number: {account.accountNumber}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ p: 1 }}>
              <Button
                color="secondary"
                onClick={() => showConfirmDeleteDialog()}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        message={DELETE_ACCOUNT_CONFIRMATION_MESSAGE}
        submit={() => onDelete(account, accountGroup)}
      />
    </>
  );
};

export default AccountListItem;
