import {
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  InputBase,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChangeEvent, FormEvent, MouseEvent, useRef, useState } from 'react';
import { grey, newEdgeNamedColors } from 'src/assets/theme';
import AccordionItem from 'src/components/accordion/AccordionItem';
import { AccountList } from './AccountList';
import ConfirmDeleteDialog from 'src/components/modals/ConfirmDeleteDialog';
import {
  ClientPortalAccountGroup,
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
} from '@newedge/common';

const DELETE_ACCOUNT_GROUP_CONFIRMATION_MESSAGE =
  'Are you sure you want to delete this Account Group?';

interface AccountGroupItemProps {
  accountGroup: ClientPortalAccountGroup;
  selectedAccountGroup: UserProfileAccountGroup | null;
  onClickAccountGroup: (accountGroup: ClientPortalAccountGroup) => void;
  onAccountGroupAddAccounts: (accountGroup: ClientPortalAccountGroup) => void;
  onDeleteAccountGroup: (accountGroup: ClientPortalAccountGroup) => void;
  onDeleteAccountFromAccountGroup: (
    account: ClientPortalFinancialAccount,
    accountGroup: ClientPortalAccountGroup
  ) => void;
  onUpdateAccountGroupName: (
    accountGroup: ClientPortalAccountGroup,
    newName: string
  ) => Promise<boolean>;
  onEditAccountGroupNameInputValueChange: (
    value: string,
    currentAccountGroupName: string
  ) => void;
  onEditModeClickAway: () => void;
}

const AccountGroupItem = ({
  accountGroup,
  selectedAccountGroup,
  onClickAccountGroup,
  onAccountGroupAddAccounts,
  onDeleteAccountGroup,
  onDeleteAccountFromAccountGroup,
  onUpdateAccountGroupName,
  onEditAccountGroupNameInputValueChange,
  onEditModeClickAway,
}: AccountGroupItemProps) => {
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [accountGroupName, setAccountGroupName] = useState<string>(
    accountGroup.name
  );

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  const isOpen = selectedAccountGroup?.id === accountGroup.id;

  const inputRef = useRef<HTMLInputElement>(null);

  if (selectedAccountGroup?.id !== accountGroup.id && isInEditMode) {
    setIsInEditMode(false);
    setAccountGroupName(accountGroup.name);
  }

  const handleInputClick = (event: MouseEvent<HTMLElement>) => {
    if (isInEditMode) {
      event.stopPropagation();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountGroupName(event.target.value);

    onEditAccountGroupNameInputValueChange(
      event.target.value,
      accountGroup.name
    );
  };

  const handleEditClick = (event: MouseEvent<HTMLElement>) => {
    setIsInEditMode(true);

    setAccountGroupName(accountGroup.name);

    if (inputRef.current) {
      inputRef.current.focus();
    }

    event.stopPropagation();
  };

  const update = () => {
    onUpdateAccountGroupName(accountGroup, accountGroupName).then(
      (isSuccess) => {
        if (isSuccess) {
          setIsInEditMode(false);
        }
      }
    );
  };

  const handleUpdateClick = (event: MouseEvent<HTMLElement>) => {
    update();

    event.stopPropagation();
  };

  const handleSubmit = (event: FormEvent) => {
    if (isInEditMode) {
      update();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const handleClickAway = () => {
    if (isInEditMode) {
      setIsInEditMode(false);
      setAccountGroupName(accountGroup.name);
      onEditModeClickAway();
    }
  };

  const showConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <AccordionItem
          summaryComponent={
            <Grid container direction="row" alignItems="center">
              <Grid item xs={8}>
                <Box
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onSubmit={handleSubmit}
                >
                  <InputBase
                    value={accountGroupName}
                    readOnly={!isInEditMode}
                    inputRef={inputRef}
                    inputProps={{
                      sx: {
                        width: '210px',
                        borderRadius: isInEditMode ? 4 : undefined,
                        border: isInEditMode
                          ? `0.5px solid ${newEdgeNamedColors.lightCadet}`
                          : undefined,
                      },
                      onClick: handleInputClick,
                      onChange: handleInputChange,
                    }}
                  />
                  <Button
                    id={accountGroup.id.toString()}
                    sx={{
                      backgroundColor: newEdgeNamedColors.cadet,
                      visibility: isOpen ? undefined : 'hidden',
                    }}
                    onClick={
                      isInEditMode && accountGroupName.length > 0
                        ? handleUpdateClick
                        : handleEditClick
                    }
                    key={accountGroup.id + '-edit'}
                  >
                    {isInEditMode && accountGroupName.length > 0
                      ? 'Update'
                      : 'Edit'}
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  control={
                    <Button
                      onClick={() => onAccountGroupAddAccounts(accountGroup)}
                    >
                      Add Accounts
                    </Button>
                  }
                  label=""
                />
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  control={
                    <Button
                      color="secondary"
                      onClick={() => showConfirmDeleteDialog()}
                    >
                      <DeleteIcon />
                    </Button>
                  }
                  label=""
                />
              </Grid>
            </Grid>
          }
          detailsComponent={
            <AccountList
              onDeleteAccountFromAccountGroup={onDeleteAccountFromAccountGroup}
              accountGroup={accountGroup}
            />
          }
          isExpanded={isOpen}
          onChange={() => onClickAccountGroup(accountGroup)}
          key={accountGroup.id}
          defaultIconSize={20}
          accordionProps={{
            style: {
              backgroundColor: grey[16],
            },
          }}
          accordionSummaryProps={{
            style: {
              backgroundColor: grey[16],
            },
          }}
        />
      </ClickAwayListener>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        message={DELETE_ACCOUNT_GROUP_CONFIRMATION_MESSAGE}
        submit={() => onDeleteAccountGroup(accountGroup)}
      />
    </>
  );
};

export default AccountGroupItem;
