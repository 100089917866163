import {
  Avatar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import LogoutDialog from 'src/components/modals/LogoutDialog';

type UserAccountMenuProps = {
  userDisplayName?: string | null;
};
function UserAccountMenu({ userDisplayName }: UserAccountMenuProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const styles = {
    avatar: {
      color: `palette.text.primary`,
    },
    displayName: {
      display: 'inline',
      textAlign: 'center',
      fontSize: 16,
    },
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToProfile = () => {
    handleMenuClose();
    navigate('/my/profile');
  };

  const userAccountMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="user-account-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleNavigateToProfile}>Profile</MenuItem>
      <MenuItem>
        <LogoutDialog />
      </MenuItem>
    </Menu>
  );

  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item>
        <Typography sx={styles.displayName} data-testid="TopBarProfileName">
          {userDisplayName}
        </Typography>
        <IconButton onClick={handleMenuOpen} size="large">
          <Avatar sx={styles.avatar} />
        </IconButton>
      </Grid>
      {userAccountMenu}
    </Grid>
  );
}

export default UserAccountMenu;
