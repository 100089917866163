import { lighten } from '@mui/material';
import chroma from 'chroma-js';
import { newEdgeNamedColors } from './theme';
import { ColorSchemeValue } from './colorScheme';

export const getChromaColorScheme = (
  numberOfColors: number,
  referenceColors: string[]
) =>
  chroma
    .scale(referenceColors)
    .mode('lch')
    .correctLightness()
    .colors(numberOfColors)
    .map((x) => ({ primary: x, active: lighten(x, 0.25) }));

export const getPrimaryAndActiveColors = (
  referenceColors: string[]
): ColorSchemeValue[] =>
  referenceColors.map((color) => {
    return { primary: color, active: lighten(color, 0.25) };
  });

export const colorScheme = [
  newEdgeNamedColors.astral,
  newEdgeNamedColors.astralComplimentary,
];

export const colorScheme2 = [
  newEdgeNamedColors.calypso,
  newEdgeNamedColors.iron,
];

export const donutChartColorScheme = [
  newEdgeNamedColors.indigo,
  newEdgeNamedColors.persian,
  newEdgeNamedColors.astral,
  newEdgeNamedColors.marigold,
  newEdgeNamedColors.saffron,
  newEdgeNamedColors.cadet,
  newEdgeNamedColors.white,
];
