import { PreloadedQuery, useQueryLoader } from 'react-relay';
import { LandingPageViewQuery } from '../__generated__/LandingPageViewQuery.graphql';
import { Suspense, useContext, useEffect, useState } from 'react';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import {
  AccountBalancesQueryReaderQuery,
  AccountBalancesQueryReaderQuery$variables,
} from './__generated__/AccountBalancesQueryReaderQuery.graphql';
import {
  AccountBalancesQueryReader,
  AccountBalancesQueryReaderNode,
} from './AccountBalancesQueryReader';
import { FilterItem } from 'src/components/table-filter/@types';
import ReportWrapper from '../ReportWrapper';
import { Skeleton } from '@mui/material';

const applySecondaryFilters = (
  variables: AccountBalancesQueryReaderQuery$variables,
  filterValues: FilterItem[]
): AccountBalancesQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);
  let input = variables.accountBalancesInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.accountBalancesInput = {
      financialAccountIds: [...ids],
    };
  }
  return newVariables;
};

interface AccountBalancesLoaderProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}

export const AccountBalancesLoader = ({
  landingPageQueryRef,
}: AccountBalancesLoaderProps) => {
  const { accountBalancesQueryVariables } = useContext(QueryVariablesContext);
  const [accountBalancesQueryRef, loadAccountBalancesQuery] =
    useQueryLoader<AccountBalancesQueryReaderQuery>(
      AccountBalancesQueryReaderNode
    );

  const [filterValues, setFilterValues] = useState<FilterItem[]>([]);
  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  useEffect(() => {
    loadAccountBalancesQuery(
      applySecondaryFilters(accountBalancesQueryVariables, filterValues)
    );
  }, [accountBalancesQueryVariables, filterValues, loadAccountBalancesQuery]);

  return accountBalancesQueryRef != null ? (
    <ReportWrapper
      title="Account Balances"
      asOfDate={asOfDate}
      landingPageQueryRef={landingPageQueryRef}
    >
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <AccountBalancesQueryReader
          queryRef={accountBalancesQueryRef}
          filterValues={filterValues}
          setAsOfDate={setAsOfDate}
          setFilterValues={setFilterValues}
        />
      </Suspense>
    </ReportWrapper>
  ) : null;
};
