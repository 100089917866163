/* eslint-disable import/extensions */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { GlobalFilterContext } from './GlobalFilterContext';
import { DashboardViewQuery$variables } from 'src/views/dashboard/__generated__/DashboardViewQuery.graphql';
import { DashboardViewAccountBalanceWidgetQuery$variables } from 'src/views/dashboard/__generated__/DashboardViewAccountBalanceWidgetQuery.graphql';
import { HoldingsDetailQueryReaderQuery$variables } from 'src/views/holdings/__generated__/HoldingsDetailQueryReaderQuery.graphql';
import { AssetAllocationDetailQueryReaderQuery$variables } from 'src/views/asset-allocation/__generated__/AssetAllocationDetailQueryReaderQuery.graphql';
import {
  CashFlowDetailModelFilterInput,
  CashFlowDetailQueryReaderQuery$variables,
} from 'src/views/cash-flow/__generated__/CashFlowDetailQueryReaderQuery.graphql';
import { AccountBalancesQueryReaderQuery$variables } from 'src/views/account-balances/__generated__/AccountBalancesQueryReaderQuery.graphql';
import { AccountActivityQueryReaderQuery$variables } from 'src/views/account-activity/__generated__/AccountActivityQueryReaderQuery.graphql';
import { PerformanceQueryReaderQuery$variables } from 'src/views/performance/__generated__/PerformanceQueryReaderQuery.graphql';
import { PerformanceQueryReaderBenchmarkQuery$variables } from 'src/views/performance/__generated__/PerformanceQueryReaderBenchmarkQuery.graphql';

export interface QueryVariablesContextType {
  accountBalanceWidgetQueryVariables: DashboardViewAccountBalanceWidgetQuery$variables;
  dashboardQueryVariables: DashboardViewQuery$variables;
  cashFlowDetailQueryVariables: CashFlowDetailQueryReaderQuery$variables;
  assetAllocationQueryVariables: AssetAllocationDetailQueryReaderQuery$variables;
  holdingsDetailQueryVariables: HoldingsDetailQueryReaderQuery$variables;
  accountBalancesQueryVariables: AccountBalancesQueryReaderQuery$variables;
  accountActivityQueryVariables: AccountActivityQueryReaderQuery$variables;
  performanceQueryVariables: PerformanceQueryReaderQuery$variables;
  performanceBenchmarkQueryVariables: PerformanceQueryReaderBenchmarkQuery$variables;
  setSelectedAssetClass: (assetClass: string) => void;
}

export const QueryVariablesContext = React.createContext(
  {} as QueryVariablesContextType
);

export const getCashFlowFilterInput = (): CashFlowDetailModelFilterInput => {
  const now = DateTime.now();
  const startDate = now.startOf('month').plus({ months: 1 });
  const endDate = now.plus({ years: 1 });
  return {
    and: [
      { incomeEstimateDate: { gte: startDate.toFormat('yyyy-MM-dd') } },
      { incomeEstimateDate: { lte: endDate.toFormat('yyyy-MM-dd') } },
    ],
  };
};

interface QueryVariablesProviderProps {
  children: React.ReactNode;
}

export const QueryVariablesProvider = ({
  children,
}: QueryVariablesProviderProps) => {
  const { selectedAccounts, allAccounts } = useContext(GlobalFilterContext);
  const assetAllocationQueryVariables = useMemo(
    () => ({
      assetAllocationInput: {
        financialAccountIds: selectedAccounts,
      },
      balancesInput: {
        financialAccountIds: selectedAccounts,
      },
    }),
    [selectedAccounts]
  );

  const holdingsDetailQueryVariables = useMemo(
    () => ({
      holdingsDetailInput: {
        financialAccountIds: selectedAccounts,
      },
    }),
    [selectedAccounts]
  );

  const accountActivityQueryVariables = useMemo(
    () => ({
      selectedAccounts: {
        financialAccountIds: selectedAccounts,
      },
      allAccounts: {
        financialAccountIds: allAccounts,
      },
    }),
    [allAccounts, selectedAccounts]
  );

  const accountBalancesQueryVariables = useMemo(
    () => ({
      accountBalancesInput: {
        financialAccountIds: selectedAccounts,
      },
    }),
    [selectedAccounts]
  );

  const accountBalanceWidgetQueryVariables = useMemo(
    () => ({
      accountBalancesWidgetInput: {
        financialAccountIds: allAccounts,
      },
    }),
    [allAccounts]
  );

  const [selectedAssetClass, setSelectedAssetClass] = useState('Fixed Income');
  const dashboardQueryVariables = useMemo(
    () => ({
      cashFlowDashboardInput: {
        financialAccountIds: selectedAccounts,
      },
      cashFlowDashboardFilterInput: getCashFlowFilterInput(),
      getPortfolioValuationBalancesInput: {
        financialAccountIds: selectedAccounts,
      },
      getDailyChangeForAccountsInput: {
        financialAccountIds: selectedAccounts,
      },
      assetAllocationWidgetInput: {
        financialAccountIds: selectedAccounts,
      },
      subAssetClassWidgetInput: {
        financialAccountIds: selectedAccounts,
        assetClass: selectedAssetClass,
        maxNumberOfRecords: 5,
      },
    }),
    [selectedAccounts, selectedAssetClass]
  );

  const cashFlowDetailQueryVariables = useMemo(
    () => ({
      cashFlowDetailInput: {
        financialAccountIds: selectedAccounts,
      },
      cashFlowDetailFilterInput: getCashFlowFilterInput(),
    }),
    [selectedAccounts]
  );

  const performanceQueryVariables = useMemo(
    () => ({
      performanceInput: {
        financialAccountIds: allAccounts,
      },
    }),
    [allAccounts]
  );

  const performanceBenchmarkQueryVariables = useMemo(() => ({}), []);

  const getContextValue = useCallback(() => {
    return {
      accountBalanceWidgetQueryVariables,
      dashboardQueryVariables,
      cashFlowDetailQueryVariables,
      assetAllocationQueryVariables,
      holdingsDetailQueryVariables,
      accountActivityQueryVariables,
      accountBalancesQueryVariables,
      performanceQueryVariables,
      performanceBenchmarkQueryVariables,
      setSelectedAssetClass,
    };
  }, [
    accountBalanceWidgetQueryVariables,
    dashboardQueryVariables,
    cashFlowDetailQueryVariables,
    assetAllocationQueryVariables,
    holdingsDetailQueryVariables,
    accountActivityQueryVariables,
    accountBalancesQueryVariables,
    performanceBenchmarkQueryVariables,
    performanceQueryVariables,
  ]);

  return (
    <QueryVariablesContext.Provider value={getContextValue()}>
      {children}
    </QueryVariablesContext.Provider>
  );
};
