import { FilterItem } from '@newedge/common';
import { DateTime } from 'luxon';
import { Suspense, useContext, useEffect, useState } from 'react';
import { PreloadedQuery, useQueryLoader } from 'react-relay';
import { useLocation } from 'react-router';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import { LandingPageViewQuery } from '../__generated__/LandingPageViewQuery.graphql';
import ReportWrapper from '../ReportWrapper';
import { Skeleton } from '@mui/material';
import {
  AccountActivityDataModelFilterInput,
  AccountActivityQueryReaderQuery,
  AccountActivityQueryReaderQuery$variables,
} from './__generated__/AccountActivityQueryReaderQuery.graphql';
import {
  AccountActivityQueryReader,
  AccountActivityQueryReaderQueryNode,
} from './AccountActivityQueryReader';
import { getDefaultFilterItems } from '@newedge/reports';

const applySecondaryFilters = (
  variables: AccountActivityQueryReaderQuery$variables,
  filterValues: FilterItem[]
): AccountActivityQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);

  let input = variables.selectedAccounts;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.selectedAccounts = {
      financialAccountIds: [...ids],
    };
  }

  const and: AccountActivityDataModelFilterInput[] = [];
  const or: AccountActivityDataModelFilterInput[] = [];

  const rangeFilter = filterValues.find((o) => o.filterType === 'range');
  if (rangeFilter && rangeFilter.predicateIdentifier) {
    and.push({
      [rangeFilter.predicateIdentifier]: {
        gte: rangeFilter.lowerBound,
        lte: rangeFilter.upperBound,
      },
    });
  }

  const dateFilter = filterValues.find((o) => o.filterType === 'date');
  if (dateFilter && dateFilter.date && dateFilter.predicateIdentifier) {
    and.push({
      [dateFilter.predicateIdentifier]: {
        gte: dateFilter.date.startDate?.toISO(),
        lte: dateFilter.date.endDate?.toISO(),
      },
    });
  }

  const remainingFilters = filterValues.filter(
    (o) =>
      o.predicateIdentifier &&
      o.predicateIdentifier !== 'accountId' &&
      o.predicateIdentifier !== 'amount' &&
      o.predicateIdentifier !== 'transactionDate'
  );

  if (remainingFilters.length > 0) {
    remainingFilters.forEach((o) => {
      if (o.predicateIdentifier) {
        switch (o.logic) {
          case 'or':
            or.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
            break;
          case 'and':
          case undefined:
          default:
            and.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
        }
      }
    });
  }

  let where: AccountActivityDataModelFilterInput | null = {
    or,
    and,
  };
  if (where.and?.length === 0) {
    delete where.and;
  }
  if (where.or?.length === 0) {
    delete where.or;
  }
  if (and.length > 0 || or.length > 0) {
    newVariables.filters = where;
  }

  return newVariables;
};

interface AccountActivityLoaderProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}

interface LocationState {
  secondaryFilters?: FilterItem[];
}

const getInitialFilterItems = (locationState: LocationState) => {
  if (!locationState) {
    return getDefaultFilterItems();
  }
  return locationState.secondaryFilters as FilterItem[];
};

export const AccountActivityLoader = ({
  landingPageQueryRef,
}: AccountActivityLoaderProps) => {
  const { accountActivityQueryVariables } = useContext(QueryVariablesContext);
  const [accountActivityQueryRef, loadAccountActivityQuery] =
    useQueryLoader<AccountActivityQueryReaderQuery>(
      AccountActivityQueryReaderQueryNode
    );
  const { state } = useLocation();

  const defaultFilterItems = getInitialFilterItems(state);

  const [filterValues, setFilterValues] =
    useState<FilterItem[]>(defaultFilterItems);

  useEffect(() => {
    loadAccountActivityQuery(
      applySecondaryFilters(accountActivityQueryVariables, filterValues)
    );
  }, [accountActivityQueryVariables, filterValues, loadAccountActivityQuery]);

  return accountActivityQueryRef != null ? (
    <ReportWrapper
      title="Account Activity"
      landingPageQueryRef={landingPageQueryRef}
    >
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <AccountActivityQueryReader
          queryRef={accountActivityQueryRef}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          hasInitialFilters={
            defaultFilterItems && defaultFilterItems.length > 0
          }
        />
      </Suspense>
    </ReportWrapper>
  ) : null;
};
