/**
 * @generated SignedSource<<f2e413c2517c60978065c91428e3aa95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAccountNicknameInput = {
  accountNickname: string;
  financialAccountId: number;
};
export type ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation$variables = {
  input: UpdateAccountNicknameInput;
};
export type ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation$data = {
  readonly UpdateAccountNickname: {
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
    }> | null;
    readonly viewer: {
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
    } | null;
  };
};
export type ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation = {
  response: ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation$data;
  variables: ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAccount",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNickname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "financialAccountId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "UpdateAccountNickname",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "UpdateAccountNickname",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98ad956d17a36f692870d8e705959c49",
    "id": null,
    "metadata": {},
    "name": "ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation",
    "operationKind": "mutation",
    "text": "mutation ManageNicknameTableRow_UpdateFinancialAccountNicknameMutation(\n  $input: UpdateAccountNicknameInput!\n) {\n  UpdateAccountNickname(input: $input) {\n    viewer {\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      id\n    }\n    errors {\n      __typename\n      message\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "786af38e883c231f13eeaf9ab181f3e0";

export default node;
