import {
  GlobalAccountSelect,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import graphql from 'babel-plugin-relay/macro';
import { useContext } from 'react';
import { PreloadedQuery, useFragment, usePreloadedQuery } from 'react-relay';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import { LandingPageViewGqlQuery } from 'src/views/LandingPageView';
// eslint-disable-next-line import/extensions
import { LandingPageViewQuery } from 'src/views/__generated__/LandingPageViewQuery.graphql';
import { GlobalFilterContext } from '../context/GlobalFilterContext';
import { GlobalAccountSelectWrapper_query$key } from './__generated__/GlobalAccountSelectWrapper_query.graphql';
import { Box } from '@mui/material';
import { FinancialAccountInfo } from 'src/@types/global';

export const GlobalAccountSelectWrapperQueryNode = graphql`
  fragment GlobalAccountSelectWrapper_query on Query {
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      financialAccounts {
        id
        accountName
      }
    }
  }
`;

interface GlobalAccountSelectWrapperProps {
  landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
}

export const GlobalAccountSelectWrapper = ({
  landingPageQueryRef,
}: GlobalAccountSelectWrapperProps) => {
  const queryData = usePreloadedQuery(
    LandingPageViewGqlQuery,
    landingPageQueryRef
  );
  const fragmentData = useFragment<GlobalAccountSelectWrapper_query$key>(
    GlobalAccountSelectWrapperQueryNode,
    queryData
  );

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    fragmentData.userprofile_viewer
      ?.accounts as UserProfileFinancialAccountNickname[],
    fragmentData.clientsummary_viewer
      ?.financialAccounts as FinancialAccountInfo[]
  );
  let accountGroups: UserProfileAccountGroup[] = [];
  if (fragmentData.userprofile_viewer) {
    accountGroups = fragmentData.userprofile_viewer
      .accountGroups as UserProfileAccountGroup[];
  }

  const { selectedAccounts, setSelectedAccounts, allAccounts } =
    useContext(GlobalFilterContext);

  return (
    <Box sx={{ marginTop: 4 }}>
      <GlobalAccountSelect
        accounts={accounts}
        accountGroups={accountGroups}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
        allAccounts={allAccounts}
      />
    </Box>
  );
};
