import { macgColors } from './macgColors';

export type ColorSchemeValue = {
  primary: string;
  active?: string;
};

export type ColorScheme = ColorSchemeValue[];

type DataColors = typeof macgColors.data;

export class ColorSchemeProvider {
  schemes: ColorScheme[];

  colors: DataColors;

  constructor(schemes?: ColorScheme[], colors: DataColors = macgColors.data) {
    this.colors = colors;
    const p = colors;
    if (schemes) {
      this.schemes = schemes;
      return;
    }

    this.schemes = [];
    this.schemes[3] = [
      { primary: p.data1, active: p.data1Light },
      { primary: p.data2, active: p.data2Light },
      { primary: p.data3, active: p.data3Light },
    ];

    this.schemes[4] = [
      { primary: p.data3, active: p.data3Light },
      { primary: p.data1, active: p.data1Light },
      { primary: p.data2, active: p.data2Light },
      { primary: p.data4, active: p.data4Light },
    ];

    this.schemes[5] = [
      { primary: p.data2, active: p.data2Light },
      { primary: p.data4, active: p.data4Light },
      { primary: p.data3, active: p.data3Light },
      { primary: p.data1, active: p.data1Light },
      { primary: p.data5, active: p.data5Light },
    ];
  }

  forIndex(index: number, length: number, isActive?: boolean): string {
    let schemeIndex = length;
    if (length < 3) {
      schemeIndex = 3;
    } else if (length > 5) {
      schemeIndex = 5;
    }
    const scheme = this.schemes[schemeIndex];
    const { primary, active } = scheme[index % schemeIndex];
    return isActive ? active ?? primary : primary;
  }

  static forIndexFromScheme(
    scheme: ColorScheme | string[],
    index: number,
    isActive?: boolean
  ) {
    const mappedScheme: ColorScheme =
      typeof scheme[0] === 'string'
        ? ((scheme as string[]).map((s) => ({ primary: s })) as ColorScheme)
        : (scheme as ColorScheme);
    const { primary, active } = mappedScheme[index % scheme.length];
    return isActive ? active ?? primary : primary;
  }
}

const instance = new ColorSchemeProvider();

export { instance as colorScheme };
export default instance;
