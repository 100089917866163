import { Box, Container, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import graphql from 'babel-plugin-relay/macro';
import { useContext, useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import {
  donutChartColorScheme,
  getPrimaryAndActiveColors,
} from 'src/assets/chartColorSchemes';
import AssetAllocationDonutChart from 'src/components/charts/AssetAllocationDonutChart';
import {
  QueryVariablesContext,
  QueryVariablesContextType,
} from 'src/components/context/QueryVariablesContext';
import Widget from 'src/components/widget/Widget';
import { AssetAllocationMenu } from './AssetAllocationMenu';
import { SubAssetClassWidget_data$key } from './__generated__/SubAssetClassWidget_data.graphql';

const StyledInnerItem = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
  whiteSpace: 'nowrap',
}));

export const SubAssetClassWidgetQuery = graphql`
  fragment SubAssetClassWidget_data on ClientSummaryViewer
  @argumentDefinitions(
    input: { type: "GetTopSubAssetClassesInput!" }
    input2: { type: "GetAssetAllocationInput!" }
  ) {
    getAssetAllocationByAssetClass(input: $input2) {
      assetClassName
      marketValue
    }
    getTopSubAssetClasses(input: $input) {
      assetClassTotal
      subClasses {
        subAssetClassMarketValue
        subAssetClassName
      }
    }
    id
  }
`;

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

interface SubClassDataType {
  subAssetClassName: string;
  subAssetClassMarketValue: number;
}

interface SubAssetDataType {
  assetClassTotal: number;
  subClasses: SubClassDataType[];
}

interface SubAssetClassAllocationChartDataType {
  name: string;
  value: number;
  assetClass?: string;
}

interface AssetDataType {
  assetClassName: string;
  marketValue: number;
}

const ASSET_CATEGORIES = [
  // cash and equivalents should not be an option in select
  'Fixed Income',
  'US Equity',
  'International Equity',
  'Alternatives',
  'Real Assets',
  'Other',
];

const generateChartData = (
  subAssetData: SubAssetDataType | undefined,
  assetClass: string
) => {
  const chartData: SubAssetClassAllocationChartDataType[] = [];
  subAssetData?.subClasses.forEach((o) => {
    chartData.push({
      name: o.subAssetClassName,
      value: o.subAssetClassMarketValue,
      assetClass,
    });
  });
  return chartData;
};

type SubAssetClassWidgetProps = {
  data: SubAssetClassWidget_data$key;
};

const SubAssetClassWidget = ({ data }: SubAssetClassWidgetProps) => {
  const subAssetData = useFragment(SubAssetClassWidgetQuery, data);

  const topSubAssetClasses: SubAssetDataType =
    subAssetData?.getTopSubAssetClasses as SubAssetDataType;

  const assetClassTotal = topSubAssetClasses?.assetClassTotal;
  const subAssetCategories = topSubAssetClasses?.subClasses.map(
    (o: SubClassDataType) => {
      return o.subAssetClassName;
    }
  );

  const assetClassValues: AssetDataType[] =
    subAssetData?.getAssetAllocationByAssetClass as AssetDataType[];

  const newScheme = getPrimaryAndActiveColors(donutChartColorScheme);

  const [selectedAssetClass, setSelectedAssetClass] = useState<string>(() => {
    let initialValue = ASSET_CATEGORIES[0];
    for (let item of ASSET_CATEGORIES) {
      const assetEntry = assetClassValues?.find(
        (assetClassValue) => assetClassValue?.assetClassName === item
      );
      if (assetEntry && assetEntry.marketValue > 0) {
        initialValue = item;
        break;
      }
    }
    return initialValue;
  });

  const handleChange = (
    // TODO: fix typing
    // event: React.ChangeEvent<{ name?: string; value: unknown }>
    event: any
  ) => {
    if (event.target.value) {
      setSelectedAssetClass(event.target.value as string);
    }
  };

  const {
    setSelectedAssetClass: setSelectedAssetClassVariable,
  }: QueryVariablesContextType = useContext(QueryVariablesContext);
  useEffect(() => {
    setSelectedAssetClassVariable(selectedAssetClass);
  }, [selectedAssetClass, setSelectedAssetClassVariable]);

  const chartData = generateChartData(topSubAssetClasses, selectedAssetClass);

  const renderInnerElement = () => {
    return (
      <>
        <StyledInnerItem>
          <Typography variant="body2">
            {formatter.format(assetClassTotal)}
          </Typography>
        </StyledInnerItem>
        <StyledInnerItem>
          <Typography variant="body1">
            {selectedAssetClass + ' Total'}
          </Typography>
        </StyledInnerItem>
      </>
    );
  };

  const subAssetTile = (
    <Container
      sx={(theme) => ({
        position: 'relative',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          width: '330px',
        },
        [theme.breakpoints.up('md')]: {
          width: '500px',
        },
      })}
    >
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <Select
          variant="standard"
          value={selectedAssetClass}
          onChange={handleChange}
        >
          {ASSET_CATEGORIES.map((item) => {
            const assetEntry = assetClassValues?.find(
              (o) => o?.assetClassName === item
            );
            return (
              <MenuItem
                value={item}
                key={item}
                sx={
                  !assetEntry || assetEntry.marketValue === 0
                    ? { opacity: 0.25 }
                    : undefined
                }
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <AssetAllocationDonutChart<SubAssetClassAllocationChartDataType>
        data={chartData}
        legendWidth={250}
        height={265}
        legendData={
          subAssetCategories &&
          subAssetCategories.map((category) => ({
            label: category,
          }))
        }
        colorScheme={newScheme}
        inner={renderInnerElement()}
        totalValue={assetClassTotal}
      />
    </Container>
  );

  return (
    <Widget
      title="Current Top 5 Valued Sub Asset Classes"
      detailsMenu={
        <AssetAllocationMenu
          navigationState={{
            selectedTab: 'by-asset-class',
            category: selectedAssetClass,
          }}
        />
      }
      widgets={[
        {
          element: subAssetTile,
        },
      ]}
    />
  );
};

export default SubAssetClassWidget;
